/* eslint-disable no-case-declarations */
import { ThemeInterface } from "@pepdirect/ui/theme";

export const APP_ID = {
  GatoradeCom: "ef66ee25-eb8f-40b1-af20-3da1b508b1b8",
  GatoradeTeamzone: "5557f6ea-7402-4309-a153-35c9faa52837",
  PantryShop: "b0414f96-89ee-4a2d-94ea-9b062d2a7212",
  PepsiCoShop: "ce256574-90a0-443b-b7a9-c68ea4dc178f",
  RockstarEnergy: "a2789465-7de3-454f-a326-118fcc3e2c33",
  SodaStreamPro: "808c8c05-42d8-4b2e-bd08-357523ac9788",
  SodaStreamPL: "e0225c6f-2285-493c-8205-8f42a92b8655",
  SodaStreamUS: "ad39626e-fcb2-42d8-ba73-aa5f505d826c",
};

export const getTheme = async (
  appId: string | null
): Promise<ThemeInterface> => {
  switch (appId) {
    case APP_ID.GatoradeCom:
      const { GatoradeTheme } = await import(
        "@pepdirect/ui/theme/GatoradeTheme"
      );
      return GatoradeTheme;
    case APP_ID.GatoradeTeamzone:
      const { GatoradeTeamZoneTheme } = await import(
        "@pepdirect/ui/theme/GatoradeTeamZoneTheme"
      );
      return GatoradeTeamZoneTheme;
    case APP_ID.PantryShop:
      const { PantryShopTheme } = await import(
        "@pepdirect/ui/theme/PantryShopTheme"
      );
      return PantryShopTheme;
    case APP_ID.PepsiCoShop:
      const { PepsiCoShopTheme } = await import(
        "@pepdirect/ui/theme/PepsiCoShopTheme"
      );
      return PepsiCoShopTheme;
    case APP_ID.RockstarEnergy:
      const { RockstarEnergyTheme } = await import(
        "@pepdirect/ui/theme/RockstarEnergyTheme"
      );
      return RockstarEnergyTheme;
    case APP_ID.SodaStreamPL:
    case APP_ID.SodaStreamUS:
      const { SodaStreamTheme } = await import(
        "@pepdirect/ui/theme/SodaStreamTheme"
      );
      return SodaStreamTheme;
    case APP_ID.SodaStreamPro:
      const { SodaStreamProTheme } = await import(
        "@pepdirect/ui/theme/SodaStreamProTheme"
      );
      return SodaStreamProTheme;
    default:
      const { PepDefaultTheme } = await import(
        "@pepdirect/ui/theme/PepDefaultTheme"
      );
      return PepDefaultTheme;
  }
};

export const getTenantLogoBackgroundColor = (appId: string | null): string => {
  switch (appId) {
    case APP_ID.GatoradeCom:
      return "#000";
    case APP_ID.GatoradeTeamzone:
      return "#000";
    case APP_ID.PantryShop:
      return "#003069";
    case APP_ID.PepsiCoShop:
      return "#fff";
    case APP_ID.RockstarEnergy:
      return "#fff";
    case APP_ID.SodaStreamPro:
      return "#009cdd";
    default:
      return "#fff";
  }
};
