import { BrandConfigCustomizationsFont } from "../types/graphql/generated";

// https://stackoverflow.com/questions/11355147/font-face-changing-via-javascript
export const generateFontFaces = (
  customFonts: BrandConfigCustomizationsFont[]
): string => {
  let fontFaces = "";

  customFonts.forEach((font) => {
    const urls: string[] = [];
    const metadata: string[] = [];

    font.urls.forEach((url) => {
      // https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face#specifying_local_font_alternatives
      if (url.local) {
        // note: each local value must be singular
        // good: "Arial"
        // bad: "Arial, sans-serif"
        urls.push(`local("${url.local}")`);
      } else if (url.url) {
        urls.push(
          `url("${url.url}")${url.format ? `format("${url.format}")` : ""}`
        );
      }
    });

    font.meta?.forEach((meta) => metadata.push(`${meta.key}:${meta.value};`));

    const fontFamily = `font-family:"${font.name}";`;
    const fontSrc = `src:${urls.join(",")};`;
    const fontWeight = `font-weight:${font.weight};`;
    const fontStyle = `font-style:${font.style};`;
    const fontMeta = metadata.join();
    // avoid showing invisible text while custom fonts
    // load by temporarily showing a system font
    const fontDisplay = "font-display:swap;";

    fontFaces +=
      "@font-face{" +
      fontFamily +
      fontSrc +
      fontWeight +
      fontStyle +
      fontMeta +
      fontDisplay +
      "}";
  });

  return fontFaces;
};
