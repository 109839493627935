import React from "react";
import {
  AddressFragment,
  CreateOrderMutation,
  CurrentUserQuery,
  PaymentRequestInput,
} from "services/graphql/generated";
import { CheckoutContextInterface } from "./hooks/useCheckoutProvider";
import { CartItemUpdateDto, CartError } from "@pepdirect/shared/types";
import { AddressDetailsFormValues } from "components/Forms/AddressDetailsForm";
import { CheckoutFormValues } from "pages/[cartId]/[appId]";

export const CheckoutContext = React.createContext<CheckoutContextInterface>({
  // values
  apiBaseURL: "",
  cartErrors: [],
  createdOrFetchedOrder: null,
  deliveryDate: null,
  isCheckoutPageLoading: false,
  orderHasSubscription: false,
  paymentRequest: {},
  priceSummary: null,
  selectedCountry: "",
  userInfo: null,
  userShippingAddress: null,
  selectedInvoicedAddressId: null,
  // functions
  autoSaveEnteredShip: (_ship: Partial<AddressDetailsFormValues>) => {},
  continueToConfirmation: (_values: CheckoutFormValues) => {},
  setApiBaseURL: (_url: string) => {},
  setCartErrors: (_errors: CartError[]) => {},
  setCreatedOrFetchedOrder: (_order: CreateOrderMutation["createOrder"]) => {},
  setDeliveryDate: (_date: Date) => {},
  setIsCheckoutPageLoading: (_isCheckoutPageLoading: boolean) => {},
  setOrderHasSubscription: (_orderHasSub: boolean) => {},
  setPaymentRequest: (_payment: PaymentRequestInput) => {},
  setSelectedCountry: (_countryCode: string) => {},
  setUserInfo: (_user: CurrentUserQuery["currentUser"] | null) => {},
  setUserShippingAddress: (_addr: AddressFragment | null) => {},
  setSelectedInvoicedAddressId: (_addr: string | null) => {},
  updateCart: async (_dto: CartItemUpdateDto) => {},
});
