import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import client from "services/graphql";
import {
  CreateOrderDocument,
  CreateOrderMutation,
  CreateOrderMutationVariables,
  UpdateOrderDocument,
  UpdateOrderMutation,
  UpdateOrderMutationVariables,
} from "services/graphql/generated";

export const createOrFetchOrder = async (
  cartId: string,
  customClient?: ApolloClient<NormalizedCacheObject>
): Promise<CreateOrderMutation["createOrder"]> => {
  const { data } = await (customClient || client).mutate<
    CreateOrderMutation,
    CreateOrderMutationVariables
  >({
    mutation: CreateOrderDocument,
    variables: { cartId },
  });

  return data?.createOrder;
};

export const saveEnteredShipToOrder = async (
  updateOrderMutationVariables: UpdateOrderMutationVariables
): Promise<UpdateOrderMutation["updateOrder"]> => {
  const { data } = await client.mutate<
    UpdateOrderMutation,
    UpdateOrderMutationVariables
  >({
    mutation: UpdateOrderDocument,
    variables: updateOrderMutationVariables,
  });
  return data?.updateOrder;
};
