import * as Sentry from "@sentry/nextjs";
import TagManager from "react-gtm-module";
import { ANALYTICS_EVENTS } from "constants/analyticsEvents";
import { formatDraftOrder, formatSubmittedOrder } from "helpers/analytics";
import {
  Bazaarvoice,
  CartFragment,
  ContactInfoInput,
  CurrentUserQuery,
  PriceV2Fragment,
  SubmitOrderMutation,
  SubmitOrderMutationVariables,
} from "services/graphql/generated";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sendEvent = (event: string, eventDetails: any): void => {
  const tagManagerArgs = {
    dataLayer: {
      event,
      ...eventDetails,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const logOrderSummaryPageView = (
  cart: CartFragment,
  username?: string | null,
  email?: string | null,
  userString?: string | null
): void => {
  sendEvent(ANALYTICS_EVENTS.orderSummaryView, {
    cart,
    email: !!email,
    emailCapture: userString || undefined,
    username,
  });
};

export const logContinueToPurchaseConfirmation = (
  appId: string,
  order: SubmitOrderMutationVariables,
  price: PriceV2Fragment,
  submissionUid: string
): void => {
  const formattedOrder = formatDraftOrder(appId, order, price, submissionUid);

  if (!formattedOrder) {
    console.error("logContinueToPurchaseConfirmation missing submissionUid");
    Sentry.captureMessage(
      "logContinueToPurchaseConfirmation missing submissionUid"
    );

    return;
  }

  sendEvent(ANALYTICS_EVENTS.continueToPurchaseConfirmation, {
    submission: formattedOrder,
  });
};

export const logPurchaseComplete = (
  appId: string,
  apiBaseUrl: string,
  bazaarvoice: Bazaarvoice,
  order: NonNullable<SubmitOrderMutation["submitOrder"]>
): void => {
  const formattedOrder = formatSubmittedOrder(appId, order);

  if (!formattedOrder) {
    console.error("logPurchaseComplete missing submissionUid");
    Sentry.captureMessage("logPurchaseComplete missing submissionUid");

    return;
  }

  sendEvent(ANALYTICS_EVENTS.purchaseCompleted, {
    ...(!!Object.keys(bazaarvoice).length && { bazaarvoice }),
    ...formattedOrder,
    apiBaseUrl,
  });
};

export const logOrderPurchaseError = (
  error: Error,
  { email, username }: ContactInfoInput
): void => {
  sendEvent(ANALYTICS_EVENTS.orderPurchaseError, {
    ...error,
    email: !!email,
    username,
  });
};

export const logPromoCode = (
  promoCode: string,
  valid: boolean,
  user: CurrentUserQuery["currentUser"] | null
): void => {
  const { email, id } = user || {};
  sendEvent(ANALYTICS_EVENTS.promoCodeApplied, {
    promoCode: {
      promoCode: promoCode,
      valid: valid,
    },
    email: !!email,
    username: id,
  });
};

export const logUserChange = (username: string | null): void => {
  sendEvent(ANALYTICS_EVENTS.userChanged, { username });
};

export const logSubscriptionEvent = (
  event: string,
  username: string,
  subscriptionId?: string
): void => {
  sendEvent(event, {
    username,
    ...(subscriptionId && { subscriptionId }),
  });
};

interface CART_ITEM {
  title: string;
  id: string;
  oldQuantity: number;
  newQuantity: number;
  intervalDays: number | null;
}

export const logCheckoutCartItemQty = (
  username: string | null,
  cartItem: CART_ITEM
): void => {
  sendEvent(ANALYTICS_EVENTS.checkoutItemQtyChange, { username, ...cartItem });
};

export const logPurchaseEmailOptIn = (email: string): void => {
  sendEvent(ANALYTICS_EVENTS.purchaseEmailOptIn, { email });
};
