import { Styles } from "react-select";
import { colors } from "../../styles/variables";
import { ThemeInterface } from "../../theme/theme";
import { ReactSelectDropdownOption } from "../dropdown/types";

export const getThemedSelectStyles = (
  theme: ThemeInterface,
  valid: boolean,
  invalid: boolean,
  focused: boolean
): Partial<Styles<ReactSelectDropdownOption, false>> => {
  const getBorderColor = () => {
    if (invalid) return colors.red;
    if (valid) return colors.green;
    if (focused) return theme.color.primary;
    return colors.mediumGray;
  };

  return {
    clearIndicator: () => ({ visibility: "hidden" }),
    container: (provided) => ({
      ...provided,
      minWidth: "64px",
    }),
    control: (provided) => ({
      ...provided,
      alignItems: "flex-start",
      border: "none",
      borderRadius: 0,
      boxShadow: "none",
      backgroundColor: "transparent",
      cursor: "pointer",
      minHeight: 0,
      height: 30, // (input.scss) .input height
      borderBottom: `1px solid ${getBorderColor()}`, // @mixin form-element-border
      "&:hover": {
        borderColor: getBorderColor(),
      },
      "&:focused": {
        borderColor: theme.color.primary,
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
      path: {
        fill: invalid ? colors.red : colors.mediumGray,
      },
    }),
    indicatorSeparator: () => ({ visibility: "hidden" }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 220,
    }),
    option: (provided, { isSelected, isFocused }) => ({
      ...provided,
      cursor: "pointer",
      backgroundColor: isSelected
        ? colors.gray
        : isFocused
        ? "rgba(0, 0, 0, 0.15)"
        : "transparent",
    }),
    placeholder: (provided, { hasValue, selectProps }) => ({
      ...provided,
      color: colors.mediumGray,
      fontSize: hasValue || selectProps.inputValue || focused ? 13 : 16,
      margin: 0,
      position: "absolute",
      top: hasValue || selectProps.inputValue || focused ? -8 : 15,
      transition: "top 0.1s, font-size 0.1s",
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: 0,
      top: 15,
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: 30,
      padding: "0 8px 0 0",
      overflow: "visible",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      alignItems: "flex-start",
    }),
    input: (provided) => ({
      ...provided,
      height: 30,
      padding: 0,
      margin: 0,
    }),
  };
};
