import React, { FC, useContext, useEffect, useState } from "react";
import { ApolloError } from "@apollo/client";
import { CheckoutContext } from "context/checkout";
import { Spinner } from "@pepdirect/ui/spinner";
import { OrderConfirmationModal } from "../VerificationAndConfirmationModal/OrderConfirmationModal";
import { ErrorModal } from "../ErrorModal";
import { getMappedErrorMsg } from "@pepdirect/helpers/error";
import {
  getCalculateOrderTaxesVariables,
  getPriceAfterTax,
  transformPriceFragmentToInput,
} from "helpers/checkout";
import {
  PriceV2Fragment,
  SubmitOrderMutationVariables,
  useCalculateOrderTaxesMutation,
} from "services/graphql/generated";
import { InvoiceConfirmationModalProps } from "types/modal";
import { errorCodes, taxVerificationErrorCodes } from "constants/error";
import st from "../VerificationAndConfirmationModal/verificationAndConfirmationModal.module.scss";

interface ICModalProps extends InvoiceConfirmationModalProps {
  onClose: () => void;
}

export const InvoiceConfirmationModal: FC<ICModalProps> = ({
  submitOrderVars,
  onClose,
}) => {
  const { createdOrFetchedOrder } = useContext(CheckoutContext);
  const { cart } = createdOrFetchedOrder || {};
  const [error, setError] = useState<string | null>(null);
  const [priceAfterTax, setPriceAfterTax] = useState<PriceV2Fragment | null>(
    null
  );
  const [showSpinner, setShowSpinner] = useState(true);
  const [submitOrderVarsWithUpdatedPrice, setSubmitOrderVarsWithUpdatedPrice] =
    useState<SubmitOrderMutationVariables>();
  const { orderId, input: submitOrderInput } = submitOrderVars;

  const [calculateOrderTaxes] = useCalculateOrderTaxesMutation();

  useEffect(() => {
    const getVerifiedTax = async () => {
      try {
        const calculateOrderTaxesArgs = getCalculateOrderTaxesVariables(
          submitOrderInput,
          orderId,
          cart?.id
        );

        const result = await calculateOrderTaxes({
          variables: calculateOrderTaxesArgs,
        });
        const order = result?.data?.calculateOrderTaxes?.order;
        if (order) {
          const afterTaxPrice = getPriceAfterTax(order);
          setPriceAfterTax(afterTaxPrice);
          setSubmitOrderVarsWithUpdatedPrice({
            orderId,
            input: {
              ...submitOrderInput,
              price: transformPriceFragmentToInput(afterTaxPrice),
            },
          });
        } else {
          setError("");
        }

        setShowSpinner(false);
      } catch (e) {
        let errorMsg = errorCodes.somethingWentWrong;
        if (e instanceof ApolloError && e.graphQLErrors?.length) {
          errorMsg = getMappedErrorMsg(
            e.message || "",
            taxVerificationErrorCodes,
            "calculateOrderTaxes"
          );
        }
        setError(errorMsg);
        setShowSpinner(false);
      }
    };

    getVerifiedTax();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error !== null) {
    return <ErrorModal error={error} onClose={onClose} />;
  }

  if (showSpinner) {
    return (
      <div className={st.body}>
        <div className={st.spinner}>
          <Spinner />
        </div>
      </div>
    );
  }

  if (priceAfterTax && submitOrderVarsWithUpdatedPrice) {
    return (
      <OrderConfirmationModal
        submitOrderVars={submitOrderVarsWithUpdatedPrice}
        price={priceAfterTax}
        onClose={onClose}
      />
    );
  }
  return null;
};
