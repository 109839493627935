import React, { useContext, useEffect } from "react";
import Modal from "react-modal";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import cx from "classnames";

import { ModalContext } from "context/modal";
import st from "./modal.module.scss";

/* Modals */
import { VerificationAndConfirmationModal } from "./VerificationAndConfirmationModal";
import { InvoiceConfirmationModal } from "./InvoiceConfirmationModal";
import { PortalAddressVerificationModal } from "./PortalAddressVerificationModal";
import { ErrorModal } from "./ErrorModal";
import {
  VerificationAndConfirmationProps,
  InvoiceConfirmationModalProps,
  PortalAddressVerificationProps,
  ErrorProps,
  MinimumErrorProps,
} from "types/modal";
import { MinimumErrorModal } from "./MinimumErrorModal";
import NoNetworkModal from "./NoNetworkModal";

export function RootModal(): JSX.Element {
  const { isOpen, close, type, props } = useContext(ModalContext);
  useEffect(() => {
    if (typeof window !== "undefined") {
      Modal.setAppElement("#__next");
    }
    // Cleanup just in case
    return clearAllBodyScrollLocks;
  }, []);

  return (
    <Modal
      onAfterClose={clearAllBodyScrollLocks}
      // Since ref is passed when we open modal, we can do it
      overlayRef={(ref) => {
        if (ref) disableBodyScroll(ref, { reserveScrollBarGap: true });
      }}
      isOpen={isOpen}
      onRequestClose={close}
      className={cx(
        st.modal,
        type === "NO_NETWORK_CONNECTION_MODAL" && st.noNetworkModal
      )}
      overlayClassName={st.overlay}
      closeTimeoutMS={250}
    >
      <>
        {/** ON NO_NETWORK_CONNECTION_MODAL, we don't show close element */}
        {type !== "NO_NETWORK_CONNECTION_MODAL" && (
          <button className={st.close} onClick={close} aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        )}

        {type === "VERIFICATION_AND_CONFIRMATION_MODAL" && (
          <VerificationAndConfirmationModal
            onClose={close}
            {...(props as VerificationAndConfirmationProps)}
          />
        )}

        {type === "INVOICE_CONFIRMATION_MODAL" && (
          <InvoiceConfirmationModal
            onClose={close}
            {...(props as InvoiceConfirmationModalProps)}
          />
        )}
        {type === "PORTAL_ADDRESS_VERIFICATION_MODAL" && (
          <PortalAddressVerificationModal
            onClose={close}
            {...(props as PortalAddressVerificationProps)}
          />
        )}
        {type === "ERROR_MODAL" && (
          <ErrorModal onClose={close} {...(props as ErrorProps)} />
        )}
        {type === "MINIMUM_ERROR_MODAL" && (
          <MinimumErrorModal
            onClose={close}
            {...(props as MinimumErrorProps)}
          />
        )}
        {type === "NO_NETWORK_CONNECTION_MODAL" && (
          <NoNetworkModal onClose={close} />
        )}
      </>
    </Modal>
  );
}
