import { useState, useCallback } from "react";
import { BannerDetails } from "@pepdirect/v3/ui/Banner";

export interface BannerContextInterface {
  setBannerDetails: (props: BannerDetails | null) => void;
  bannerDetails: BannerDetails | null;
  setBannerAutoDismiss: (dismissBanner: boolean) => void;
  bannerAutoDismiss: boolean;
}

export function useBannerProvider(): {
  bannerContextValue: BannerContextInterface;
} {
  const [bannerContent, setBannerContent] = useState<BannerDetails | null>(
    null
  );
  const [dismissBanner, setDismissBanner] = useState(false);

  const setBannerDetails = useCallback(
    (props: BannerDetails | null) => setBannerContent(props),
    []
  );
  const setBannerAutoDismiss = useCallback(
    (dismiss: boolean) => setDismissBanner(dismiss),
    []
  );

  const bannerContextValue = {
    bannerDetails: bannerContent,
    bannerAutoDismiss: dismissBanner,
    setBannerDetails,
    setBannerAutoDismiss,
  };

  return { bannerContextValue };
}
