import React, { FC, SVGProps } from "react";
import styled from "styled-components";
import { HexColor } from "../../types/styles";
import { colors } from "../../styles/variables";

export enum CaretPointDirections {
  down = "down",
  left = "left",
  right = "right",
  up = "up",
}

const pointDirectionTransformMap = {
  [CaretPointDirections.down]: "transform: none",
  [CaretPointDirections.left]: "transform: rotate(90deg)",
  [CaretPointDirections.right]: "transform: rotate(270deg)",
  [CaretPointDirections.up]: "transform: rotate(180deg)",
};

const StyledSvg = styled.svg<{ pointDirection: CaretPointDirections }>`
  ${({ pointDirection }) => pointDirectionTransformMap[pointDirection]};
`;

interface CaretIconSvgProps extends SVGProps<SVGSVGElement> {
  pointDirection?: CaretPointDirections;
  color?: HexColor;
  height?: string;
  width?: string;
  className?: string;
}

export const CaretIconSvg: FC<CaretIconSvgProps> = ({
  pointDirection = CaretPointDirections.down,
  color = colors.gray900,
  height = "6px",
  width = "10px",
  className = "",
}) => (
  <StyledSvg
    pointDirection={pointDirection}
    width={width}
    height={height}
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.03623 0.833178C1.22485 0.643325 1.53065 0.643325 1.71926 0.833178L5.00004 4.13554L8.28082 0.833178C8.46943 0.643325 8.77523 0.643325 8.96384 0.833178C9.15245 1.02303 9.15245 1.33084 8.96384 1.5207L5.34155 5.16682C5.15294 5.35667 4.84714 5.35667 4.65853 5.16682L1.03623 1.5207C0.847622 1.33084 0.847622 1.02303 1.03623 0.833178Z"
      fill={color}
    />
  </StyledSvg>
);
