import { Scalars } from "./generated";
import { Maybe } from "graphql/jsutils/Maybe";

// generated types copied from tenant query
export type BrandConfigCustomizationsFont = {
  meta?: Maybe<Array<BrandConfigCustomizationsFontMeta>>;
  name: Scalars["String"];
  style?: Maybe<Scalars["String"]>;
  urls: Array<BrandConfigCustomizationsFontSources>;
  weight?: Maybe<Scalars["String"]>;
};
export type BrandConfigCustomizationsFontMeta = {
  key: Scalars["String"];
  value: Scalars["String"];
};
export type BrandConfigCustomizationsFontSources = {
  format?: Maybe<Scalars["String"]>;
  local?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type BrandConfigTrackingFonts = {
  __typename?: "BrandConfigTrackingFonts";
  isTrackingRequired: Scalars["Boolean"];
  resource: Scalars["String"];
};

export enum FontResources {
  "fonts.com" = "fonts.com",
  "myfonts.net" = "myfonts.net",
}
