export interface GenericErrorExtensions<ErrorKeys> {
  details?: {
    key: ErrorKeys;
  }[];
}
export interface ChangesetErrorExtensions<ErrorKeys = never> {
  details?: (
    | {
        key: ErrorKeys;
      }
    | ChangesetError
  )[];
}

export const ChangesetErrorKey = "changesetError" as const;

export interface ChangesetError {
  details: [
    {
      details: {
        message: string;
      };
      errorType: string;
      key: string;
    }
  ];
  key: typeof ChangesetErrorKey;
}

export enum LoginErrorKeys {
  error = "error",
  accountLocked = "accountLocked",
  passwordResetRequired = "passwordResetRequired",
  emailVerificationRequired = "emailVerificationRequired",
}

export interface ErrorsInterface {
  [key: string]: string;
}

export enum SignupErrorKeys {
  emailVerificationRequired = "emailVerificationRequired",
  unverifiedEmail = "unverifiedEmail",
}
