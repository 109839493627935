import React, { FC, ReactNode } from "react";
import styled, { css } from "styled-components";
import { Device } from "../../helpers/screenSizes";

const headingStyles = css<HeadingStyleProps>`
  margin: 0;
  padding: 0;
  ${({ theme, size, color }) => {
    const {
      fontSize,
      fontFamily,
      fontWeight,
      letterSpacing,
      lineHeight,
      textTransform,
      color: themeColor,
    } = theme.typography.heading[size];
    const headingColor = color || themeColor;
    return `
      font-size: ${fontSize?.desktop};
      font-family: ${fontFamily || "font-primary"};
      font-weight: ${fontWeight || "400"};
      line-height: ${lineHeight || "normal"};
      letter-spacing: ${letterSpacing || "normal"};
      ${textTransform ? `text-transform: ${textTransform};` : ""}
      ${headingColor ? `color: ${headingColor};` : ""}
      ${
        fontSize?.mobile
          ? `
        @media ${Device.mobile} {
          font-size: ${fontSize?.mobile};
        }
      `
          : ""
      };
    `;
  }};
  ${({ center }) => center && `text-align: center`};
`;

/*-----------------------
  HeadingLevel components
------------------------*/
const Heading1 = styled.h1<HeadingStyleProps>`
  ${headingStyles};
`;

const Heading2 = styled.h2<HeadingStyleProps>`
  ${headingStyles};
`;

const Heading3 = styled.h3<HeadingStyleProps>`
  ${headingStyles};
`;

const Heading4 = styled.h4<HeadingStyleProps>`
  ${headingStyles};
`;

const Heading5 = styled.h5<HeadingStyleProps>`
  ${headingStyles};
`;

const Heading6 = styled.h6<HeadingStyleProps>`
  ${headingStyles};
`;

/*------------
  interfaces
-------------*/

interface HeadingStyleProps {
  size: HeadingSize;
  // this overrides the color set in the theme, use sparingly!
  color?: string;
  center?: boolean;
}

interface HeadingProps extends HeadingStyleProps {
  level: HeadingLevel;
  children: ReactNode;
  // https://styled-components.com/docs/advanced#styling-normal-react-components
  className?: string;
}

/**
 * Heading size refers to the defined size/font/color etc.
 * in the theme and is unrelated to the heading level.
 */
type HeadingSize = "xxl" | "xl" | "l" | "m" | "s" | "xs" | "xxs";

/**
 * Heading levels (<h1>, <h2>, etc) are not simply about sizing
 * and styling of header text, they provide semantic information
 * about the organization and importance of the content. They
 * are also interpreted by screen readers so that users can
 * jump directly to top level headings, next level headings,
 * and so on.
 *
 * In this component, we override all default styles of the
 * heading levels with the definitions from the heading size.
 */
type HeadingLevel = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export const Heading: FC<HeadingProps> = ({
  size,
  level,
  children,
  color,
  center,
  className,
}) => {
  switch (level) {
    case "h1":
      return (
        <Heading1
          size={size}
          color={color}
          center={center}
          className={className || ""}
        >
          {children}
        </Heading1>
      );
    case "h2":
      return (
        <Heading2
          size={size}
          color={color}
          center={center}
          className={className || ""}
        >
          {children}
        </Heading2>
      );
    case "h3":
      return (
        <Heading3
          size={size}
          color={color}
          center={center}
          className={className || ""}
        >
          {children}
        </Heading3>
      );
    case "h4":
      return (
        <Heading4
          size={size}
          color={color}
          center={center}
          className={className || ""}
        >
          {children}
        </Heading4>
      );
    case "h5":
      return (
        <Heading5
          size={size}
          color={color}
          center={center}
          className={className || ""}
        >
          {children}
        </Heading5>
      );
    case "h6":
      return (
        <Heading6
          size={size}
          color={color}
          center={center}
          className={className || ""}
        >
          {children}
        </Heading6>
      );
  }
};
