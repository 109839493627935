import { NextPageContext } from "next";
import absoluteUrl from "next-absolute-url";
import { apiSubdomain, endpoints, environment } from "v3/config";
import { AppContext } from "next/app";

export function getApiPathFromAbsolutePath(
  apiSubdomain: string,
  req?: NextPageContext["req"]
): string {
  const { protocol, host } = absoluteUrl(req);
  const hostWithoutFirstPart = host.split(".").slice(1).join(".");
  // add `2` to RSE in production
  if (/rockstarenergy\.com/.test(hostWithoutFirstPart)) {
    return `${protocol}//${apiSubdomain}2.${hostWithoutFirstPart}`;
  }
  return `${protocol}//${apiSubdomain}.${hostWithoutFirstPart}`;
}

export function getApiEndpoint(req?: NextPageContext["req"]): string {
  const host = req?.headers.host;
  const env =
    // special dev configuration to allow all apps to run concurrently locally
    environment === "dev" && host
      ? `http://${host.replace("secure", "api").replace("3001", "4000")}`
      : endpoints.api;
  return env;
}

/**
 * Function which returns either path with subdomain (if apiSubdomain is present) or plain endpoints.api
 */
export function getBasePath(req?: NextPageContext["req"]): string {
  return apiSubdomain
    ? getApiPathFromAbsolutePath(apiSubdomain, req)
    : getApiEndpoint(req);
}

export function getGQLPath(req?: NextPageContext["req"]): string {
  return `${getBasePath(req)}/v3/graphql`;
}

export function isServerSide(appContext: AppContext): boolean {
  return !!(typeof window === "undefined" && appContext?.ctx?.res?.writeHead);
}
