import React from "react";
import { AutocompletedAddressValues } from "@pepdirect/shared/types";
import { AutocompleteContextInterface } from "types/autocomplete";

export const AutocompleteContext =
  React.createContext<AutocompleteContextInterface>({
    setShipSelectedFromAutocomplete: (
      _props: AutocompletedAddressValues | null
    ) => {},
    shipSelectedFromAutocomplete: null,
  });
