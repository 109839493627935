export const FontsMock = [
  {
    meta: null,
    name: "font-primary",
    style: "normal",
    urls: [
      {
        format: "woff2",
        local: null,
        url: "https://assets.decovostatic.com/fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2",
      },
      {
        format: "woff",
        local: null,
        url: "https://assets.decovostatic.com/fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff",
      },
    ],
    weight: "400",
  },
  {
    meta: [
      {
        key: "descent-override",
        value: "0%",
      },
    ],
    name: "font-primary",
    style: "normal",
    urls: [
      {
        format: "woff2",
        local: null,
        url: "https://assets.decovostatic.com/fonts/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2",
      },
      {
        format: "woff",
        local: null,
        url: "https://assets.decovostatic.com/fonts/7b415a05-784a-4a4c-8c94-67e9288312f5.woff",
      },
    ],
    weight: "700",
  },
  {
    meta: null,
    name: "font-secondary",
    style: "normal",
    urls: [
      {
        format: "woff2",
        local: null,
        url: "https://assets.decovostatic.com/fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2",
      },
      {
        format: "woff",
        local: null,
        url: "https://assets.decovostatic.com/fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff",
      },
    ],
    weight: "400",
  },
];
