import React from "react";
import { CreditContextInterface } from "./hooks/useCreditProvider";
import { DiscountFragment } from "services/graphql/generated";
import { TotalAmountPriceProps } from "helpers/price";
import { AllocationFragment } from "services/graphql/generated";

export const CreditContext = React.createContext<CreditContextInterface>({
  activeAllocation: null,
  setActiveAllocation: (_activeAllocation: AllocationFragment | null) => {},
  hasInsufficientCredit: false,
  setHasInsufficientCredit: (
    _price: TotalAmountPriceProps,
    _discounts: DiscountFragment[]
  ) => {},
});
