import React from "react";
import styled from "styled-components";

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs((props) => ({
  type: props.type ?? "checkbox",
}))`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
`;

const StyledCheckbox = styled.div<CheckboxProps>`
  display: inline-block;
  width: 20px;
  height: 20px;
  background: ${({ checked, disabled, theme }) =>
    checked && disabled
      ? theme.color.disabled
      : checked
      ? theme.color.primary
      : "transparent"};
  border-radius: ${({ round }) => (round ? "50%" : "4px")};
  transition: all 150ms;
  border: 1px solid
    ${({ disabled, theme }) =>
      disabled ? theme.color.disabled : theme.color.primary};

  :hover {
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  }

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 2px pink;
  }
  ${Icon} {
    visibility: ${({ checked }) => (checked ? "visible" : "hidden")};
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export interface CheckboxProps {
  checked: boolean;
  disabled: boolean;
  round?: boolean;
}

/**
 * HOW TO USE THE CHECKBOX COMPONENT
 * - Wrap the `<Checkbox>` inside a `<label>` to get
 *   access to the usual input interactions
 * - Common props to pass in:
 *   - checked
 *   - onChange
 *   - disabled
 *
 * **NOTE** if you use `htmlFor` on the label
 * you must have an id of the same string on
 * the `<Checkbox>`. Or don't use altogether.
 *
 * ex: `<label htmlFor="abc"><Checkbox id="abc"/></label>`
 * or simply: `<label><Checkbox/></label>`
 */
export const Checkbox = ({
  checked = false,
  disabled = false,
  round = false,
  className = "",
  ...props
}) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox
      data-testid="checkbox-input"
      checked={checked}
      disabled={disabled}
      {...props}
    />
    <StyledCheckbox checked={checked} disabled={disabled} round={round}>
      <Icon viewBox="0 0 24 28">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);
