import React from "react";

export const CirclePlusIconSvg = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="37"
    viewBox="0 0 74 74"
  >
    <path
      d="M0 0 C9.02717467 5.35806496 15.3282501 13.81473748 18 24 C19.12722481 37.14658704 17.85644485 48.44428387 9.125 58.92578125 C0.22437957 67.61370303 -9.13036941 70.42058147 -21.17163086 70.36352539 C-31.54071792 70.12645146 -39.65327034 66.40114988 -47 59 C-55.52787667 47.80309323 -57.24449518 37.71490981 -56 24 C-53.92581354 14.20040051 -47.67969938 6.48264198 -39.4375 1.0625 C-27.77254255 -6.09900508 -12.1197322 -6.56811294 0 0 Z M-46 13 C-51.78848512 22.08858699 -53.56036252 31.22981636 -51.421875 41.87890625 C-48.80845628 50.22996698 -43.74282639 56.97931262 -36.33203125 61.69921875 C-27.03070667 66.49414087 -17.53407413 67.74172661 -7.375 64.5625 C1.79981554 60.78385487 8.10673673 54.21963164 12.16015625 45.26171875 C15.39207854 36.55807686 14.9738434 27.22991099 11.44921875 18.671875 C6.44179225 9.43985417 -0.96395314 3.4850426 -10.9453125 0.3984375 C-24.98043391 -2.32320201 -36.77397746 2.31111262 -46 13 Z "
      fill="#717171"
      transform="translate(56,4)"
    />
    <path
      d="M0 0 C1.32 0 2.64 0 4 0 C4 5.28 4 10.56 4 16 C8.62 16 13.24 16 18 16 C18 17.32 18 18.64 18 20 C13.38 20 8.76 20 4 20 C4 24.62 4 29.24 4 34 C2.68 34 1.36 34 0 34 C0 29.38 0 24.76 0 20 C-4.62 20 -9.24 20 -14 20 C-14 18.68 -14 17.36 -14 16 C-9.38 16 -4.76 16 0 16 C0 10.72 0 5.44 0 0 Z "
      fill="#646464"
      transform="translate(36,20)"
    />
    <path
      d="M0 0 C0.66 0.33 1.32 0.66 2 1 C-0.64 3.64 -3.28 6.28 -6 9 C-6.66 8.67 -7.32 8.34 -8 8 C-5.58443874 4.80800833 -3.31996462 2.25283313 0 0 Z "
      fill="#868686"
      transform="translate(15,6)"
    />
  </svg>
);
