import React, { FC, useEffect, useState, useContext, useMemo } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ApolloError } from "@apollo/client";
import { TenantContext } from "v3/context/tenant";
import { CheckoutContext } from "context/checkout";
import {
  AddressVerificationModal,
  VerificationAddrTypes,
} from "./AddressVerificationModal";
import { OrderConfirmationModal } from "./OrderConfirmationModal";
import { ErrorModal } from "../ErrorModal";
import { Spinner } from "@pepdirect/ui/spinner";
import {
  PriceV2Fragment,
  SubmitOrderMutationVariables,
  useCalculateOrderTaxesMutation,
  useVerifyAddressForOrderMutation,
  VerifiedAddressFragment,
  VerifyAddressInput,
} from "services/graphql/generated";
import { logUnknownErrorCode } from "@pepdirect/helpers/logAndCaptureInSentry";
import { getMappedErrorMsg } from "@pepdirect/helpers/error";
import { logAddressVerified } from "@pepdirect/helpers/analyticsLogger";
import { VerificationAndConfirmationProps } from "types/modal";
import {
  getCalculateOrderTaxesVariables,
  getPriceAfterTax,
  getVerifiedShippingAddress,
  isOriginalAndVerifiedAddressSame,
  transformPriceFragmentToInput,
} from "helpers/checkout";
import {
  addressValidationErrorCodes,
  errorCodes,
  taxVerificationErrorCodes,
} from "constants/error";
import { PAYMENT_TYPES } from "constants/payment";
import st from "./verificationAndConfirmationModal.module.scss";

interface VAndCModalProps extends VerificationAndConfirmationProps {
  onClose: () => void;
}

export const VerificationAndConfirmationModal: FC<VAndCModalProps> = ({
  submitOrderVars,
  priceBeforeTax,
  onClose,
}) => {
  const { tenant } = useContext(TenantContext);
  const { createdOrFetchedOrder } = useContext(CheckoutContext);

  const [showSpinner, setShowSpinner] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [verifiedAddress, setVerifiedAddress] =
    useState<VerifiedAddressFragment | null>(null);
  const [unverifiedAddress, setUnverifiedAddress] =
    useState<VerifyAddressInput | null>(null);
  const [confirmedAddress, setConfirmedAddress] =
    useState<VerificationAddrTypes | null>(null);
  const [priceAfterTax, setPriceAfterTax] = useState<PriceV2Fragment | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);
  const [verificationErrors, setVerificationErrors] = useState<
    NonNullable<VerifiedAddressFragment>["errors"] | null
  >(null);
  const [hasInvalidZip, setHasInvalidZip] = useState(false);

  const [verifyAddressForOrder] = useVerifyAddressForOrderMutation();
  const [calculateOrderTaxes] = useCalculateOrderTaxesMutation();

  const { orderId, input: submitOrderInput } = submitOrderVars;
  const { cart } = createdOrFetchedOrder || {};

  const requireValidation =
    tenant?.contactConfig.shippingAddressConfig.requireValidation;
  // special handler to allow entered addresses as-is
  const allowUnverifiedAddress = requireValidation === "OPTIONAL";
  const userSelectedShip = submitOrderInput?.contactInfo.shippingAddress;
  // if payment method is credit, we don't do tax verification and is verified at address step
  const isVerified =
    (submitOrderInput?.paymentRequest.paymentMethod ===
      PAYMENT_TYPES.ALLOCATED_CREDIT &&
      confirmedAddress) ||
    !!priceAfterTax;

  const calculateHasInvalidZip = async () => {
    try {
      const calculateOrderTaxesVars = getCalculateOrderTaxesVariables(
        submitOrderInput,
        orderId,
        cart?.id
      );
      await calculateOrderTaxes({
        variables: calculateOrderTaxesVars,
      });
    } catch (e) {
      if (e instanceof Error && !taxVerificationErrorCodes[e.message]) {
        logUnknownErrorCode(e.message, "calculateOrderTaxes");
      }

      if (
        e instanceof Error &&
        Object.keys(taxVerificationErrorCodes).includes(e.message)
      ) {
        setHasInvalidZip(true);
      } else {
        throw e;
      }
    }
  };

  useEffect(() => {
    const getVerifiedAddress = async () => {
      if (!userSelectedShip) {
        setError("");
        return;
      }

      const userSelectedAddress: VerifyAddressInput = {
        city: userSelectedShip.city || "",
        company: userSelectedShip.company || null,
        countryCode: userSelectedShip.countryCode || "",
        firstName: userSelectedShip.firstName || "",
        lastName: userSelectedShip.lastName || "",
        line1: userSelectedShip.line1 || "",
        line2: userSelectedShip.line2 || null,
        state: userSelectedShip.state || "",
        zip: userSelectedShip.zip || "",
      };

      if (userSelectedShip.validated) {
        setConfirmedAddress(userSelectedAddress);
        return;
      }

      try {
        const { data } = await verifyAddressForOrder({
          variables: {
            orderId,
            input: userSelectedAddress,
          },
        });

        logAddressVerified();

        const { verifyAddressForOrder: verifiedAddressForOrder } = data || {};

        // handle no verified address
        if (!verifiedAddressForOrder) {
          setError("");
          return;
        }

        const { errors } = verifiedAddressForOrder || {};

        // handle errors
        if (errors?.length) {
          if (allowUnverifiedAddress) {
            if (
              submitOrderInput.paymentRequest.paymentMethod !==
              PAYMENT_TYPES.ALLOCATED_CREDIT
            ) {
              await calculateHasInvalidZip();
            }
            setUnverifiedAddress(userSelectedAddress);
          } else {
            setVerificationErrors(errors);
          }
          setShowSpinner(false);
          return;
        }

        // handle different entered and verified address
        if (
          !isOriginalAndVerifiedAddressSame(
            userSelectedShip,
            verifiedAddressForOrder,
            allowUnverifiedAddress
          )
        ) {
          // HANDLE ALLOWING BOTH VERIFIED AND UNVERIFIED
          if (allowUnverifiedAddress) {
            if (
              submitOrderInput.paymentRequest.paymentMethod !==
              PAYMENT_TYPES.ALLOCATED_CREDIT
            ) {
              await calculateHasInvalidZip();
            }
            setUnverifiedAddress(userSelectedAddress);
          }
          setVerifiedAddress(verifiedAddressForOrder);
          setShowSpinner(false);
          return;
        }
        // if we get here, address is verified
        setConfirmedAddress(verifiedAddress || userSelectedAddress);
      } catch (e) {
        setError((e as Error).message || "");
        setShowSpinner(false);
      }
    };

    // on opening of this modal, and only once, get the verified address
    getVerifiedAddress();
    // Ignoring dep warning because it wants it to have 7 more deps, which in turn
    // may need to be memoized, and they might have a deps array whose deps also
    // need to be memoized to prevent this single-use effect getting called more
    // than once, which will unnecessarily bloat this component and make it (even
    // more) unreadable. It would be much better to use the useEvent hook once it
    // gets implemented into React.
    // https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getVerifiedTax = async () => {
      try {
        setIsLoading(true);

        if (verifiedAddress && confirmedAddress === verifiedAddress) {
          submitOrderInput.contactInfo.shippingAddress =
            getVerifiedShippingAddress(
              verifiedAddress,
              submitOrderInput.contactInfo.shippingAddress
            );
        }

        const calculateOrderTaxesArgs = getCalculateOrderTaxesVariables(
          submitOrderInput,
          orderId,
          cart?.id
        );

        const result = await calculateOrderTaxes({
          variables: calculateOrderTaxesArgs,
        });
        const order = result?.data?.calculateOrderTaxes?.order;
        if (order) {
          setPriceAfterTax(getPriceAfterTax(order));
        } else {
          setError("");
        }

        setIsLoading(false);
        setShowSpinner(false);
      } catch (e) {
        let errorMsg = errorCodes.somethingWentWrong;
        if (e instanceof ApolloError && e.graphQLErrors?.length) {
          errorMsg = getMappedErrorMsg(
            e.message || "",
            taxVerificationErrorCodes,
            "calculateOrderTaxes"
          );
        }
        setError(errorMsg);
        setShowSpinner(false);
      }
    };

    // after the user confirms the address...
    if (confirmedAddress) {
      // calculate taxes if not an allocation
      if (
        submitOrderInput.paymentRequest.paymentMethod !==
        PAYMENT_TYPES.ALLOCATED_CREDIT
      ) {
        getVerifiedTax();
        return;
      }

      // set validated to false if user confirmed unverified address on allocation
      if (
        confirmedAddress === unverifiedAddress &&
        submitOrderInput.contactInfo.shippingAddress
      ) {
        submitOrderInput.contactInfo.shippingAddress.validated = false;
        // set the shipping address if user confirmed verified address on allocation
      } else if (confirmedAddress === verifiedAddress) {
        submitOrderInput.contactInfo.shippingAddress =
          getVerifiedShippingAddress(
            verifiedAddress,
            submitOrderInput.contactInfo.shippingAddress
          );
      } else {
        setIsLoading(false);
        setShowSpinner(false);
      }
    }
    // Ignoring dep warning for same reason as above.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedAddress]);

  const handleCloseAddressVerificationModal = () => {
    if (hasInvalidZip) setHasInvalidZip(false);
    onClose();
  };

  const finalPrice = priceAfterTax || priceBeforeTax;

  const submitOrderVarsWithUpdatedPrice: SubmitOrderMutationVariables = useMemo(
    () => ({
      orderId,
      input: {
        ...submitOrderInput,
        price: transformPriceFragmentToInput(priceAfterTax || priceBeforeTax),
      },
    }),
    [orderId, submitOrderInput, priceAfterTax, priceBeforeTax]
  );

  if (showSpinner) {
    return (
      <div className={st.body}>
        <div className={st.spinner}>
          <Spinner />
        </div>
      </div>
    );
  }

  if (verificationErrors) {
    return (
      <ErrorModal onClose={onClose} buttonType="cancel" buttonText="Go back">
        <p>We&apos;ve encountered the following error(s):</p>
        <ul className={st.error}>
          {verificationErrors.map((error, index) => {
            let errorMessage = "Unexpected error occurred";
            if (error.code) {
              errorMessage = getMappedErrorMsg(
                error.code,
                addressValidationErrorCodes,
                "verifyAddressForOrder"
              );
            }
            if (error.message) {
              errorMessage = error.message;
            }
            return <li key={`address_error_${index}`}>{errorMessage}</li>;
          })}
        </ul>
      </ErrorModal>
    );
  }

  if (error !== null) {
    return <ErrorModal error={error} onClose={onClose} />;
  }

  const renderOrderConfirmationModal = () => (
    <OrderConfirmationModal
      submitOrderVars={submitOrderVarsWithUpdatedPrice}
      price={finalPrice}
      onClose={onClose}
    />
  );

  if (
    userSelectedShip &&
    !(verifiedAddress || unverifiedAddress) &&
    isVerified
  ) {
    return renderOrderConfirmationModal();
  }

  return (
    <TransitionGroup component={null}>
      {userSelectedShip &&
        (verifiedAddress || unverifiedAddress) &&
        !isVerified && (
          <CSSTransition
            classNames="slide"
            timeout={200}
            key="slide-1"
            unmountOnExit
            enter={false}
          >
            <AddressVerificationModal
              verifiedAddress={verifiedAddress}
              unverifiedAddress={unverifiedAddress}
              userConfirm={setConfirmedAddress}
              onClose={handleCloseAddressVerificationModal}
              loading={isLoading}
              hasInvalidZip={hasInvalidZip}
            />
          </CSSTransition>
        )}

      {isVerified && (
        <CSSTransition
          classNames="slide"
          timeout={200}
          key="slide-2"
          unmountOnExit
          exit={false}
        >
          {renderOrderConfirmationModal()}
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};
