import React from "react";
import { HexColor } from "../../types/styles";
import { colors } from "../../styles/variables";

export const WarningIconSvg = ({
  color = colors.gray900,
  width = "22",
  height = "18",
}: {
  color?: HexColor;
  height?: string;
  width?: string;
}) => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.875 17.75L11 0.25L21.125 17.75H0.875ZM3.45 16.25H18.55L11 3.25L3.45 16.25ZM11 15.05C11.2333 15.05 11.425 14.975 11.575 14.825C11.725 14.675 11.8 14.4833 11.8 14.25C11.8 14.0167 11.725 13.825 11.575 13.675C11.425 13.525 11.2333 13.45 11 13.45C10.7667 13.45 10.575 13.525 10.425 13.675C10.275 13.825 10.2 14.0167 10.2 14.25C10.2 14.4833 10.275 14.675 10.425 14.825C10.575 14.975 10.7667 15.05 11 15.05ZM10.25 12.45H11.75V7.45H10.25V12.45Z"
      fill={color}
    />
  </svg>
);
