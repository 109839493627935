import React from "react";
import { HexColor } from "../../types/styles";
import { colors } from "../../styles/variables";

export const DiscountIconSvg = ({
  color = colors.gray900,
}: {
  color?: HexColor;
}) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.21497 13.2298C8.03331 13.4111 7.8144 13.5017 7.55827 13.5017C7.30213 13.5017 7.08343 13.4111 6.90216 13.2298L0.778766 7.10642C0.691682 7.01574 0.62345 6.91176 0.57407 6.79448C0.52469 6.6772 0.5 6.56009 0.5 6.44315V1.42661C0.5 1.17384 0.59153 0.955935 0.774589 0.772888C0.957636 0.589829 1.17554 0.498299 1.42831 0.498299H6.44485C6.5621 0.498299 6.67493 0.518139 6.78333 0.55782C6.89172 0.597488 6.99378 0.664196 7.08952 0.757945L13.2178 6.87987C13.4036 7.06571 13.4977 7.28612 13.5 7.54107C13.5023 7.79603 13.4128 8.01414 13.2315 8.19542L8.21497 13.2298ZM7.55548 12.5665L12.5751 7.55L6.44792 1.42661H1.42831V6.44315L7.55548 12.5665ZM3.14134 4.03305C3.38906 4.03305 3.59989 3.94634 3.77384 3.77294C3.94778 3.59953 4.03475 3.38897 4.03475 3.14125C4.03475 2.89353 3.94805 2.6827 3.77464 2.50875C3.60123 2.33481 3.39067 2.24784 3.14295 2.24784C2.89523 2.24784 2.6844 2.33454 2.51045 2.50795C2.33651 2.68136 2.24954 2.89192 2.24954 3.13964C2.24954 3.38736 2.33624 3.59819 2.50965 3.77213C2.68306 3.94608 2.89362 4.03305 3.14134 4.03305Z"
      fill={color}
    />
  </svg>
);
