import currency from "currency.js";
import { getPriceSummary } from "helpers/checkout";
import {
  PriceV2Fragment,
  SubmitOrderMutation,
  SubmitOrderMutationVariables,
} from "services/graphql/generated";

interface FormattedOrderItem {
  // productId here refers to the priceItem.id, not sure if technically
  // correct but this is what has been sent for a while so leaving as-is
  productId?: string;
  gtin: string;
  quantity: number;
  price: number;
  rowTotal: number;
  currency: string;
}

export interface FormattedOrder {
  orderId: string;
  submissionUid: string;
  appId: string;
  total: number;
  subTotal: number;
  shipping: number;
  tax: number;
  discount: number;
  email: boolean;
  phone: boolean;
  username?: string | null;
  emailCapture?: string | null;
  mailingAddress: boolean;
  paymentMethod?: string | null;
  lineItems: FormattedOrderItem[];
}

export const formatDraftOrder = (
  appId: string,
  order: SubmitOrderMutationVariables,
  price: PriceV2Fragment,
  submissionUid: string
): FormattedOrder | null => {
  if (!submissionUid) return null;

  const {
    userSubtotalPrice,
    userTaxPrice,
    userDiscountPrice,
    userShippingPrice,
    userTotalPrice,
  } = getPriceSummary(price.items, price.shipping || 0);

  const total = currency(userTotalPrice || 0, {
    fromCents: true,
  }).value;
  const subTotal = currency(userSubtotalPrice || 0, {
    fromCents: true,
  }).value;
  const tax = currency(userTaxPrice || 0, {
    fromCents: true,
  }).value;
  const shipping = currency(userShippingPrice || 0, {
    fromCents: true,
  }).value;
  const discount = currency(userDiscountPrice || 0, {
    fromCents: true,
  }).value;

  const {
    orderId,
    input: {
      paymentRequest: { paymentMethod },
      contactInfo: { email, username = null, phone, shippingAddress },
    },
  } = order;

  const lineItems: FormattedOrderItem[] = price.items.map(
    (priceItem): FormattedOrderItem => {
      const { basePrice, quantity } = priceItem;
      const rowTotal = currency((basePrice || 0) * (quantity || 0), {
        fromCents: true,
      }).value;
      const itemPrice = currency(basePrice || 0, {
        fromCents: true,
      }).value;

      return {
        gtin: priceItem.item?.upc || "",
        quantity: quantity || 0,
        price: itemPrice,
        rowTotal,
        currency: "USD",
      };
    }
  );

  return {
    orderId,
    submissionUid,
    appId,
    total,
    subTotal,
    shipping,
    tax,
    discount,
    email: !!email,
    phone: !!phone,
    username,
    mailingAddress: !!shippingAddress,
    paymentMethod,
    lineItems,
  };
};

export const formatSubmittedOrder = (
  appId: string,
  order: NonNullable<SubmitOrderMutation["submitOrder"]>
): FormattedOrder | null => {
  const {
    contactInfo: { email, username = null, phone, shippingAddress },
    id: orderId,
    paymentRequest,
    price: { items: priceItems, shipping: shippingFromOrder },
    submissionUid,
  } = order;

  if (!submissionUid) return null;

  const {
    userSubtotalPrice,
    userTaxPrice,
    userDiscountPrice,
    userShippingPrice,
    userTotalPrice,
  } = getPriceSummary(priceItems, shippingFromOrder || 0);

  const total = currency(userTotalPrice || 0, {
    fromCents: true,
  }).value;
  const subTotal = currency(userSubtotalPrice || 0, {
    fromCents: true,
  }).value;
  const tax = currency(userTaxPrice || 0, {
    fromCents: true,
  }).value;
  const shipping = currency(userShippingPrice || 0, {
    fromCents: true,
  }).value;
  const discount = currency(userDiscountPrice || 0, {
    fromCents: true,
  }).value;

  const lineItems = priceItems.map((priceItem) => {
    const { id: productId, basePrice, quantity } = priceItem || {};
    const rowTotal = currency((basePrice || 0) * (quantity || 0), {
      fromCents: true,
    }).value;

    return {
      productId,
      quantity: quantity || 0,
      price: currency(basePrice || 0, {
        fromCents: true,
      }).value,
      rowTotal,
      gtin: priceItem.item?.upc || "",
      currency: "USD",
    };
  });

  return {
    orderId,
    submissionUid,
    appId,
    total,
    subTotal,
    shipping,
    tax,
    discount,
    email: !!email,
    phone: !!phone,
    emailCapture: order.contactInfo.userString,
    username,
    mailingAddress: !!shippingAddress,
    paymentMethod: paymentRequest?.paymentMethod,
    lineItems,
  };
};
