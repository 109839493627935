import { useState, useCallback } from "react";
import {
  DiscountFragment,
  AllocationFragment,
} from "services/graphql/generated";
import { getTotalAmount, TotalAmountPriceProps } from "helpers/price";

export interface CreditContextInterface {
  activeAllocation: AllocationFragment | null;
  setActiveAllocation: (activeAllocation: AllocationFragment | null) => void;
  hasInsufficientCredit: boolean;
  setHasInsufficientCredit: (
    price: TotalAmountPriceProps,
    discounts: DiscountFragment[]
  ) => void;
}

export function useCreditProvider(): {
  creditContextValue: CreditContextInterface;
} {
  const [activeAllocation, setActiveAllocation] =
    useState<AllocationFragment | null>(null);
  const [hasInsufficientCredit, setHasInsufficientCredit] = useState(false);

  const handleSetActiveAllocation = useCallback(
    (allocation: AllocationFragment | null) => {
      setActiveAllocation(allocation);
    },
    []
  );

  const handleSetHasUnsufficientCredit = useCallback(
    (price: TotalAmountPriceProps, discounts: DiscountFragment[]) => {
      setHasInsufficientCredit(
        (activeAllocation?.availableAmount || 0) <
          getTotalAmount(price, discounts)
      );
    },
    [activeAllocation?.availableAmount]
  );

  const creditContextValue: CreditContextInterface = {
    activeAllocation,
    hasInsufficientCredit,
    setActiveAllocation: handleSetActiveAllocation,
    setHasInsufficientCredit: handleSetHasUnsufficientCredit,
  };

  return { creditContextValue };
}
