import { ErrorsInterface } from "@pepdirect/shared/types";

export const errorModal = {
  title: "Error",
  link: "https://contact.pepsico.com/pantryshop",
};

export interface ZipCodeValidationItemInterface {
  id: string;
  title: string;
  upc: string;
}

export const addressValidationErrors: ErrorsInterface = {
  inputInvalid:
    "The shipping address entered is invalid. Please check and try again.",
  wrongShippingAddress:
    "We haven't been able to verify the shipping address entered. Please check and try again.",
  shippingAddressNotFound:
    "The shipping address entered wasn't found in any of our postal services' systems.",
  shippingAddressHouseNumberMissingInvalid:
    "The house number for the shipping address entered is missing or invalid.",
  shippingAddressStreetMissingInvalid:
    "The street address information for the shipping address entered is missing or invalid.",
  shippingAddressStreetDetailsMissingInvalid:
    "The second line street address information for the shipping address entered is missing or invalid.",
  shippingAddressZipCodeError:
    "The zip code for the shipping address entered is invalid according to our postal services' systems.",
  shippingAddressUndeliverable:
    "The shipping address entered cannot be delivered to according to our postal services' systems.",
  unverifiedCarrier: "Unable to verify address using provided carrier.",
  upstreamUnavailable:
    "Address verification is not available due to an upstream service not responding.",
  onlyUs: "USPS can only validate US addresses.",
  noInt: "International Verification not enabled.",
  missingStateZip:
    "Insufficient address data provided. A city and state or a zip must be provided.",
};

export const taxVerificationErrors = {
  invalidZip:
    "The zip code for the shipping address entered is invalid according to our postal services' systems.",
  mismatchStateZip:
    "The zip code for the shipping address entered does not match the state.",
};

export const addressValidationErrorCodes: ErrorsInterface = {
  "E.ADDRESS.DELIVERY.INVALID":
    addressValidationErrors.shippingAddressUndeliverable,
  "E.ADDRESS.DUAL": addressValidationErrors.wrongShippingAddress,
  "E.ADDRESS.INSUFFICIENT": addressValidationErrors.wrongShippingAddress,
  "E.ADDRESS.INVALID": addressValidationErrors.wrongShippingAddress,
  "E.ADDRESS.MULTIPLE": addressValidationErrors.wrongShippingAddress,
  "E.ADDRESS.NOT_FOUND": addressValidationErrors.shippingAddressNotFound,
  "E.BOX_NUMBER.INVALID": addressValidationErrors.wrongShippingAddress,
  "E.BOX_NUMBER.MISSING": addressValidationErrors.wrongShippingAddress,
  "E.CITY_STATE.INVALID": addressValidationErrors.wrongShippingAddress,
  "E.COUNTRY.UNSUPPORTED": addressValidationErrors.wrongShippingAddress,
  "E.ENGINE.UNAVAILABLE": addressValidationErrors.wrongShippingAddress,
  "E.HOUSE_NUMBER.INVALID":
    addressValidationErrors.shippingAddressHouseNumberMissingInvalid,
  "E.HOUSE_NUMBER.MISSING":
    addressValidationErrors.shippingAddressHouseNumberMissingInvalid,
  "E.INPUT.INVALID": addressValidationErrors.inputInvalid,
  "E.PO_BOX.INTERNATIONAL": addressValidationErrors.wrongShippingAddress,
  "E.QUERY.UNANSWERABLE": addressValidationErrors.wrongShippingAddress,
  "E.SECONDARY_INFORMATION.INVALID":
    addressValidationErrors.shippingAddressStreetDetailsMissingInvalid,
  "E.SECONDARY_INFORMATION.MISSING":
    addressValidationErrors.shippingAddressStreetDetailsMissingInvalid,
  "E.STATE.INVALID": addressValidationErrors.wrongShippingAddress,
  "E.STREET.INVALID":
    addressValidationErrors.shippingAddressStreetMissingInvalid,
  "E.STREET.MAGNET": addressValidationErrors.wrongShippingAddress,
  "E.STREET.MISSING":
    addressValidationErrors.shippingAddressStreetMissingInvalid,
  "E.TIMED_OUT": addressValidationErrors.wrongShippingAddress,
  "E.TIME_ZONE.UNAVAILABLE": addressValidationErrors.wrongShippingAddress,
  "E.ZIP.INVALID": addressValidationErrors.shippingAddressZipCodeError,
  "E.ZIP.NOT_FOUND": addressValidationErrors.shippingAddressZipCodeError,
  "E.ZIP.PLUS4.NOT_FOUND": addressValidationErrors.shippingAddressZipCodeError,
  "ADDRESS.PARAMETERS.INVALID_CHARACTER": addressValidationErrors.inputInvalid,
  "ADDRESS.PARAMETERS.INVALID": addressValidationErrors.inputInvalid,
  "ADDRESS.COUNTRY.INVALID": addressValidationErrors.wrongShippingAddress,
  "ADDRESS.VERIFICATION.NOT_FOUND":
    addressValidationErrors.wrongShippingAddress,
  "ADDRESS.VERIFICATION.FAILURE": addressValidationErrors.wrongShippingAddress,
  "ADDRESS.VERIFY.UNAVAILABLE": addressValidationErrors.wrongShippingAddress,
  "ADDRESS.VERIFICATION.INVALID": addressValidationErrors.wrongShippingAddress,
  "ADDRESS.VERIFY.FAILURE": addressValidationErrors.wrongShippingAddress,
  "ADDRESS.VERIFY.CARRIER_INVALID": addressValidationErrors.unverifiedCarrier,
  "ADDRESS.VERIFY.UPSTREAM_UNAVAILABLE":
    addressValidationErrors.upstreamUnavailable,
  "ADDRESS.VERIFY.ONLY_US": addressValidationErrors.onlyUs,
  "ADDRESS.VERIFY.INTL_NOT_ENABLED": addressValidationErrors.noInt,
  "ADDRESS.VERIFY.MISSING_STREET":
    addressValidationErrors.shippingAddressStreetMissingInvalid,
  "ADDRESS.VERIFY.MISSING_CITY_STATE_ZIP":
    addressValidationErrors.missingStateZip,
};

export const taxVerificationErrorCodes: ErrorsInterface = {
  invalid_zip: taxVerificationErrors.invalidZip,
  mismatch_state_zip: taxVerificationErrors.mismatchStateZip,
};

export const errorCodes: ErrorsInterface = {
  paymentNotAccepted:
    "Unfortunately we were unable to process your payment. Please try again with another payment method.",
  paymentProcessingError:
    "There was an error processing your payment request. Please try again.",
  somethingWentWrong: "Something went wrong. Please try again.",
  promo_code_unique_limit_reached:
    "This code has already been applied to a purchase.",
  not_found: "Something went wrong. Please try again later.",
  unverified_email: "Please enter a valid email address.",
};

export enum SignupErrorKeys {
  emailVerificationRequired = "emailVerificationRequired",
}
