import React, { FC, useRef, useEffect, useContext } from "react";
import { Field } from "react-final-form";
import Select, { components } from "react-select";
import styled, { ThemeContext } from "styled-components";
import { getThemedSelectStyles } from "./helpers";
import { FieldProps } from "./types";
import { DropdownIndicator } from "../dropdown/DropdownIndicator";
import { ReactSelectDropdownOption } from "../dropdown/types";

const StyledField = styled.div`
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  height: 30px;
  margin: 30px 0 25px;
`;

const StyledError = styled.span`
  font-size: 12px;
  line-height: 1.3;
  color: ${({ theme }) => theme.color.error};
  display: block;
  margin-top: 5px;
`;

interface SelectProps extends FieldProps {
  options: ReactSelectDropdownOption[];
  defaultValue?: ReactSelectDropdownOption;
  autocompleteValue?: ReactSelectDropdownOption;
  browserAutofillOffValue?: string;
  trackInteraction?: (fieldName: string) => void;
}

const { ValueContainer, Placeholder } = components;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomValueContainer = ({ children, ...props }: any) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OnChangeType = (event: any) => void;

export const FieldSelect: FC<SelectProps> = ({
  name,
  autocompleteValue,
  browserAutofillOffValue,
  trackInteraction,
  ...props
}) => {
  const theme = useContext(ThemeContext);
  const inputOnChange = useRef<OnChangeType | null>(null);

  /* setting the Input under components causes a tabbing bug, this is a hacky
     workaround until react-select accepts an autoComplete value or fixes the
     tabbing bug. So, DON'T DO THIS:

    components={{
      Input: ({ ...rest }) => (
        <components.Input
          {...rest}
          autoComplete={browserAutofillOffValue}
        />
      ),
  */
  useEffect(() => {
    if (browserAutofillOffValue) {
      const fieldSelectInputs = document.querySelectorAll(
        `.react-select input[autocomplete="off"]`
      );

      if (fieldSelectInputs) {
        fieldSelectInputs.forEach((input) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          input.autocomplete = browserAutofillOffValue;
        });
      }
    }
  }, [browserAutofillOffValue]);

  // handle an incoming change from the AutocompleteInput
  useEffect(() => {
    if (inputOnChange.current && autocompleteValue !== undefined) {
      inputOnChange.current(autocompleteValue);
    }
  }, [autocompleteValue, inputOnChange]);

  return (
    <Field name={name} {...props}>
      {({ input, meta, ...rest }) => {
        if (!inputOnChange.current) {
          inputOnChange.current = input.onChange;
        }

        const valid = !!meta.touched && !meta.error;
        const invalid = !!meta.touched && !!meta.error;
        const focused = !!meta.active;

        return (
          <StyledField className="react-select" data-testid={name}>
            <Select<ReactSelectDropdownOption>
              {...input}
              {...rest}
              components={{
                ValueContainer: CustomValueContainer,
                DropdownIndicator,
              }}
              defaultValue={props.defaultValue}
              placeholder={props.label}
              styles={getThemedSelectStyles(theme, valid, invalid, focused)}
              // https://github.com/JedWatson/react-select/issues/2629
              instanceId={name}
              onFocus={() => {
                trackInteraction?.(name);
                input?.onFocus?.();
              }}
            />
            {invalid && <StyledError>{meta.error}</StyledError>}
          </StyledField>
        );
      }}
    </Field>
  );
};
