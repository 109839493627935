import React from "react";
import { InvoicingContextInterface } from "./hooks/useInvoicingProvider";
import { InvoicedAddress } from "services/graphql/generated";

export const InvoicingContext = React.createContext<InvoicingContextInterface>({
  invoicingEnabled: false,
  setInvoicingEnabled: (invoicingEnabled: boolean) => {},
  addresses: [],
  setAddresses: (addresses: InvoicedAddress[]) => [],
  canPlaceOrders: false,
  setCanPlaceOrders: (canPlaceOrders: boolean) => {},
});
