export interface FieldProps {
  name: string;
  type?: string;
  label: string;
  formId?: string;
  autoComplete: string;
  component?: "input" | "select" | "textarea";
}

export const emptyInputMessage = "This field cannot be left blank";
