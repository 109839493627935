import PostalAddress from "i18n-postal-address";

interface FormatFullName {
  countryFormat?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}

export const formatFullName = ({
  countryFormat,
  firstName,
  lastName,
}: FormatFullName): string => {
  const fullName = new PostalAddress();
  fullName
    .setFirstName(firstName || "")
    .setLastName(lastName || "")
    .setFormat({ country: countryFormat || "US" });
  return fullName.toString();
};

interface FormatStreetByCountry {
  countryFormat?: string | null;
  line1?: string | null;
  line2?: string | null;
}

export const formatStreetByCountry = ({
  countryFormat,
  line1,
  line2,
}: FormatStreetByCountry): string => {
  const address = new PostalAddress();
  address
    .setAddress1(line1 || "")
    .setAddress2(line2 || "")
    .setFormat({ country: countryFormat || "US" });

  // puts line1 and line2 on single line instead of two lines for these countries
  ["US", "CA", "PL"].forEach((country) => {
    address.addFormat({
      country,
      format: [["address1", "address2"]],
    });
  });

  return address.toString();
};

export interface FormatCityStateZipByCountry {
  city?: string | null;
  countryFormat?: string | null;
  state?: string | null;
  zip?: string | null;
}

export const formatCityStateZipByCountry = ({
  city,
  countryFormat,
  state,
  zip,
}: FormatCityStateZipByCountry): string => {
  const address = new PostalAddress();
  address
    .setCity(city || "")
    .setState(state || "")
    .setPostalCode(zip || "")
    .setFormat({ country: countryFormat || "US" });

  return address.toString();
};
