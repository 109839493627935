import { Cart, EVENT_NAMES } from "@pepdirect/shared/types";
import { logEvent } from "./analyticsLogger";

interface getSubtotalOptions {
  inCents?: boolean;
}

export const getSubtotal = (
  cart: Cart | null,
  options?: getSubtotalOptions
): number => {
  if (!cart?.items?.length) return 0;

  const subtotalInCents = cart.items.reduce((subtotal, item) => {
    if (item && item.product && item.product.price) {
      const { price, subscriptionMetadata } = item.product;
      const isSubscription =
        subscriptionMetadata?.subscriptionPrice &&
        item.subscriptionIntervalInDays;
      const itemPrice =
        (isSubscription && subscriptionMetadata?.subscriptionPrice) || price;

      return subtotal + itemPrice * item.quantity;
    } else {
      return subtotal;
    }
  }, 0);

  return options?.inCents ? subtotalInCents : subtotalInCents / 100;
};

export const getErrors = (
  cart: Cart | null,
  currentUserId: string | null
): string[] => {
  const errors: string[] = [];
  cart?.items.forEach((item) => {
    if (item.product.isOutOfStock || !item.product.inventoryQty) {
      logEvent(currentUserId, EVENT_NAMES.cartOutOfStock);
      errors.push("noStock");
    }
    if (
      item.product.inventoryQty &&
      item.product.inventoryQty < item.quantity
    ) {
      errors.push("lowStock");
    }
  });
  return errors;
};
