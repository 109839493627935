import {
  ApolloClient,
  from,
  InMemoryCache,
  HttpLink,
  NormalizedCacheObject,
  DefaultOptions,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { NextPageContext } from "next";
import { getGQLPath, getApiEndpoint } from "v3/helpers/api";
import { isLocalEnv } from "v3/config";
import {
  logNonArrayGraphQLErrors,
  logServerNetworkError,
  logWithMessageInSentry,
} from "@pepdirect/v3/helpers/logAndCaptureInSentry";
import { PageRoutes } from "v3/constants/pageRoutes";
import { GenericErrorExtensions } from "@pepdirect/v3/types";

interface UpdateDevBasePathOnClientProps {
  basePath: string;
  client: ApolloClient<NormalizedCacheObject>;
  locale?: string;
}

interface UpdateBasePathOnClientProps {
  client: ApolloClient<NormalizedCacheObject>;
  locale?: string;
  req?: NextPageContext["req"];
}

// https://www.apollographql.com/docs/react/data/error-handling/#advanced-error-handling-with-apollo-link
export const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    const { operationName } = operation;
    /*
    - expected errors from BE, returned as 200 status code
    - do not log to DataDog/Sentry; any major errors returned as 200 (e.g.
      Braintree payment failures) will have already been logged on BE and
      are technically BE errors anyway
  */
    if (graphQLErrors) {
      if (Array.isArray(graphQLErrors)) {
        graphQLErrors.forEach((e) => {
          const { message, extensions } = e;
          const errorDetails = (extensions as GenericErrorExtensions<string>)
            ?.details;

          if (!errorDetails || !Array.isArray(errorDetails)) {
            logWithMessageInSentry(
              "Malformed GraphQL error, extensions.details missing or not an array!\n" +
                `${JSON.stringify(e, null, 2)}`
            );
          }

          // console.warn only in dev for debugging
          if (isLocalEnv) {
            const errorMessage =
              "[GraphQL 200 error]: " +
              `message: "${message}", ` +
              `operation name: ${operationName}` +
              `${
                extensions
                  ? `, extensions: ${JSON.stringify(extensions, null, 2)}`
                  : ""
              }`;
            console.warn(errorMessage);
          }
        });
      } else {
        logNonArrayGraphQLErrors({ graphQLErrors, operationName, response });
      }
    }

    // unexpected errors from BE, returned as 4-5xx status code
    if (networkError) {
      if (
        "statusCode" in networkError &&
        networkError.statusCode === 401 &&
        typeof window !== "undefined"
      ) {
        // redirect to the login page immediately if we get a 401 unauthenticated from
        // any graph call in the browser, because this means we sent a bad (expired) token
        // to the backend.
        window.location.href = `${PageRoutes.signIn}?successRedirectTo=${window.location.href}`;
      } else {
        // only log unknown network errors (401 unauthenticated is a known error)
        logServerNetworkError(networkError, operationName);
      }
    }
  }
);

// special dev configuration to allow all apps to run concurrently locally
export const updateDevBasePathOnClient = ({
  basePath,
  client,
  locale,
}: UpdateDevBasePathOnClientProps): void => {
  const headers = locale
    ? {
        headers: {
          "Accept-Language": locale,
        },
      }
    : {};
  const uri = `${basePath}/v3/graphql`;
  const link = from([
    errorLink,
    new HttpLink({
      credentials: "include",
      ...headers,
      uri,
    }),
  ]);

  client.setLink(link);
};

export const updateBasePathOnClient = ({
  client,
  locale,
  req,
}: UpdateBasePathOnClientProps): void => {
  const headers = locale
    ? {
        headers: {
          "Accept-Language": locale,
        },
      }
    : {};
  const uri = getGQLPath(req);
  const link = from([
    errorLink,
    new HttpLink({
      credentials: "include",
      ...headers,
      uri,
    }),
  ]);

  client.setLink(link);
};

const cache = new InMemoryCache();

export const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache" as const,
  },
  query: {
    fetchPolicy: "no-cache" as const,
  },
  mutate: {
    fetchPolicy: "no-cache" as const,
  },
};

const client = new ApolloClient({
  cache,
  defaultOptions,
  uri: `${getApiEndpoint()}/v3/graphql`,
  credentials: "include",
});

export default client;
