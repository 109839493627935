import { useCallback, useState } from "react";
import { useCreateSetupIntentMutation } from "services/graphql/generated";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { ConfirmationToken } from "@stripe/stripe-js";

export interface StripePaymentInterface {
  setStripeTokenDetails: (tokenDetails: ConfirmationToken) => void;
  stripeTokenDetails: ConfirmationToken | null;
  generateSetupIntentSecret: () => Promise<void>;
  setupIntentSecret: string | null;
  setSetupIntentSecret: (secret: string | null) => void;
}

export function useStripeProvider(
  apolloClient: ApolloClient<NormalizedCacheObject>,
  isStripeTenant: boolean
): {
  stripeContextValue: StripePaymentInterface;
} {
  const [stripeTokenDetails, setStripeTokenDetails] =
    useState<ConfirmationToken | null>(null);
  const [setupIntentSecret, setSetupIntentSecret] = useState<string | null>(
    null
  );
  const [setupIntent] = useCreateSetupIntentMutation({
    client: apolloClient,
  });
  const generateSetupIntentSecret = useCallback(async () => {
    if (!isStripeTenant) return;
    const { data: setupTransactionData } = await setupIntent();
    if (setupTransactionData?.createSetupIntent?.clientSecret) {
      setSetupIntentSecret(setupTransactionData.createSetupIntent.clientSecret);
    }
  }, [setupIntent, isStripeTenant]);
  const stripeContextValue: StripePaymentInterface = {
    setStripeTokenDetails,
    setSetupIntentSecret,
    generateSetupIntentSecret,
    stripeTokenDetails,
    setupIntentSecret,
  };

  return { stripeContextValue };
}
