import React, { useState, useContext, useEffect, useMemo } from "react";
import { ApolloError } from "@apollo/client";
import cx from "classnames";
import client from "services/graphql";
import { TenantContext } from "v3/context/tenant";
import { AutocompleteContext } from "context/autocomplete";
import { LocalizationContext } from "v3/context/localization";
import { FieldInput } from "@pepdirect/ui/FieldInput";
import { AutocompleteInput } from "@pepdirect/ui/AutocompleteInput";
import { FieldSelect } from "@pepdirect/ui/FieldSelect";
import { FieldCheckbox } from "components/Fields/FieldCheckbox";
import {
  StatesConfig,
  CountriesAllowed,
  useFindAddressLazyQuery,
  useRetrieveAddressLazyQuery,
} from "services/graphql/generated";
import {
  SelectedItemProps,
  AutocompletedAddressValues,
} from "@pepdirect/shared/types";
import { ADDRESS_FIELDS } from "constants/addressFields";
import { ReactSelectDropdownOption } from "constants/dropdown";
import st from "./addressDetailsForm.module.scss";
import columnSt from "styles/modules/columnLayout.module.scss";
import { logClientSideError } from "@pepdirect/helpers/logAndCaptureInSentry";
import { SignupDisclaimer } from "@pepdirect/ui/SignupDisclaimer";
import { getLinkFromTenant } from "helpers/tenant";
import { isTestEnv } from "config";

export interface AddressDetailsFormProps {
  countryValue?: ReactSelectDropdownOption;
  formId?: string;
  showCompany?: boolean;
  showEmail?: boolean;
  showPhone?: boolean;
  showEmailOptIn?: boolean;
  showIsDefault?: boolean;
  isBilling?: boolean;
}

export const ADDRESS_DETAILS_TYPE = "AddressForm";

export interface AddressDetailsFormValues {
  __formType: string; // use ADDRESS_DETAILS_TYPE to set value
  email?: string;
  addressId?: string;
  firstName: string;
  lastName: string;
  company?: string;
  line1: string;
  line2?: string;
  city: string;
  zip: string;
  state: ReactSelectDropdownOption;
  country: ReactSelectDropdownOption;
  phoneNumber?: string;
  phone?: string;
  nickname?: string;
  specialInstructions?: string;
  emailOptIn?: boolean;
  isDefault?: boolean;
}

export const UnitedStatesOption = {
  value: "US",
  label: "United States",
};

export function AddressDetailsForm({
  countryValue,
  formId = "shippingAddress",
  showCompany = false,
  showEmail = false,
  showPhone = false,
  showEmailOptIn = false,
  showIsDefault = false,
  isBilling = false,
}: AddressDetailsFormProps): JSX.Element {
  const { tenant } = useContext(TenantContext);
  const tenantName = tenant?.brandConfig?.title;
  const { shipSelectedFromAutocomplete, setShipSelectedFromAutocomplete } =
    useContext(AutocompleteContext);
  const { localization } = useContext(LocalizationContext);
  const {
    cityLabel = "City",
    companyLabel = "Company",
    countryLabel = "Country",
    emailLabel = "Email",
    firstNameLabel = "First Name",
    isDefaultLabel = "Make this my default shipping address",
    lastNameLabel = "Last Name",
    line1Label = "Street Address",
    line2Label = "Apt., Suite, etc. (Optional)",
    nicknameLabel = "Address Nickname",
    phoneLabel = "Phone",
    specialInstructionsLabel = "Special Instructions",
    stateLabel = "State",
    zipLabel = "Zip Code",
  } = localization?.forms?.address || {};

  const [autocompleteEnabled, setAutocompleteEnabled] = useState(false);
  const [browserAutofillOffValue, setBrowserAutofillOffValue] =
    useState<string>();
  const [autocompletedState, setAutocompletedState] =
    useState<ReactSelectDropdownOption>();
  const [country, setCountry] = useState<ReactSelectDropdownOption | undefined>(
    countryValue
  );

  const [findAddress] = useFindAddressLazyQuery({ client });
  const [retrieveAddress] = useRetrieveAddressLazyQuery({ client });

  const showAddressNickname =
    !!tenant?.contactConfig.shippingAddressConfig.addressNickname;
  const showSpecialInstructions =
    !!tenant?.contactConfig.shippingAddressConfig.addressSpecialInstructions;

  const { countriesAllowed = [], statesConfig = [] } =
    tenant?.contactConfig?.shippingAddressConfig || {};

  const isShipForm = useMemo(() => formId === "shippingAddress", [formId]);

  const statesForDropdown = useMemo(() => {
    return getStates(statesConfig, undefined, country);
  }, [country, statesConfig]);

  const privacyLink = getLinkFromTenant(tenant, "privacy");
  const termsLink = getLinkFromTenant(tenant, "terms");

  useEffect(() => {
    // unset ship selected from autocomplete on unmount
    return () => setShipSelectedFromAutocomplete(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isShipForm && !isTestEnv) {
      // FIXME: check for test env remove this please
      setAutocompleteEnabled(true);

      // disable browser autofill (chrome needs special value)
      setBrowserAutofillOffValue(
        navigator.userAgent.indexOf("Chrome") !== -1 ? "chrome-off" : "off"
      );
    }
  }, [tenant, isShipForm]);

  useEffect(() => {
    if (shipSelectedFromAutocomplete) {
      const countries = getCountries(countriesAllowed);
      const country =
        countries &&
        countries.find(
          (co) => co.value === shipSelectedFromAutocomplete.country
        );

      if (country) {
        setCountry(country);
      }
    }
  }, [shipSelectedFromAutocomplete, countriesAllowed]);

  useEffect(() => {
    if (shipSelectedFromAutocomplete && country) {
      const state = statesForDropdown.find(
        (st) => st.value === shipSelectedFromAutocomplete.state
      );

      if (state) {
        setAutocompletedState(state);
      }
    }
  }, [shipSelectedFromAutocomplete, statesConfig, country, statesForDropdown]);

  const handleFindOrRetrieveError = (e: ApolloError) => {
    if (e.graphQLErrors[0]) {
      const { extensions, message } = e.graphQLErrors[0];
      const details = extensions?.details || "";
      console.warn(`message: ${message}, details: ${details}`);
    }
    logClientSideError(e);
  };

  const handleFindAddresses = async (
    inputValue?: string,
    container?: string
  ): Promise<SelectedItemProps[]> => {
    try {
      if (!inputValue && !container) return [];

      const { data, error } = await findAddress({
        variables: { input: { text: inputValue || "", container } },
      });
      if (error) throw error;

      const addresses = data?.findAddress?.items || [];
      const foundAddresses: SelectedItemProps[] = [];

      addresses.forEach((address) => {
        const { id, value, type, description } = address;

        foundAddresses.push({
          id: id || "",
          value: value || "",
          type: type || "",
          label: `${value} ${description}`,
        });
      });

      return foundAddresses;
    } catch (e) {
      if (e instanceof ApolloError) handleFindOrRetrieveError(e);
    }

    return [];
  };

  const handleRetrieveAddresses = async (
    addressId: string
  ): Promise<AutocompletedAddressValues[]> => {
    try {
      const { data, error } = await retrieveAddress({
        variables: { id: addressId },
      });
      if (error) throw error;

      const addresses = data?.retrieveAddress?.items || [];
      const retrievedAddresses: AutocompletedAddressValues[] = [];

      addresses.forEach((address) => {
        const { line1, line2, city, state, zip, country } = address;

        retrievedAddresses.push({
          line1: line1 || "",
          line2: line2 || "",
          city: city || "",
          state: state || "",
          zip: zip || "",
          country: country || "",
        });
      });

      return retrievedAddresses;
    } catch (e) {
      if (e instanceof ApolloError) handleFindOrRetrieveError(e);
    }

    return [];
  };

  const handleSelectedAddress = (addr: AutocompletedAddressValues | null) => {
    setShipSelectedFromAutocomplete(addr);
  };

  return (
    <div>
      {showEmail && (
        <FieldInput
          {...{ ...ADDRESS_FIELDS.email, label: emailLabel }}
          name={`${formId}.${ADDRESS_FIELDS.email.name}`}
          browserAutofillOffValue={browserAutofillOffValue}
        />
      )}

      <div className={columnSt.row}>
        <div
          className={cx(
            st.columnItem,
            columnSt.column,
            columnSt.lg50,
            columnSt.md50
          )}
        >
          <FieldInput
            {...{ ...ADDRESS_FIELDS.firstName, label: firstNameLabel }}
            name={`${formId}.${ADDRESS_FIELDS.firstName.name}`}
            browserAutofillOffValue={browserAutofillOffValue}
          />
        </div>

        <div
          className={cx(
            st.columnItem,
            columnSt.column,
            columnSt.lg50,
            columnSt.md50
          )}
        >
          <FieldInput
            {...{ ...ADDRESS_FIELDS.lastName, label: lastNameLabel }}
            name={`${formId}.${ADDRESS_FIELDS.lastName.name}`}
            browserAutofillOffValue={browserAutofillOffValue}
          />
        </div>
      </div>

      {showCompany && (
        <FieldInput
          {...{ ...ADDRESS_FIELDS.company, label: companyLabel }}
          name={`${formId}.${ADDRESS_FIELDS.company.name}`}
        />
      )}

      {autocompleteEnabled ? (
        <AutocompleteInput
          {...{ ...ADDRESS_FIELDS.line1, label: line1Label }}
          name={`${formId}.${ADDRESS_FIELDS.line1.name}`}
          setAutocompletedAddressValues={handleSelectedAddress}
          browserAutofillOffValue={browserAutofillOffValue}
          findAddresses={handleFindAddresses}
          retrieveAddresses={handleRetrieveAddresses}
        />
      ) : (
        <FieldInput
          {...{ ...ADDRESS_FIELDS.line1, label: line1Label }}
          name={`${formId}.${ADDRESS_FIELDS.line1.name}`}
        />
      )}

      <FieldInput
        {...{ ...ADDRESS_FIELDS.line2, label: line2Label }}
        name={`${formId}.${ADDRESS_FIELDS.line2.name}`}
        autocompleteValue={
          isShipForm ? shipSelectedFromAutocomplete?.line2 : undefined
        }
        browserAutofillOffValue={browserAutofillOffValue}
      />

      <FieldInput
        {...{ ...ADDRESS_FIELDS.city, label: cityLabel }}
        name={`${formId}.${ADDRESS_FIELDS.city.name}`}
        autocompleteValue={
          isShipForm ? shipSelectedFromAutocomplete?.city : undefined
        }
        browserAutofillOffValue={browserAutofillOffValue}
      />

      <div className={columnSt.row}>
        <div
          className={cx(
            st.columnItem,
            columnSt.column,
            columnSt.lg33,
            columnSt.md33
          )}
        >
          <FieldSelect
            {...{ ...ADDRESS_FIELDS.state, label: stateLabel }}
            name={`${formId}.${ADDRESS_FIELDS.state.name}`}
            options={statesForDropdown}
            autocompleteValue={isShipForm ? autocompletedState : undefined}
            browserAutofillOffValue={browserAutofillOffValue}
          />
        </div>

        <div
          className={cx(
            st.columnItem,
            columnSt.column,
            columnSt.lg33,
            columnSt.md33
          )}
        >
          <FieldInput
            {...{ ...ADDRESS_FIELDS.zip, label: zipLabel }}
            name={`${formId}.${ADDRESS_FIELDS.zip.name}`}
            autocompleteValue={
              isShipForm ? shipSelectedFromAutocomplete?.zip : undefined
            }
            browserAutofillOffValue={browserAutofillOffValue}
          />
        </div>

        <div
          className={cx(
            st.columnItem,
            columnSt.column,
            columnSt.lg33,
            columnSt.md33
          )}
        >
          <FieldSelect
            {...{ ...ADDRESS_FIELDS.country, label: countryLabel }}
            name={`${formId}.${ADDRESS_FIELDS.country.name}`}
            options={getCountries(countriesAllowed)}
            autocompleteValue={isShipForm ? country : undefined}
            browserAutofillOffValue={browserAutofillOffValue}
          />
        </div>
      </div>

      {showPhone && (
        <FieldInput
          {...{ ...ADDRESS_FIELDS.phoneNumber, label: phoneLabel }}
          name={`${formId}.${ADDRESS_FIELDS.phoneNumber.name}`}
          browserAutofillOffValue={browserAutofillOffValue}
        />
      )}
      {showSpecialInstructions && !isBilling && (
        <FieldInput
          {...{
            ...ADDRESS_FIELDS.specialInstructions,
            label: specialInstructionsLabel,
          }}
          name={`${formId}.${ADDRESS_FIELDS.specialInstructions.name}`}
        />
      )}
      {showAddressNickname && !isBilling && (
        <FieldInput
          {...{ ...ADDRESS_FIELDS.nickname, label: nicknameLabel }}
          name={`${formId}.${ADDRESS_FIELDS.nickname.name}`}
        />
      )}

      {showEmailOptIn && (
        <FieldCheckbox
          label={
            tenantName ? (
              <SignupDisclaimer
                tenantName={tenantName}
                termsUrl={termsLink?.url}
                privacyUrl={privacyLink?.url}
              />
            ) : (
              ADDRESS_FIELDS.emailOptIn.label
            )
          }
          name={`${formId}.${ADDRESS_FIELDS.emailOptIn.name}`}
        />
      )}

      {showIsDefault && (
        <FieldCheckbox
          label={isDefaultLabel}
          name={`${formId}.${ADDRESS_FIELDS.isDefault.name}`}
        />
      )}
    </div>
  );
}

export const getStateReactSelectOption = (
  statesConfig: StatesConfig[],
  countryValue: string,
  state: string
): ReactSelectDropdownOption | null => {
  const states = getStates(statesConfig, countryValue);
  return (states && states.find((st) => st.value === state)) || null;
};

export const getCountryReactSelectOption = (
  countriesAllowed: CountriesAllowed[],
  countryCode: string
): ReactSelectDropdownOption => {
  const countries = getCountries(countriesAllowed);
  return (
    (countries && countries.find((co) => co.value === countryCode)) ||
    UnitedStatesOption
  );
};

export const getStates = (
  statesConfig: StatesConfig[],
  countryCode = "US",
  selectedCountry?: ReactSelectDropdownOption
): ReactSelectDropdownOption[] => {
  if (!statesConfig) {
    return [];
  }
  const states = statesConfig.find(
    (config: StatesConfig) =>
      config.countryId === (selectedCountry?.value || countryCode || "US")
  );
  return states
    ? states.statesAllowed.map((state) => {
        return { value: state.id, label: state.name };
      })
    : [];
};

export const getCountries = (
  countries?: CountriesAllowed[]
): ReactSelectDropdownOption[] => {
  if (!countries) {
    return [];
  }
  return countries.map((country: CountriesAllowed) => ({
    value: country.id,
    label: country.name,
  }));
};
