import { AlertTypes } from "@pepdirect/v3/ui/Alert";
import { CheckoutErrorsLocalizations } from "v3/services/graphql/generated";

export type LocalizationCheckoutErrors = CheckoutErrorsLocalizations | null;

export interface CheckoutSessionErrorExtensions<ErrorKeys> {
  details?: {
    details?: CheckoutSessionError;
    key: ErrorKeys;
  }[];
}

export interface SubmitOrderErrorExtensions {
  details?: SubmitOrderErrorDetail[];
}

export interface SubmitOrderErrorDetail {
  details: ItemErrorDetail[] | CartErrorDetail;
  key: SubmitOrderErrorKeys;
  type: string;
}

export interface ItemErrorDetail {
  id: string;
  inventoryQty?: number;
  purchaseQtyLimit?: number;
}

export interface CartErrorDetail {
  minimumPurchaseAmount?: number;
  minimumPurchaseQuantity?: number;
}

export interface CartErrorMsg {
  alertType: AlertTypes;
  errorKey: ShopToCheckoutErrorKeys | SubmitOrderErrorKeys;
  msg: string;
}

export interface CartAndItemErrorExtensions<ErrorKeys> {
  details?: {
    details: CartErrorDetail | ItemErrorDetail[];
    key: ErrorKeys;
    type: string;
  }[];
}

export interface VerifyAddressErrorExtensions {
  details?: {
    details: AddressVerificationError | SuggestedAddressError | null;
    key: VerifyAddressErrorKeys;
    type: string;
  }[];
}

export interface AddressVerificationError {
  code: string;
}

export interface CheckoutSessionError {
  orderId: string;
}

export interface SuggestedAddressError {
  suggested: SuggestedAddress;
}

export interface SuggestedAddress {
  city: string;
  line1: string;
  line2: string | null;
  state: string;
  zip: string;
  countryCode?: string;
}

export interface UserAddressVerificationErrorExtensions {
  details?: (
    | {
        details: SuggestedAddressError;
        key: UserAddressVerificationErrorKeys.suggestedAddress;
        type: string;
      }
    | {
        key: UserAddressVerificationErrorKeys.addressNotFound;
      }
  )[];
}

export enum UserAddressVerificationErrorKeys {
  suggestedAddress = "suggestedAddress",
  addressNotFound = "addressNotFound",
}

export enum UpdateUserAddressErrorKeys {
  notFound = "notFound",
}

export enum UniversalCheckoutErrorKeys {
  cartNotFound = "cartNotFound",
  emptyCart = "emptyCart",
  sessionNotFound = "sessionNotFound",
  paymentProcessing = "paymentProcessing",
  orderProcessed = "orderProcessed",
  // calculation error, can happen on any checkout endpoint
  discountCannotApplyToSubscription = "discountCannotApplyToSubscription",
  discountExceedsSubtotal = "discountExceedsSubtotal",
  discountLimitExceeded = "discountLimitExceeded",
  discountPromoCodeNotApplicable = "discountPromoCodeNotApplicable",
  discountPromoCodeNotFound = "discountPromoCodeNotFound",
  discountPromoCodeRequiresEmail = "discountPromoCodeRequiresEmail",
  failedToCalculateTaxes = "failedToCalculateTaxes",
  incompleteShippingAddress = "incompleteShippingAddress",
  loyaltyDiscountTooLarge = "loyaltyDiscountTooLarge",
}

export enum ShopToCheckoutErrorKeys {
  lowStock = "lowStock",
  minimumPurchaseAmount = "minimumPurchaseAmount",
  minimumPurchaseQuantity = "minimumPurchaseQuantity",
  outOfStock = "outOfStock",
}

export enum SubmitOrderErrorKeys {
  archivedItemsInCart = "archivedItemsInCart",
  createSubmittedOrder = "createSubmittedOrder",
  guestCartContainsSubscription = "guestCartContainsSubscription",
  insufficientLoyaltyBalance = "insufficientLoyaltyBalance",
  invalidLoyaltyReward = "invalidLoyaltyReward",
  lowStock = "lowStock",
  loyaltyRedemptionFailure = "loyaltyRedemptionFailure",
  minimumPurchaseAmount = "minimumPurchaseAmount",
  minimumPurchaseQuantity = "minimumPurchaseQuantity",
  outOfStock = "outOfStock",
  paymentNotAccepted = "paymentNotAccepted",
  purchaseQtyLimit = "purchaseQtyLimit",
  shippingAddressValidation = "shippingAddressValidation",
  subscriptionOnlyInCart = "subscriptionOnlyInCart",
  unableToDeleteCart = "unableToDeleteCart",
  unapprovedItemsInCart = "unapprovedItemsInCart",
  bundleOnlyWithoutBundle = "bundleOnlyWithoutBundle",
  bundleItemWithoutBundle = "bundleItemWithoutBundle",
  unverifiedEmail = "unverifiedEmail",
  userWithoutAccount = "userWithoutAccount",
  virtualItemsInCart = "virtualItemsInCart",
  zipCodeRestrictionOnItems = "zipCodeRestrictionOnItems",
  zipCodeRestrictionOnTenant = "zipCodeRestrictionOnTenant",
  unableToApplyGiftCard = "unableToApplyGiftCard",
  noGiftCardBalance = "noGiftCardBalance",
}

export enum VerifyAddressErrorKeys {
  addressVerification = "addressVerification", // no suggestion
  checkoutAddressVerificationError = "checkoutAddressVerificationError",
  suggestedAddress = "suggestedAddress", // with suggestion
  unableToSaveVerificationOnShippingAddress = "unableToSaveVerificationOnShippingAddress",
  unableToUsePOBox = "unableToUsePOBox",
}

export enum ApplyDiscountErrorKeys {
  discountNotFound = "discountNotFound",
  unableToApplyDiscount = "unableToApplyDiscount",
  unableToSaveDiscount = "unableToSaveDiscount",
}

export enum RemoveDiscountErrorKeys {
  unableToRemoveDiscount = "unableToRemoveDiscount",
}

export enum AuthorizeGiftCardErrorKeys {
  giftCardMismatch = "giftCardMismatch",
  unableToApplyGiftCard = "unableToApplyGiftCard",
  zeroBalance = "zeroBalance",
}

export enum RetrieveAddressErrorKeys {
  unableToUsePOBox = "unableToUsePOBox",
}

export enum SaveShippingOptionsErrorKeys {
  unableToSaveShippingOptions = "unableToSaveShippingOptions",
}

export enum FinalizePriceErrorKeys {
  unableToSetStage = "unableToSetStage",
}

export enum SavePaymentOptionsErrorKeys {
  unableToSavePaymentOptions = "unableToSavePaymentOptions",
}

export enum SaveEnteredContactInfoErrorKeys {
  unableToSaveEnteredContactInfo = "unableToSaveEnteredContactInfo",
}

export enum SaveSuggestedAddressErrorKeys {
  unableToSaveEnteredContactInfo = "unableToSaveEnteredContactInfo",
}

// saveGiftInfo and removeGiftInfo
export enum GiftInfoErrorKeys {
  giftingDisabled = "giftingDisabled",
}

export enum ApplySavedShippingAddressErrorKeys {
  addressNotFound = "addressNotFound",
}

export enum SavePaymentErrorKeys {
  missingBillingAddress = "missingBillingAddress",
  paymentDataError = "paymentDataError",
  unableToSavePayment = "unableToSavePayment",
}

export enum VerifyUserErrorKeys {
  tokenNotFound = "tokenNotFound",
  tokenExpired = "tokenExpired",
}

export enum DeleteUserAddressErrorKeys {
  addressSubscriptionAssociation = "addressSubscriptionAssociation",
  unknownError = "unknownError",
}

export enum IsAddressValidForTaxKeys {
  invalidZip = "invalidZip",
}
