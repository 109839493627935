import { useMemo } from "react";
import {
  BrandConfigCustomizationsImage,
  PaymentProcessor,
} from "v3/services/graphql/generated";
// this is v2 because we are only using one tenant query
// and it needs to be v2 in getInitialProps where it is initially fetched
// once v2 is no longer used then this can be on v3
import { TenantQuery } from "services/graphql/generated";

export interface TenantContextInterface {
  isStateRequired: boolean;
  isStripeTenant: boolean;
  logoSmall: BrandConfigCustomizationsImage | null;
  tenant: TenantQuery["tenant"];
}

export function useTenantProvider(tenant: TenantQuery["tenant"]): {
  tenantContextValue: TenantContextInterface;
} {
  const isStripeTenant = useMemo(
    () => tenant?.paymentConfig.processor === PaymentProcessor.Stripe,
    [tenant]
  );

  const isStateRequired = useMemo(
    () => !!tenant?.contactConfig?.shippingAddressConfig?.isStateRequired,
    [tenant]
  );

  const logoSmall = useMemo(() => {
    return (
      tenant?.brandConfig?.customizations?.images?.find(
        (img: BrandConfigCustomizationsImage) => img.name === "logoSmallUrl"
      ) || null
    );
  }, [tenant]);

  const tenantContextValue: TenantContextInterface = {
    isStateRequired,
    isStripeTenant,
    logoSmall,
    tenant,
  };

  return { tenantContextValue };
}
