import { transformQueryParamToString } from "helpers/routing";
import { useRouter } from "next/router";

export function useCurrentAppId(): {
  appId: string;
} {
  const router = useRouter();
  const appId = transformQueryParamToString(router?.query?.appId);

  return { appId };
}
