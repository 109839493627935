import { SCREEN_SIZES } from "@pepdirect/shared/types";

export const Size = {
  mobileMin: 320,
  mobileMax: 767,
  tabletMin: 768,
  tabletMax: 1024,
  laptopMin: 1025,
  laptopMax: 1440,
};

export const getScreenBreakpoint = (width: number): SCREEN_SIZES => {
  if (width > Size.laptopMax) return "xl";
  else if (width >= Size.laptopMin) {
    return "l";
  } else if (width >= Size.tabletMin) {
    return "m";
  } else if (width >= Size.mobileMin) {
    return "s";
  } else {
    return "xs";
  }
};
