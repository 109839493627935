import { Cart } from "./graphql/generated";

export enum LOGIN_LOCATION_ENUM {
  header = "header",
  checkoutModal = "checkoutModal",
  checkoutSubscriptionModal = "checkoutSubscriptionModal",
}

export interface AnalyticsData {
  event: EVENT_NAMES;
  leadType?: string;
  leadSource?: string;
  userToken?: string;
  dateOfBirth?: string;
  // not currently used in v3
  // removing since Product (most similar to EmbeddedItem) is also not exposed on v3
  // TODO: when this is needed again, the types will need to be audited across all tenants
  // item?: Product | Partial<Item> | null;
  qty?: number;
  page?: string;
  referrer?: string;
  source?: string;
  medium?: string;
  campaign?: string;
  term?: string;
  isEmployerSignUp?: boolean;
  isWeWorkSignUp?: boolean;
  noInteraction?: boolean;
  cart?: Partial<Cart> | { subtotal?: number };
  loginLocation?: LOGIN_LOCATION_ENUM;
  email?: string;
  pageName?: string;
  popularBrand?: string;
  bestSellerTitle?: string;
  categoryTitle?: string;
  navigationName?: string;
  oldQuantity?: number;
  newQuantity?: number;
  intervalDays?: number | null;
  modalName?: string;
  step?: number;
  category?: string;
  action?: string;
  label?: string;
  nonInteraction?: boolean;
  formId?: string;
}

export interface FullAnalyticsData extends Omit<AnalyticsData, "email"> {
  email: boolean | string;
  username?: string;
}

export interface QueryStringAttributes {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  isEmployerSignUp?: string;
  isWeWorkSignUp?: string;
  isSignup?: string;
  returnUrl?: string;
}

export enum EVENT_NAMES {
  addressVerified = "addressVerified",
  bestSellersClick = "bestSellersClick",
  bundleProductClicked = "productClicked",
  cartCheckoutButton = "cartCheckoutButtonClicked",
  cartLineItem = "cartLineItemQtyChanged",
  cartLoginRedirect = "cartLoginRedirect",
  cartOutOfStock = "cartOutOfStockErrorDisplayed",
  cartViewed = "cartViewed",
  categoryClick = "categoryClick",
  generateLead = "generateLead",
  landingPageLogin = "landingPageLogin",
  loqateAutocompleteAddressSelected = "loqate.autocompleteAddressSelected",
  loqateAutocompleteInitiated = "loqate.autocompleteInitiated",
  modalClosed = "modalClosed",
  navigationClick = "navigationClick",
  pageView = "pageView",
  popularBrandsClick = "popularBrandsClick",
  productDetailBuyButton = "productDetailBuyButtonClicked",
  productOneTimePurchaseSelected = "product.oneTimePurchaseSelected",
  productSubAndSaveSelected = "product.subAndSaveSelected",
  productTileBuyButton = "productTileBuyButtonClicked",
  productViewed = "productViewed",
  unverifiedAddressSelected = "unverifiedAddressSelected",
  userChanged = "userChanged",
  userContinueAsGuest = "userContinueAsGuest",
  userLogin = "userLogin",
  userLoginSuccess = "signIn",
  userSignUp = "userSignUp",
  userSignupSuccess = "signup",
  formView = "formView",
  formStart = "formStart",
  formFail = "formFail",
  formAbandonment = "formAbandonment",
}
