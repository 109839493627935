import React from "react";
import { LocalizationContextInterface } from "./hooks/useLocalizationProvider";
import { genericErrorMsgEnglish } from "@pepdirect/v3/constants";

export const LocalizationContext =
  React.createContext<LocalizationContextInterface>({
    formatPriceByLocale: () => "",
    genericErrorMsg: genericErrorMsgEnglish,
    localization: null,
  });
