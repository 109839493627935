import { useState, useEffect, useCallback } from "react";
import { ModalType, ModalProps, ModalContextInterface } from "types/modal";

export function useModalProvider(): {
  modalContextValue: ModalContextInterface;
} {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenCalled, setIsModalOpenCalled] = useState(false);
  const [modalType, setModalType] = useState<ModalType>("");
  const [modalProps, setModalProps] = useState<ModalProps | null>(null);

  const handleOpen = useCallback(
    (type: ModalType, props?: ModalProps | null) => {
      setModalProps(props || null);
      setModalType(type);
      setIsModalOpenCalled(true);
    },
    []
  );
  const handleClose = useCallback(() => setIsModalOpen(false), []);

  // prevents stale data upon opening
  useEffect(() => {
    // we check if modalType since modalProps as null is a valid value
    if (isModalOpenCalled && modalType) {
      setIsModalOpen(true);
      setIsModalOpenCalled(false);
    }
  }, [isModalOpenCalled, modalType]);

  const modalContextValue: ModalContextInterface = {
    isOpen: isModalOpen,
    type: modalType,
    props: modalProps,
    open: handleOpen,
    close: handleClose,
  };

  return { modalContextValue };
}
