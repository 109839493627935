import { Product } from "../types/product";

export const ProductMock: Product = {
  appId: "1232",
  gtin: "2314",
  awaitingImportCorrection: false,
  clickCount: 0,
  compareAtPrice: 1600,
  createdAt: "2017-10-15T13:46:39.278Z",
  isApproved: true,
  isOutOfStock: false,
  itemColor: "N/A",
  itemCountryCity: "U.S.A.",
  itemDescription: `<div class="page">↵<div class="page">↵<div class="layoutArea">↵<div class="column"><span style="font-size: 16px;">Capture all your memos in our slender, lightweight canvas notebooks. Pick your pattern, and start jotting.<br /></span></div></div></div></div><div class="page"><div class="page"><div class="layoutArea"><div class="column"><div class="page"><div class="layoutArea"><ul>  <li><span style="font-size: 16px;">Printing Type: flat printing with gold foil</span></li><li><span style="font-size: 16px;">Additional Notes: canvas cover with sewn black stiching</span></li></ul>↵</div>↵</div>↵</div>↵</div>↵</div>↵</div>↵`,
  itemDesignerArtistBrandName: "Ramona & Ruth",
  itemDesignerBio: "<div></div>",
  itemDimensionUnits: null,
  itemDimensions: `5" x 8"`,
  itemHeight: "",
  itemImgUrl:
    "https://decovoassets.blob.core.windows.net/product-imgs/original/116297e1-36f4-496b-b7c1-8e1d91d3a7a4_dots-notebookjpg.jpg",
  itemImgVariantUrls: [],
  itemLength: "",
  itemMaterial: "N/A",
  itemPackagedHeight: "",
  itemPackagedLength: "",
  itemPackagedWidth: "",
  itemPrice: 12,
  itemPrimaryCategory: ["office & desk"],
  itemReturnsPolicy: "",
  itemSecondaryCategory: ["paper goods", "desk accessories"],
  itemShippingInfo: "within_3_days",
  itemSlug: "dots_notebook__single_notebook_ce7aec41",
  itemSlugHash: "eed07b6a2cc2e0191c12678ff788b47956741b3a",
  itemTags: [],
  itemTertiaryCategory: ["notebooks"],
  itemTitle: "Dots Notebook | Single",
  itemUser: {},
  itemVariantOptions: [],
  variantOptions: [],
  itemWeightOz: "0.00",
  itemWidth: "",
  meltRisk: false,
  onMarketplace: true,
  primaryCategoryPriority: 0,
  secondaryCategoryPriority: 1,
  shopifyId: "10308338305",
  shopifyVariantId: "38568865601",
  storyId: "",
  storyIds: ["5bc4f69f2c7ca6000f72385c"],
  updatedAt: "2018-10-28T21:21:58.369Z",
  vendorId: "9061a06f-d938-46ed-8a98-e9062b1c84ef",
  vendorShopifyShopId: "12002560",
  vendorShopifyShopItemQuantity: 102,
  vendorShopifyShopItemQuantityOld: 102,
  vendorShopifyShopProductId: "6493219719",
  vendorShopifyShopUrl: "ramona-ruth.myshopify.com",
  vendorShopifyShopVariantId: "20717022663",
  viewCount: 0,
  _id: "59e366bf0321b71600e8f866",
  isDairyFree: false,
  isFairTrade: null,
  isGMOFree: null,
  isGlutenFree: null,
  isKosher: null,
  isOrganic: null,
  isVegan: null,
  isWheatFree: null,
  isYeastFree: null,
  itemISBN: "",
  itemUPC: "",
  productPacks: 1,
  productsPerPack: 1,
  vendorProductId: "",
  subscriptionMetadata: {
    discount: null,
    isSubscribable: false,
    subscriptionPrice: null,
  },
};
