import React, { FC } from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  fill: ${({ theme }) => theme.color.primary};
`;

// TODO: eventually remove this component in favor of modifying CaretIconSvg to handle left pointing direction to mimic
// a Left Chevron
export const ChevronIconSvg: FC = () => {
  return (
    <StyledSvg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.46118 11.8894L0.571777 6L6.46118 0.110596L7.42871 1.07812L2.50683 6L7.42871 10.9219L6.46118 11.8894Z" />
    </StyledSvg>
  );
};
