import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import client from "services/graphql";
import { Button } from "@pepdirect/ui/button";
import { DiscountsContext } from "context/discounts";
import { ModalContext } from "context/modal";
import { CheckoutContext } from "context/checkout";
import { logPromoCode } from "services/analytics";
import {
  CreateOrderMutation,
  SearchDiscountsDocument,
  SearchDiscountsQuery,
  SearchDiscountsQueryVariables,
  useCalculateOrderDiscountsMutation,
  useCurrentUserQuery,
} from "services/graphql/generated";
import { formatDollarsFromCents } from "helpers/currency";
import { getDiscountAmount } from "helpers/price";
import { PriceSummary } from "types/price";
import st from "./promoCode.module.scss";
import Cookies from "universal-cookie";
import URI from "urijs";
import { useLazyQuery } from "@apollo/client";
import { transformPriceFragmentToInput } from "helpers/checkout";

const cookie = new Cookies();
const AUTO_APPLY_COOKIE_KEY = "autoApplyPromoCode";

interface PromoCodeProps {
  price: PriceSummary;
  disableRemoval?: boolean;
  order?: NonNullable<CreateOrderMutation["createOrder"]>;
}

type DiscountErrorCodes =
  | "INVALID_CODE"
  | "AMOUNT_TOO_LARGE"
  | "CANNOT_APPLY_TO_SUBSCRIPTION"
  | "CANNOT_APPLY_TO_ORDER";

export const ErrorCodeMap: Record<DiscountErrorCodes, string> = {
  INVALID_CODE:
    "Items in your cart may be excluded, have a discount, or this code may be invalid.",
  AMOUNT_TOO_LARGE: "Sorry, your subtotal is too low for this promo code.",
  CANNOT_APPLY_TO_SUBSCRIPTION:
    "Sorry, this discount cannot be combined with a subscription discount.",
  CANNOT_APPLY_TO_ORDER: "Sorry, your cart does not qualify for this discount.",
};

export const PromoCode: React.FunctionComponent<PromoCodeProps> = ({
  price,
  disableRemoval = false,
  order,
}) => {
  const { discounts, hasDiscounts, applyDiscounts } =
    useContext(DiscountsContext);
  const { open } = useContext(ModalContext);
  const { setCreatedOrFetchedOrder } = useContext(CheckoutContext);

  const discountAmountDisplay = useMemo(() => {
    return formatDollarsFromCents(getDiscountAmount(price, discounts));
  }, [discounts, price]);

  const { data } = useCurrentUserQuery();
  const [getSearchDiscounts] = useLazyQuery<
    SearchDiscountsQuery,
    SearchDiscountsQueryVariables
  >(SearchDiscountsDocument, { client });
  const [calculateOrderDiscounts] = useCalculateOrderDiscountsMutation();

  const [promoCode, setPromoCode] = useState("");
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [fieldError, setFieldError] = useState<DiscountErrorCodes | null>(null);
  const autoApplyDiscountCode = cookie.get(AUTO_APPLY_COOKIE_KEY);

  useEffect(() => {
    if (autoApplyDiscountCode) {
      if (promoCode) {
        handleSubmitPromo();
      } else {
        setPromoCode(autoApplyDiscountCode);
      }
      setShowPromoInput(true);
    }
    // Ignoring dep warning because useCurrentUserQuery gets called again on
    // rerenders because we have apollo cache turned off, which causes infinite
    // looping. We could compare current useCurrentUserQuery data to next data
    // with useRef, but it adds lots of extra code that feels very cluttered and
    // like an unnecessary optimization.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoApplyDiscountCode, promoCode]);

  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    // focus on the input when it first opens so user can start
    // typing in their promo code
    if (showPromoInput && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPromoInput]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPromoCode(e.target.value.trim());

  const logEvent = (isValid: boolean) => {
    if (data?.currentUser) {
      logPromoCode(promoCode, isValid, data.currentUser);
    }
  };

  const handleRemovePromo = () => {
    if (hasDiscounts) {
      applyDiscounts([]);
      cookie.set(AUTO_APPLY_COOKIE_KEY, "", {
        path: "/",
        domain: `.${URI(window.location.href).domain()}`,
      });
      setPromoCode("");
    }
    setShowPromoInput(true);
  };

  const handleSubmitPromo = async () => {
    if (!promoCode) return;

    try {
      const { data } = await getSearchDiscounts({
        variables: {
          input: {
            promoCode,
          },
        },
      });

      const discountSearch = data?.searchDiscounts?.discounts || [];

      if (!discountSearch || !discountSearch.length) {
        // no discounts found for the promo code provided
        logEvent(false);
        setFieldError("INVALID_CODE");
        return;
      }

      const discountAmount = getDiscountAmount(price, discountSearch);

      // TODO: REMOVE AFTER DOLLAR AMOUNT DISCOUNT REFACTOR
      const isAmountTooLarge =
        (price?.userDiscountableSubtotal || 0) - discountAmount <= 0;
      const isSubscriptionTooLarge =
        isAmountTooLarge && price.userNonDiscountableSubtotal;
      let canPromoBeAppliedToOrder = true;

      // searchDiscounts returns all available discounts, however we also need to
      // check if it can be applied to the order (i.e. for brand discounts).
      if (order) {
        const { data } = await calculateOrderDiscounts({
          variables: {
            input: {
              orderId: order.id,
              price: transformPriceFragmentToInput({
                ...order.price,
                discounts: discountSearch,
              }),
            },
          },
        });

        const { calculateOrderDiscounts: orderWithDiscounts } = data || {};

        if (orderWithDiscounts?.order) {
          setCreatedOrFetchedOrder({
            ...order,
            price: orderWithDiscounts.order?.price,
          });
        }
        canPromoBeAppliedToOrder =
          !!orderWithDiscounts?.order?.price.discounts?.length;
      }

      if (isSubscriptionTooLarge) {
        setFieldError("CANNOT_APPLY_TO_SUBSCRIPTION");
        return;
      } else if (isAmountTooLarge) {
        setFieldError("AMOUNT_TOO_LARGE");
        return;
      } else if (!canPromoBeAppliedToOrder) {
        setFieldError("CANNOT_APPLY_TO_ORDER");
        return;
      }

      // Happy Path: this is a valid promo code
      logEvent(true);
      applyDiscounts(discountSearch);
      setShowPromoInput(false);
      setFieldError(null);
    } catch (error) {
      open("ERROR_MODAL");
    }
  };

  const handleApplyPromoClick = () => {
    setShowPromoInput(!showPromoInput);
  };

  return (
    <div className={st.promoCodeWrapper}>
      {!!discounts.length && !fieldError ? (
        <div className={st.promoApplied}>
          <div className={st.promoText}>
            {`${discounts[0].promoCode} (-${
              discounts[0].isPercent
                ? `${discounts[0].amount || 0}%`
                : `${formatDollarsFromCents(discounts[0].amount || 0)}`
            })
            `}
            {!disableRemoval && (
              <button className={st.remove} onClick={handleRemovePromo}>
                ×
              </button>
            )}
          </div>
          <div className={st.discountPrice}>-{discountAmountDisplay}</div>
        </div>
      ) : (
        <div className={st.promo}>
          <div className={st.promoContainer}>
            {!showPromoInput && (
              <span
                className={st.applyPromoCode}
                onClick={handleApplyPromoClick}
                role="button"
              >
                Have a coupon code?
              </span>
            )}
            {showPromoInput && (
              <>
                <input
                  ref={inputRef}
                  className={st.inputField}
                  onChange={handleInputChange}
                  placeholder="Enter promotion code"
                  value={promoCode}
                  data-testid="promo-code-input"
                />
                <Button onClick={handleSubmitPromo} type="tertiary">
                  Apply
                </Button>
              </>
            )}
          </div>
          {fieldError && (
            <div className={st.error}>{ErrorCodeMap[fieldError]}</div>
          )}
        </div>
      )}
    </div>
  );
};
