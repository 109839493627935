import React, { FC } from "react";
import styled from "styled-components";
import { ExternalLink } from "../link/ExternalLink";
import { colors } from "../../styles/variables";

const StyledDisclaimer = styled.div`
  width: 100%;
  font-size: 13px;
  line-height: 15.51px;
  margin-left: 10px;
  color: ${colors.gray};
`;

const StyledExternalLink = styled(ExternalLink)`
  color: ${({ theme }) => theme.color.primary};
  text-decoration: underline;
`;

interface DisclaimerProps {
  tenantName: string;
  termsUrl?: string;
  privacyUrl?: string;
  fallbackUrl?: string;
}

export const SignupDisclaimer: FC<DisclaimerProps> = ({
  tenantName,
  termsUrl,
  privacyUrl,
  fallbackUrl = "",
}) => {
  return (
    <StyledDisclaimer>
      Yes, I&apos;d like to opt-in to receive additional product information,
      news and special offers from {tenantName}, PepsiCo Tasty Rewards and
      PepsiCo brands. Please see our{" "}
      <StyledExternalLink href={privacyUrl || `${fallbackUrl}/privacy`}>
        Privacy Policy
      </StyledExternalLink>
      {", "}
      <StyledExternalLink href={termsUrl || `${fallbackUrl}/terms`}>
        Terms of Use
      </StyledExternalLink>{" "}
      and About our Ads for details -- you may opt out at any time.
    </StyledDisclaimer>
  );
};
