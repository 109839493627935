import React, { FC, useContext } from "react";
import { ErrorModal } from "components/Modals/ErrorModal";
import { formatDollarsFromCents } from "helpers/currency";
import { scrollTo } from "helpers/scroll";
import { MinimumErrorProps } from "types/modal";
import { TenantContext } from "v3/context/tenant";

interface MinimumErrorModalProps extends MinimumErrorProps {
  onClose: () => void;
}

const getErrorText = (
  type: "amount" | "quantity" | undefined,
  value: number | undefined
): string => {
  if (type === "amount") {
    return "You have not met the ##variable minimum for your order. Please update your cart to complete your order.".replace(
      "##variable",
      value ? formatDollarsFromCents(value) : ""
    );
  } else {
    return "Please ensure a minimum quantity of ##variable items to proceed with your purchase.".replace(
      "##variable",
      (value || "").toString()
    );
  }
};

export const MinimumErrorModal: FC<MinimumErrorModalProps> = ({
  type,
  onClose,
}) => {
  const { tenant } = useContext(TenantContext);
  const minQuantity = tenant?.shoppingBagConfig?.minimumPurchaseQty;
  const minAmount = tenant?.shoppingBagConfig?.minimumPurchaseAmount;
  const error = getErrorText(type, type === "amount" ? minAmount : minQuantity);
  const errorHeader = "Add More Items to Cart";
  const close = () => {
    onClose();
    scrollTo({ top: 60, isDelayed: true });
  };

  return (
    <ErrorModal
      error={error}
      header={errorHeader}
      buttonType="cancel"
      buttonText="Go back"
      onClose={close}
    />
  );
};
