import React from "react";
import { HexColor } from "../../types/styles";
import { colors } from "../../styles/variables";

interface CheckIconSvgProps {
  color?: HexColor;
  height?: string;
  width?: string;
}

export const CheckIconSvg = ({
  color = colors.gray900,
  height = "20",
  width = "20",
}: CheckIconSvgProps) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 20 20"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.25 9.92087L8.85208 13.523L15.5417 6.83337"
      stroke={color}
      strokeLinecap="round"
      strokeWidth="1.6"
    />
  </svg>
);
