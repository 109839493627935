import {
  FindAddress,
  RetrieveAddress,
  SelectedItemProps,
} from "../types/autocomplete";

export const FindAddressApiResponseMock1: FindAddress[] = [
  {
    Id: "US|US|B|Z222408617|444",
    Type: "Address",
    Text: "444 Laurel St San Carlos CA 94070",
    Highlight: "0-3",
    Description: "",
  },
  {
    Id: "US|US|ENG|94070-CA-SAN_CARLOS--ST-LAUREL--444",
    Type: "BuildingNumber",
    Text: "444 Laurel St San Carlos CA 94070",
    Highlight: "0-3",
    Description: "- 2 Addresses",
  },
];

export const FindAddressMock1: SelectedItemProps[] = [
  {
    id: "US|US|B|Z222408617|444",
    type: "Address",
    value: "444 Laurel St San Carlos CA 94070",
    label: "444 Laurel St San Carlos CA 94070",
  },
  {
    id: "US|US|ENG|94070-CA-SAN_CARLOS--ST-LAUREL--444",
    type: "BuildingNumber",
    value: "444 Laurel St San Carlos CA 94070",
    label: "444 Laurel St San Carlos CA 94070 - 2 Addresses",
  },
];

export const FindAddressApiResponseMock2: FindAddress[] = [
  {
    Id: "US|US|B|Z222408618|A",
    Type: "Address",
    Text: "444 Laurel St Apt A San Carlos CA 94070",
    Highlight: "",
    Description: "",
  },
  {
    Id: "US|US|B|Z222408618|B",
    Type: "Address",
    Text: "444 Laurel St Apt B San Carlos CA 94070",
    Highlight: "",
    Description: "",
  },
];

export const FindAddressMock2: SelectedItemProps[] = [
  {
    id: "US|US|B|Z222408617|444",
    type: "Address",
    value: "444 Laurel St Apt A",
    label: "444 Laurel St Apt A San Carlos CA 94070",
  },
  {
    id: "US|US|B|Z222408618|B",
    type: "Address",
    value: "444 Laurel St Apt B",
    label: "444 Laurel St Apt B San Carlos CA 94070",
  },
];

export const RetrieveAddressMock: RetrieveAddress = {
  city: "San Carlos",
  country: "US",
  line1: "444 Laurel St",
  line2: "",
  state: "CA",
  zip: "94070-2421",
};
