import { useEffect, useState } from "react";
import { logWithMessageInSentry } from "../helpers/logAndCaptureInSentry";
import { BrandConfigTrackingFonts } from "../types/graphql/generated";
import { FontResources } from "../types/font";

export const getTrackingResource = (
  font: BrandConfigTrackingFonts,
  brandTitle: string
): FontResources | null => {
  const { isTrackingRequired, resource } = font || {};
  if (isTrackingRequired) {
    if (!resource || !(resource in FontResources)) {
      const availableResources = [];
      for (const val in FontResources) {
        availableResources.push(val);
      }
      logWithMessageInSentry(
        `WARNING: Unknown font resource "${resource}" in tenant config ` +
          `for ${brandTitle}, must update to ensure font is properly tracked. ` +
          `Available resources: ${availableResources.join(", ")}`
      );
      return null;
    }
    return FontResources[resource as FontResources];
  }
  return null;
};

export function useFontTracking(
  // TODO: BE needs to fix this type to not have Maybe inside the array
  fonts: (BrandConfigTrackingFonts | null | undefined)[],
  title: string
): FontResources[] {
  const [trackFontResources, setTrackFontResources] = useState<FontResources[]>(
    []
  );

  useEffect(() => {
    fonts.forEach((font) => {
      if (!font) return;
      const resource = getTrackingResource(font, title);
      if (resource) {
        setTrackFontResources((prev) =>
          prev.includes(resource) ? prev : prev.concat(resource)
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return trackFontResources;
}
