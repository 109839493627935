import { Cart, CartItem } from "../types/cart";
import { ErrorResponse } from "../types/error";

export const emptyCart: Cart = {
  appId: "",
  id: "",
  items: [],
};

export const product1: CartItem = {
  id: "",
  quantity: 10,
  product: {
    id: "123",
  },
  subscriptionIntervalInDays: null,
};

export const product2: CartItem = {
  id: "",
  quantity: 12,
  product: {
    id: "456",
  },
  subscriptionIntervalInDays: null,
};

export const cartWithProducts: Cart = {
  ...emptyCart,
  items: [product1, product2],
};

export const errorsArray: ErrorResponse = {
  response: {
    data: {
      data: {
        message: "",
        errors: [
          {
            messages: ["first error 1 message", "first error 2 message"],
          },
          {
            messages: ["second error 1 message", "second error 2 message"],
          },
        ],
      },
      status: 404,
      success: false,
    },
  },
};

export const errorMessage: ErrorResponse = {
  response: {
    data: {
      data: {
        message: "error response",
      },
      status: 404,
      success: false,
    },
  },
};

export const emptyErrorMessage: ErrorResponse = {
  response: {
    data: {
      data: {
        message: "",
      },
      status: 404,
      success: false,
    },
  },
};
