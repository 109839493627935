interface windowWithPepdirect extends Window {
  _pepdirect?: Record<string, string>;
}

declare const window: windowWithPepdirect;

export const addGitShaToWindow = () => {
  if (typeof window !== "undefined") {
    window._pepdirect = {
      ...window._pepdirect,
      gitSha:
        process.env.NEXT_PUBLIC_GIT_SHA || "NEXT_PUBLIC_GIT_SHA is not set",
    };
  }
};
