import { GraphQLError } from "graphql";
import { genericErrorMsgEnglish } from "../constants";
import { logUnknownErrorCode } from "./logAndCaptureInSentry";
import {
  GenericErrorExtensions,
  LoginErrorKeys,
  SignupErrorKeys,
} from "../types/error";
import { FormsSignInLocalization } from "../types/graphql/generated";
export const parseLoginGraphQLErrors = (
  graphQLErrors: GraphQLError[],
  localization?: FormsSignInLocalization | null
) => {
  let errorMsg = "";
  let passwordResetRequired = false;
  let emailVerificationRequired = false;

  // 4 possible error keys are: error, account_locked, password_reset_required, emailVerificationRequired

  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as GenericErrorExtensions<LoginErrorKeys>)?.details ||
      [];
    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case LoginErrorKeys.accountLocked:
          errorMsg =
            localization?.accountLockedErrorText ||
            "Account locked. Please contact support for assistance.";
          break;
        case LoginErrorKeys.error:
          errorMsg =
            localization?.genericErrorText ||
            "Oops, that email and password combination isn't correct. Please check and try again or try resetting your password.";
          break;
        case LoginErrorKeys.passwordResetRequired:
          errorMsg = "";
          passwordResetRequired = true;
          break;
        case LoginErrorKeys.emailVerificationRequired:
          errorMsg = "";
          emailVerificationRequired = true;
          break;
        default:
          logUnknownErrorCode(key, "login");
          errorMsg = genericErrorMsgEnglish;
      }
    });
  });

  return { errorMsg, passwordResetRequired, emailVerificationRequired };
};

export const parseSignupGraphQLErrors = (graphQLErrors: GraphQLError[]) => {
  let errorMsg = "";
  let emailVerificationRequired = false;

  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as GenericErrorExtensions<SignupErrorKeys>)?.details ||
      [];
    // non-array errorDetails will get logged to Sentry in errorLink
    if (!Array.isArray(errorDetails)) return;
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case SignupErrorKeys.unverifiedEmail:
          errorMsg = genericErrorMsgEnglish;
          break;
        case SignupErrorKeys.emailVerificationRequired:
          emailVerificationRequired = true;
          break;
        default:
          logUnknownErrorCode(key, "signup");
          errorMsg = genericErrorMsgEnglish;
      }
    });
  });

  return { errorMsg, emailVerificationRequired };
};
