import { HexColor } from "@pepdirect/shared/types";

export type colorNames =
  | "darkGray"
  | "gray"
  | "mediumGray"
  | "lightGray"
  | "borderGray"
  | "lightestGray"
  | "red"
  | "orange"
  | "green"
  | "white"
  | "black"
  | "yellow"
  | "brown"
  | "darkBlue"
  | "formBlue"
  | "dropdownSelectedBlue"
  | "accent"
  | "lightGreen"
  | "dropdownHoverBlue"
  | "gray300"
  | "gray600"
  | "gray900";

export const colors: Record<colorNames, HexColor> = {
  darkGray: "#222",
  mediumGray: "#666",
  gray: "#999",
  lightGray: "#f6f6f6",
  borderGray: "#e5e3e7",
  lightestGray: "#bcbcbc",
  red: "#a62635",
  orange: "#e07647",
  green: "#00883f",
  lightGreen: "#AFCE2D",
  white: "#fff",
  black: "#000",
  yellow: "#f3c530",
  brown: "#8e6451",
  darkBlue: "#13294B",
  formBlue: "#1069d1",
  dropdownSelectedBlue: "#009CDD1A",
  dropdownHoverBlue: "#009CDD4D",
  accent: "#6F7172",
  gray300: "#ccc",
  gray600: "#666",
  gray900: "#1a1a1a",
};
