interface ScrollOptions {
  ref?: React.RefObject<HTMLDivElement>;
  isDelayed?: boolean;
  top?: number;
}

export const scrollTo = ({ ref, isDelayed, top }: ScrollOptions): void => {
  const scroll = () => {
    window.scrollTo({
      left: 0,
      top: ref?.current?.offsetTop || top || 0,
      behavior: "smooth",
    });
  };

  // setting timeout to allow Chrome / IE / Edge  to be
  // ready to take scroll action (otherwise it
  // inconsistently seemingly doesn't scroll as expected)
  setTimeout(scroll, isDelayed ? 500 : 200);
  return;
};
