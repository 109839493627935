export const appIds = {
  GatoradeCom: "ef66ee25-eb8f-40b1-af20-3da1b508b1b8",
  GatoradeTeamzone: "5557f6ea-7402-4309-a153-35c9faa52837",
  PantryShop: "b0414f96-89ee-4a2d-94ea-9b062d2a7212",
  PepsiCoShop: "ce256574-90a0-443b-b7a9-c68ea4dc178f",
  RockstarEnergy: "a2789465-7de3-454f-a326-118fcc3e2c33",
  SodaStreamPL: "e0225c6f-2285-493c-8205-8f42a92b8655",
  SodaStreamPro: "808c8c05-42d8-4b2e-bd08-357523ac9788",
  SodaStreamUS: "ad39626e-fcb2-42d8-ba73-aa5f505d826c",
};
