import React, { FC, useContext } from "react";
import { NoNetworkConnectionModal as NoNetworkModal } from "@pepdirect/ui/modal/NoNetworkConnectionModal";
import { TenantContext } from "v3/context/tenant";

const NoNetworkConnectionModal: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { tenant } = useContext(TenantContext);

  return <NoNetworkModal logoSrc={tenant?.brandConfig?.logoUrl || ""} />;
};

export default NoNetworkConnectionModal;
