import React, { FC } from "react";
import styled from "styled-components";
import { HexColor } from "@pepdirect/shared/types";
import { colors } from "../../styles/variables";

const StyledDivider = styled.div<{ color?: HexColor }>`
  border-top: ${({ color, theme }) =>
    `1px solid ${color || theme.dividerColor || colors.gray}`};
`;

interface DividerProps {
  color?: HexColor;
  // https://styled-components.com/docs/advanced#styling-normal-react-components
  // this is needed to extend styles, ex:
  //
  // const StyledDivider = styled(Divider)`
  //   margin: 10px 0;
  // `;
  className?: string;
}

export const Divider: FC<DividerProps> = ({ color, className }) => (
  <StyledDivider
    data-testid="divider"
    color={color}
    className={className || ""}
  />
);
