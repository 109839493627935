import React, { FC } from "react";
import styled from "styled-components";

const BoldOptionText = styled.span`
  font-weight: 700;
`;

interface AutocompleteOptionLabelProps {
  label: string;
  inputValue: string;
}

export const AutocompleteOptionLabel: FC<AutocompleteOptionLabelProps> = ({
  label,
  inputValue,
}) => {
  const boldStartIndex = label.toLowerCase().indexOf(inputValue.toLowerCase());
  if (boldStartIndex === -1)
    return <span data-testid="label-no-bold">{label}</span>;

  const boldEndIndex = boldStartIndex + inputValue.length;

  const unboldedLabelBefore = label.slice(0, boldStartIndex);
  const boldedLabel = label.slice(boldStartIndex, boldEndIndex);
  const unboldedLabelAfter = label.slice(boldEndIndex);

  return (
    <>
      <span data-testid="label-unbolded-before">{unboldedLabelBefore}</span>
      <BoldOptionText data-testid="label-bolded">{boldedLabel}</BoldOptionText>
      <span data-testid="label-unbolded-after">{unboldedLabelAfter}</span>
    </>
  );
};
