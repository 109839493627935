import { useState, useCallback } from "react";
import { DiscountsContextInterface } from "types/discounts";
import { DiscountFragment } from "services/graphql/generated";

/**
 * Helper function to remove __typename from the Discount objects so that
 * it's compatible with DiscountsInput type
 */
const removeTypename = (discounts: DiscountFragment[]) => {
  for (const discount of discounts) {
    delete discount.__typename;
  }
};

export function useDiscountsProvider(): {
  discountsContextValue: DiscountsContextInterface;
} {
  const [discountsState, setDiscountsState] = useState<DiscountFragment[]>([]);

  const handleApplyDiscounts = useCallback(
    (newDiscounts: DiscountFragment[]) => {
      removeTypename(newDiscounts);
      setDiscountsState(newDiscounts);
    },
    []
  );

  const discountsContextValue: DiscountsContextInterface = {
    discounts: discountsState,
    hasDiscounts: !!discountsState && discountsState?.length > 0,
    applyDiscounts: handleApplyDiscounts,
  };

  return { discountsContextValue };
}
