import React from "react";
import { HexColor } from "../../types/styles";
import { colors } from "../../styles/variables";

interface CheckCircledIconSvgProps {
  color?: HexColor;
}

export const CheckCircledIconSvg = ({
  color = colors.gray900,
}: CheckCircledIconSvgProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.6566 7.87548C19.0443 8.27026 19.0443 8.90822 18.6566 9.303L10.7163 17.3881C10.3286 17.7829 9.70206 17.7829 9.31435 17.3881L5.34333 13.3455C4.95575 12.9508 4.95575 12.3128 5.34333 11.918C5.73098 11.5232 6.35938 11.5232 6.74709 11.918L9.98741 15.2437L17.2546 7.87548C17.6424 7.48007 18.2689 7.48007 18.6566 7.87548Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.74707 11.9181L9.98739 15.2437L17.2546 7.87553C17.6423 7.48012 18.2689 7.48012 18.6566 7.87553C19.0443 8.27031 19.0443 8.90828 18.6566 9.30306L10.7163 17.3882C10.3286 17.7829 9.70204 17.7829 9.31433 17.3882L5.34332 13.3456C4.95573 12.9508 4.95573 12.3129 5.34332 11.9181C5.73096 11.5233 6.35936 11.5233 6.74707 11.9181ZM9.13409 17.5652L5.16309 13.5226C4.67903 13.0296 4.67898 12.2341 5.16304 11.7411C5.64972 11.2455 6.4406 11.2455 6.92732 11.7411L9.98849 14.8828L17.0742 7.69866C17.0741 7.69875 17.0743 7.69857 17.0742 7.69866C17.561 7.2025 18.3503 7.20233 18.837 7.69866C19.321 8.19173 19.321 8.98707 18.8368 9.48008L10.8965 17.5652C10.4098 18.0608 9.62081 18.0608 9.13409 17.5652C9.13409 17.5652 9.13408 17.5652 9.13409 17.5652Z"
      fill={color}
    />
    <circle cx="12" cy="12" r="11" stroke={color} strokeWidth="2" />
  </svg>
);
