import React, { FC } from "react";
import { FontResources } from "../../types/font";

const iFrameSrc: Record<FontResources, string> = {
  "fonts.com": "/font-tracking/fonts-com.html",
  "myfonts.net": "/font-tracking/myfonts-net.html",
};

const SANDBOX_SRC = "http://example.com";

interface FontTrackingiFrameProps {
  isLocalEnv: boolean;
  resource: FontResources;
}

/**
 * Usage:
 * - track licensed fonts from font resources
 * - fontTrackingiFrame.html must be placed directly in the app's public folder
 */
export const FontTrackingiFrame: FC<FontTrackingiFrameProps> = ({
  isLocalEnv,
  resource,
}) => (
  <iframe
    src={isLocalEnv ? SANDBOX_SRC : iFrameSrc[resource]}
    name="fontTracking"
    title={`Font Tracking - ${resource}`}
    height="0"
    width="0"
    loading="lazy"
    style={{
      position: "absolute",
      visibility: "hidden",
    }}
  />
);

// current html for font tracking:
// <!DOCTYPE html>
// <html>
//   <head>
//     <meta charset="UTF-8" />
//     <title>Font Tracking</title>
//     <link
//       type="text/css"
//       rel="stylesheet"
//       href="<RESOURCE-SPECIFIC-HREF-GOES-HERE>"
//     >
//   </head>
// </html>
