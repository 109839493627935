import React, { useEffect, useState, useContext, useRef } from "react";
import styled, { css, ThemeContext } from "styled-components";
import { InternalLink } from "../link/InternalLink";
import { Spinner } from "../spinner/Spinner";
import { ButtonProps, StyledButtonProps } from "./types";
import { getSpinnerColor, getThemeValues } from "./helpers";
import { colors } from "../../styles/variables";

/*----------
   styles
-----------*/
const transparentBgStyles = css`
  background-color: transparent;
  border-style: solid;
`;

const fillAndHoverStyles = css<StyledButtonProps>`
  ${({ addDisabledStyles }) =>
    addDisabledStyles ? disabledStyles : fillAndHoverDefaultStyles}
`;

const hollowStyles = css<StyledButtonProps>`
  ${({ color, theme }) => `
    ${transparentBgStyles};
    border-width: ${theme.button.hasThinBorder ? "1px" : "2px"};
    padding: ${
      theme.button.padding ||
      (theme.button.hasThinBorder ? "9px 24px" : "8px 23px")
    };
    color: ${color};
    border-color: ${color};
  `}
`;

const fillAndHoverDefaultStyles = css<StyledButtonProps>`
  ${({ color, textColor, hollow, theme, fontSize }) =>
    hollow
      ? hollowStyles
      : `
    background-color: ${color};
    color: ${textColor || colors.white};
    border: 0;
  `}

  &:hover {
    ${({ solidOnHover, hollowOnHover, color, textColor, disabled }) => {
      if (disabled) return;
      if (solidOnHover)
        return `
        background-color: ${color};
        color: ${textColor || colors.white};
      `;
      if (hollowOnHover) return hollowStyles;
      return `
        background-image: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.15) 0%,
          rgba(0, 0, 0, 0.15) 100%
        )
      `;
    }}
  }
`;

const textStyles = css<StyledButtonProps>`
  background: none;
  border: none;
  text-transform: none;
  font-family: inherit;
  padding: 0;
  width: auto;
  ${({ theme, addDisabledStyles }) => {
    if (addDisabledStyles) {
      return `  border-color: ${theme.color.disabled};
      color: ${theme.color.disabled};
      cursor: not-allowed;`;
    }
    return `
    color: ${theme.button.text.color};`;
  }}
`;

const disabledStyles = css`
  cursor: not-allowed;

  ${({ theme }) =>
    theme.button.disabled?.hollow
      ? `
    border-color: ${theme.color.disabled};
    color: ${theme.color.disabled};
    ${transparentBgStyles};
    border-width: ${theme.button.hasThinBorder ? "1px" : "2px"};
    padding: ${
      theme.button.padding ||
      (theme.button.hasThinBorder ? "9px 24px" : "8px 23px")
    };
  `
      : `
    border: none;
    background-color: ${theme.color.disabled};
    color: ${colors.white};
  `}
`;

const smallStyles = css`
  padding-left: 0;
  padding-right: 0;
  font-weight: 700;
  text-align: center;
  width: 40px;
`;

const positionStyles = css<StyledButtonProps>`
  flex-grow: 0;
  ${({ position, theme }) => {
    const borderRadius =
      theme.button.borderRadius || theme.borderRadius || "4px";

    if (position === "left")
      return `
      border-radius: ${borderRadius} 0 0 ${borderRadius};
    `;
    if (position === "right")
      return `
      border-radius: 0 ${borderRadius} ${borderRadius} 0;
    `;
  }}
`;

const themedStyles = css<StyledButtonProps>`
  ${({ styleType }) => {
    if (styleType === "text") return textStyles;
    return fillAndHoverStyles;
  }}
`;

const buttonStyles = css<StyledButtonProps>`
  box-sizing: border-box;
  font-size: ${({ fontSize }) => fontSize || "16px"};
  /* THIS IS A TEMP CHANGE FOR G.COM UNTIL WE CAN UPDATE THE BUTTONS I HATE IT TOO */
  padding: ${({ theme }) => (theme.button.padding ? "19px 41px" : "10px 25px")};
  line-height: 1.25;
  cursor: pointer;
  text-align: center;
  border-radius: ${({ theme }) =>
    theme.button.borderRadius || theme.borderRadius || "4px"};
  font-family: ${({ theme }) => theme.button.fontFamily || "inherit"};
  font-weight: ${({ theme }) => theme.button.fontWeight};
  text-transform: ${({ theme }) => theme.button.textTransform};
  width: ${({ fullWidth }) => fullWidth && "100%"};
`;

/*----------
 components
-----------*/
const StyledButton = styled.button<StyledButtonProps>`
  ${buttonStyles}
  ${themedStyles}
  ${({ small }) => small && smallStyles}
  ${({ position }) => position && positionStyles};
`;

const InternalLinkInnerContainer = styled.div<StyledButtonProps>`
  display: inline-block;
  ${buttonStyles}
  ${themedStyles}
`;

const LoadingWrapper = styled.div`
  position: relative;
`;

const Loading = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
`;

const Children = styled.div`
  opacity: 0;
  user-select: none;
`;

const TIME_BEFORE_SPINNER = 100;

/**
 * Explanation of internalLink usage here in `<Button>`
 *
 * - Pass in your internal link to style your clicky element
 *   just like a button but under the hood render as the
 *   `<InternalLink>` component
 *
 * Why should we not render internal links as buttons?
 *
 * - SEO and accessibility - no `<a href></a>` in the DOM tree
 * - Buttons are meant to do *more* than just change pages,
 *   such as submit forms or call functions or endpoints
 *
 * Why can't I just do `<a><button></button></a>` or
 * `<button><a></a></button>`?
 *
 * - It is against HTML5 spec and bad for accessibility
 *   to nest interactive elements
 */
export const Button = ({
  type = "primary",
  disabled = false,
  children = "",
  htmlType = "button",
  fullWidth = false,
  loading = false,
  position = "",
  small = false,
  internalLink = "",
  ...rest
}: ButtonProps): JSX.Element => {
  const [isLoadingDisplayed, setisLoadingDisplayed] = useState(!!loading);
  const theme = useContext(ThemeContext);
  const timeout = useRef<number | undefined>();

  useEffect(() => {
    if (!loading && isLoadingDisplayed) {
      setisLoadingDisplayed(false);
    } else if (loading && !isLoadingDisplayed) {
      timeout.current = window.setTimeout(() => {
        if (loading && !isLoadingDisplayed) setisLoadingDisplayed(true);
      }, TIME_BEFORE_SPINNER);
      return () => clearTimeout(timeout.current);
    }
    // Ignoring dep warning since I'm not sure why we use this useEffect
    // and this works as-is so not touching :)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const { color, textColor, hollow, solidOnHover, hollowOnHover } =
    getThemeValues(type, theme);
  const spinnerColor = getSpinnerColor(type, theme, disabled);

  return internalLink ? (
    <InternalLink forwardedRef={rest.forwardedRef} href={internalLink}>
      <InternalLinkInnerContainer
        data-testid="button-as-link"
        styleType={type}
        fullWidth={fullWidth}
        color={color}
        textColor={textColor}
        hollow={hollow}
        solidOnHover={solidOnHover}
        hollowOnHover={hollowOnHover}
      >
        {children}
      </InternalLinkInnerContainer>
    </InternalLink>
  ) : (
    <StyledButton
      type={htmlType}
      /* adds "disabled" to the button in the DOM
          when `disabled` or `isLoadingDisplayed` */
      disabled={disabled || isLoadingDisplayed}
      /* checking `disabled` only here, don't want
          disabled styles when `isLoadingDisplayed` */
      addDisabledStyles={disabled}
      styleType={type}
      fullWidth={fullWidth}
      position={position}
      small={small}
      color={color}
      textColor={textColor}
      hollow={hollow}
      solidOnHover={solidOnHover}
      hollowOnHover={hollowOnHover}
      {...rest}
    >
      {isLoadingDisplayed ? (
        <LoadingWrapper>
          <Loading>
            <Spinner color={spinnerColor} />
          </Loading>
          {/* children are in the loading wrapper
                to maintain the height/width of the
                button when showing the spinner */}
          <Children>{children}</Children>
        </LoadingWrapper>
      ) : (
        children
      )}
    </StyledButton>
  );
};
