import React from "react";

export const SearchIconSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11" cy="11" r="6.2" stroke="#9B9B9B" strokeWidth="1.6" />
    <path
      d="M16 16L20 20"
      stroke="#9B9B9B"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
  </svg>
);
