import { ErrorsInterface, SignupErrorKeys } from "@pepdirect/shared/types";
import { logUnknownErrorCode } from "./logAndCaptureInSentry";
import { GraphQLError } from "graphql";
import { isArray } from "@apollo/client/utilities";

export const isEmailVerificationRequiredError = (
  graphQLErrors: GraphQLError[]
) => {
  let isEmailVerificationRequired = false;
  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails = error.extensions?.details as
      | ErrorsInterface
      | ErrorsInterface[]; // receiving array when emailVerificationRequired

    isArray(errorDetails) &&
      errorDetails.forEach((errorDetail) => {
        const { key } = errorDetail;
        if (key === SignupErrorKeys.emailVerificationRequired) {
          isEmailVerificationRequired = true;
        }
      });
  });

  return isEmailVerificationRequired;
};

// only call this with GraphQLErrors and not NetworkErrors
export const getMappedErrorMsg = (
  code: string,
  errorCodeMap: ErrorsInterface,
  operationName: string
): string => {
  const mappedErrorMsg = errorCodeMap[code];
  if (!mappedErrorMsg) {
    logUnknownErrorCode(code, operationName);
  }
  return mappedErrorMsg || "Something went wrong. Please try again.";
};
