import React, { FC, useContext, useEffect, useState } from "react";
import { GraphQLError } from "graphql";
import { useRouter } from "next/router";
import Cookies from "universal-cookie";
import URI from "urijs";
import cx from "classnames";
import { BlockedItemsContext } from "context/blockedItems";
import { DiscountsContext } from "context/discounts";
import { CheckoutContext } from "context/checkout";
import { useCurrentAppId } from "hooks/useCurrentAppId";
import { Button } from "@pepdirect/ui/button";
import { Heading } from "@pepdirect/ui/heading";
import { Summary } from "components/OrderSummary/Summary";
import { ErrorModal } from "components/Modals/ErrorModal";
import { MinimumErrorModal } from "components/Modals/MinimumErrorModal";
import {
  PriceV2Fragment,
  SubmitOrderMutation,
  SubmitOrderMutationVariables,
  useCalculateOrderTaxesMutation,
  useSubmitOrderMutation,
} from "services/graphql/generated";
import {
  logContinueToPurchaseConfirmation,
  logOrderPurchaseError,
  logPurchaseComplete,
  logPurchaseEmailOptIn,
} from "services/analytics";
import {
  parseSubmitOrderGraphQLErrors,
  getPriceSummary,
  getCalculateOrderTaxesVariables,
  transformPriceFragmentToInput,
} from "helpers/checkout";
import { CartMinimumError } from "@pepdirect/shared/types";
import { PAYMENT_TYPES } from "constants/payment";
import commonSt from "../verificationAndConfirmationModal.module.scss";
import st from "./orderConfirmationModal.module.scss";
import { ApolloError } from "@apollo/client";
import { useApplePay } from "hooks/useApplePay";
import { createOrFetchOrder } from "services/checkoutSubmission";

const cookie = new Cookies();
const AUTO_APPLY_COOKIE_KEY = "autoApplyPromoCode";

interface OrderConfirmationModalProps {
  submitOrderVars: SubmitOrderMutationVariables;
  price: PriceV2Fragment;
  onClose: () => void;
}

export const OrderConfirmationModal: FC<OrderConfirmationModalProps> = ({
  submitOrderVars,
  price,
  onClose,
}) => {
  const { authorizePayment } = useApplePay();
  const { setBlockedItems } = useContext(BlockedItemsContext);
  const { discounts } = useContext(DiscountsContext);
  const { apiBaseURL, createdOrFetchedOrder, setCartErrors } =
    useContext(CheckoutContext);

  const [error, setError] = useState<string | null>(null);
  const [showMinimumError, setShowMinimumError] =
    useState<CartMinimumError>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [submitOrder] = useSubmitOrderMutation();
  const router = useRouter();

  const { appId } = useCurrentAppId();

  const {
    input: { paymentRequest, contactInfo },
  } = submitOrderVars;
  const showAllocation =
    paymentRequest.paymentMethod === PAYMENT_TYPES.ALLOCATED_CREDIT;
  const emailForOptIn = !!contactInfo.emailOptIn && contactInfo.email;

  useEffect(() => {
    logContinueToPurchaseConfirmation(
      appId,
      submitOrderVars,
      price,
      createdOrFetchedOrder?.submissionUid || ""
    );
  }, [appId, submitOrderVars, price, createdOrFetchedOrder]);

  const handleSubmitOrderSuccess = (submitOrderData: SubmitOrderMutation) => {
    const { submitOrder: order } = submitOrderData || {};

    if (!order) {
      // something went wrong
      setError("");
      setIsLoading(false);
      return;
    }

    cookie.set(AUTO_APPLY_COOKIE_KEY, "", {
      path: "/",
      domain: `.${URI(window.location.href).domain()}`,
    });

    const bazaarvoice =
      submitOrderData.submitOrder?.contactInfo.bazaarvoice || {};
    logPurchaseComplete(appId, apiBaseURL, bazaarvoice, order);
    setBlockedItems([]);

    if (emailForOptIn) logPurchaseEmailOptIn(emailForOptIn);

    const href = `/status/${order.id}/${order.appId}`;
    router.push(href).then(() => onClose());
  };

  const handleSubmitOrderError = (e: ApolloError) => {
    const { graphQLErrors, networkError } = e;

    if (graphQLErrors.length) {
      const { blockedItems, minimumError, errorMsg, cartErrors } =
        parseSubmitOrderGraphQLErrors(graphQLErrors as GraphQLError[]);

      if (blockedItems.length) {
        setBlockedItems(blockedItems);
        if (cartErrors.length) setCartErrors(cartErrors);
        onClose();
      } else if (minimumError) setShowMinimumError(minimumError);
      else setError(errorMsg);
    } else if (networkError) {
      logOrderPurchaseError(e, submitOrderVars.input.contactInfo);
      setError("");
    } else setError("");

    setIsLoading(false);
  };
  const [calculateOrderTaxes] = useCalculateOrderTaxesMutation();

  const getApplePayPaymentRequest = async () => {
    try {
      const paymentRequest = await authorizePayment(
        getPriceSummary(price.items, price.shipping || 0, price.taxItems)
      );

      if (paymentRequest === undefined) {
        // The user cancelled the payment request
        return;
      }

      // refetch the cart to get the latest cart items\
      const orderData = await createOrFetchOrder(
        createdOrFetchedOrder?.cart?.id
      );

      if (!orderData?.price) {
        setError("");
        return;
      }

      const calculateOrderTaxesVars = getCalculateOrderTaxesVariables(
        {
          ...submitOrderVars.input,
          price: transformPriceFragmentToInput(orderData?.price),
        },
        submitOrderVars?.orderId,
        createdOrFetchedOrder?.cart?.id
      );

      const { data } = await calculateOrderTaxes({
        variables: calculateOrderTaxesVars,
      });

      if (!data?.calculateOrderTaxes?.order) {
        setError("");
        return;
      }

      const newPrice = data?.calculateOrderTaxes?.order?.price;
      const newPriceSummary = getPriceSummary(
        newPrice?.items,
        newPrice.shipping || 0,
        newPrice.taxItems
      );
      const currentPriceSummary = getPriceSummary(
        price?.items,
        price.shipping || 0,
        price.taxItems
      );

      if (
        newPriceSummary.userTotalPrice !== currentPriceSummary.userTotalPrice
      ) {
        // This means that the price changed. We need to refresh the page
        setError("");
        router.reload();
        return;
      }

      // Generating the payment request object was a success. We now need to update the submitted variables
      return paymentRequest;
    } catch (e) {
      setError("");
      setIsLoading(false);
      return;
    }
  };

  const handleSubmitOrder = async () => {
    setIsLoading(true);
    try {
      // PCS-686 calculateOrderTaxes prod-only bug where discounts is being
      // sent in the request but not returned in the response. Adding back here
      // for submitOrder as a temp solution until a fix on BE is implemented
      submitOrderVars.input.price.discounts = discounts;

      if (
        submitOrderVars.input.paymentRequest.paymentMethod ===
        PAYMENT_TYPES.APPLE_PAY
      ) {
        const paymentRequest = await getApplePayPaymentRequest();
        if (!paymentRequest) return;

        submitOrderVars.input.paymentRequest = paymentRequest;
      }

      const { data: submitOrderData } = await submitOrder({
        variables: submitOrderVars,
      });

      if (submitOrderData) {
        handleSubmitOrderSuccess(submitOrderData);
      } else {
        setError("");
        setIsLoading(false);
      }
    } catch (e) {
      if (e instanceof ApolloError) {
        handleSubmitOrderError(e);
      } else {
        setError("");
        setIsLoading(false);
      }
    }
  };

  if (error !== null) {
    return (
      <ErrorModal
        onClose={onClose}
        error={error}
        buttonType="cancel"
        buttonText="Go back"
      />
    );
  } else if (showMinimumError) {
    return <MinimumErrorModal onClose={onClose} type={showMinimumError} />;
  }

  const buttonTitle =
    submitOrderVars.input.paymentRequest.paymentMethod ===
    PAYMENT_TYPES.APPLE_PAY
      ? "Complete Order with Apple Pay"
      : "Complete Order";
  return (
    <div className={cx(commonSt.body, commonSt.orderConfirmationBody)}>
      <Heading level="h2" size="xl">
        Order Confirmation
      </Heading>

      <p className={st.text}>We have calculated your order total.</p>

      <div className={st.summary}>
        <Summary
          price={getPriceSummary(
            price.items,
            price.shipping || 0,
            price.taxItems
          )}
          showAllocation={showAllocation}
        />
      </div>

      <div className={st.buttonsContainer}>
        <Button type="cancel" onClick={onClose}>
          Cancel
        </Button>
        <Button
          htmlType="submit"
          type="secondary"
          loading={isLoading}
          onClick={handleSubmitOrder}
        >
          {buttonTitle}
        </Button>
      </div>
    </div>
  );
};
