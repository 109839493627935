import { PotentialBillingAddressTypes } from "services/payment";
import { BraintreeTokenizeFields } from "types/payment";
import { paymentMethods, paymentImgPaths } from "constants/payment";
import { ActiveAccountQuery, PaymentType } from "services/graphql/generated";

export const getPaymentImageUrl = (brandImgUrl: string): string => {
  const brand = brandImgUrl.toLowerCase();
  let imgUrl = paymentImgPaths.creditCardImg;
  if (brand.indexOf("american") !== -1) {
    imgUrl = paymentImgPaths.americanExpress;
  } else if (brand.indexOf("amex") !== -1) {
    imgUrl = paymentImgPaths.americanExpress;
  } else if (brand.indexOf("visa") !== -1) {
    imgUrl = paymentImgPaths.visa;
  } else if (brand.indexOf("mastercard") !== -1) {
    imgUrl = paymentImgPaths.masterCardImg;
  } else if (brand.indexOf("discover") !== -1) {
    imgUrl = paymentImgPaths.discover;
  } else if (brand.indexOf("paypal") !== -1) {
    imgUrl = paymentImgPaths.paypal;
  } else if (brand.indexOf("applepay") !== -1) {
    imgUrl = paymentImgPaths.applePay;
  }

  return imgUrl;
};

export const getPaymentMethodImg = (paymentMethod: string): string => {
  switch (paymentMethod) {
    case paymentMethods.CREDIT_CARD:
      return paymentImgPaths.creditCardImg;
    // case paymentMethods.PAYPAL:
    //   return paypal;
    case paymentMethods.VISA_CHECKOUT:
      return paymentImgPaths.visaCheckout;
    case paymentMethods.MASTERPASS:
      return paymentImgPaths.masterpass;
    case paymentMethods.APPLE_PAY:
      return paymentImgPaths.applePay;
    case paymentMethods.ALLOCATION:
    case paymentMethods.INVOICE:
      // TODO: this will just be a tenant logo once Ford finishes his ticket https://pepsico-ecomm.atlassian.net/browse/PCS-1154
      return paymentImgPaths.allocation;
    default:
      return paymentImgPaths.creditCardImg;
  }
};

export const getActiveAllocation = (
  activeAccount: ActiveAccountQuery["activeAccount"]
) => {
  return (
    activeAccount?.credits.find((credit) => {
      return (
        credit.type === "allocation" &&
        credit.status === "active" &&
        !credit.frozen
      );
    }) || null
  );
};

export const getCountry = (bill: PotentialBillingAddressTypes): string => {
  if ("countryCode" in bill) {
    return bill.countryCode;
  }
  return bill.country?.value || "";
};

export const getTokenizeFields = (
  bill: PotentialBillingAddressTypes,
  cardholderName: string
): BraintreeTokenizeFields => {
  const tokenizeBillingAddress = {
    postalCode: bill.zip || "",
    firstName: bill.firstName || "",
    lastName: bill.lastName || "",
    streetAddress: bill.line1 || "",
    extendedAddress: bill.line2 || "",
    locality: bill.city || "",
    region:
      typeof bill.state === "string" ? bill.state : bill.state?.value || "",
    countryName: getCountry(bill),
  };

  return {
    cardholderName,
    billingAddress: tokenizeBillingAddress,
  };
};

export const getAllocationStatus = (paymentTypes?: PaymentType[]): boolean => {
  return paymentTypes?.find((payment) => payment.name === "Allocation")
    ? true
    : false;
};

export const isPayPalEnabled = (paymentTypes?: PaymentType[]): boolean => {
  return paymentTypes?.some((payment) => payment.name === "PayPal")
    ? true
    : false;
};

export const isApplePayEnabled = (paymentTypes?: PaymentType[]): boolean => {
  try {
    return (
      Boolean(window.ApplePaySession) &&
      ApplePaySession.supportsVersion(3) &&
      ApplePaySession.canMakePayments() &&
      Boolean(paymentTypes?.some((payment) => payment.name === "ApplePay"))
    );
  } catch (error) {
    return false;
  }
};
