import React, { FC, useState } from "react";
import { useRouter } from "next/router";
import {
  AddressVerificationModal,
  VerificationAddrTypes,
} from "../VerificationAndConfirmationModal/AddressVerificationModal";
import { ErrorModal } from "../ErrorModal";
import {
  useCreateUserAddressMutation,
  useUpdateUserAddressMutation,
} from "services/graphql/generated";
import { getUserAddressInputFromVerification } from "helpers/address";
import { PortalAddressVerificationProps } from "types/modal";

interface PortalAddrVeriModalProps extends PortalAddressVerificationProps {
  onClose: () => void;
}

export const PortalAddressVerificationModal: FC<PortalAddrVeriModalProps> = ({
  formValues,
  verifiedAddress,
  unverifiedAddress,
  addressId,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [createUserAddress] = useCreateUserAddressMutation();
  const [updateUserAddress] = useUpdateUserAddressMutation();
  const router = useRouter();

  if (!(verifiedAddress || unverifiedAddress)) {
    // something went wrong
    return <ErrorModal onClose={onClose} />;
  }

  const saveAddress = async (addr: VerificationAddrTypes) => {
    setIsLoading(true);

    const addrForSubmit = getUserAddressInputFromVerification({
      formValues,
      confirmedAddress: addr,
      verifiedAddress,
    });

    // workaround to use v3 form in v2 page.
    if ("phone" in formValues) {
      addrForSubmit.phoneNumber = (formValues.phone || "") as string;
    }

    try {
      addressId
        ? await updateUserAddress({
            variables: {
              id: addressId,
              input: addrForSubmit,
            },
          })
        : await createUserAddress({ variables: { input: addrForSubmit } });

      onClose();
      router.back();
    } catch (e) {
      setError((e instanceof Error && e.message) || "");
    }
  };

  if (error !== null) {
    return <ErrorModal error={error} onClose={onClose} />;
  }

  return (
    <AddressVerificationModal
      verifiedAddress={verifiedAddress}
      unverifiedAddress={unverifiedAddress}
      userConfirm={saveAddress}
      onClose={onClose}
      loading={isLoading}
    />
  );
};
