import React from "react";
import { HexColor } from "../../types/styles";
import { colors } from "../../styles/variables";

export const LockIconSvg = ({
  color = colors.gray900,
  width = "12",
  height = "16",
}: {
  color?: HexColor;
  height?: string;
  width?: string;
}) => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.54218 15.8749C1.17219 15.8749 0.857392 15.7441 0.597781 15.4824C0.338156 15.2207 0.208344 14.9049 0.208344 14.5352V7.04804C0.208344 6.67828 0.339197 6.36255 0.600906 6.10085C0.8626 5.83915 1.17833 5.70829 1.54809 5.70829H2.20834V3.70829C2.20834 2.71382 2.55741 1.86799 3.25553 1.17079C3.95364 0.473597 4.80059 0.125 5.79636 0.125C6.79214 0.125 7.63752 0.473597 8.33251 1.17079C9.02748 1.86799 9.37497 2.71382 9.37497 3.70829V5.70829H10.0352C10.405 5.70829 10.7207 5.83915 10.9824 6.10085C11.2441 6.36255 11.375 6.67828 11.375 7.04804V14.5352C11.375 14.9049 11.244 15.2207 10.9822 15.4824C10.7203 15.7441 10.4044 15.8749 10.0344 15.8749H1.54218ZM1.54809 14.7916H10.0352C10.11 14.7916 10.1714 14.7676 10.2195 14.7195C10.2676 14.6714 10.2917 14.61 10.2917 14.5352V7.04804C10.2917 6.97325 10.2676 6.91181 10.2195 6.86373C10.1714 6.81565 10.11 6.7916 10.0352 6.7916H1.54809C1.4733 6.7916 1.41186 6.81565 1.36378 6.86373C1.3157 6.91181 1.29166 6.97325 1.29166 7.04804V14.5352C1.29166 14.61 1.3157 14.6714 1.36378 14.7195C1.41186 14.7676 1.4733 14.7916 1.54809 14.7916ZM5.79607 12.0833C6.15638 12.0833 6.46099 11.9573 6.70991 11.7054C6.95884 11.4536 7.0833 11.1475 7.0833 10.7872C7.0833 10.4269 6.95736 10.1223 6.70549 9.87335C6.45361 9.62442 6.14753 9.49996 5.78724 9.49996C5.42693 9.49996 5.12232 9.6259 4.87341 9.87777C4.62448 10.1296 4.50001 10.4357 4.50001 10.796C4.50001 11.1563 4.62595 11.4609 4.87782 11.7099C5.1297 11.9588 5.43578 12.0833 5.79607 12.0833ZM3.29166 5.70829H8.29166V3.70829C8.29166 3.01385 8.0486 2.42357 7.56249 1.93746C7.07638 1.45135 6.4861 1.20829 5.79166 1.20829C5.09721 1.20829 4.50693 1.45135 4.02082 1.93746C3.53471 2.42357 3.29166 3.01385 3.29166 3.70829V5.70829Z"
      fill={color}
    />
  </svg>
);
