import {
  Client,
  hostedFields,
  HostedFields,
  HostedFieldsTokenizePayload,
} from "braintree-web";
import { AddressFragment } from "./graphql/generated";
import { AddressDetailsFormValues } from "components/Forms/AddressDetailsForm";
import { getTokenizeFields } from "helpers/payment";

export const createHostedFields = async (
  client: Client
): Promise<HostedFields | null> => {
  const createdHostedFields = hostedFields.create({
    client,
    styles: {
      input: {
        "font-size": "16px",
        "font-family": "Arial, sans-serif",
      },
    },
    fields: {
      number: {
        selector: "#cardNumber",
      },
      cvv: {
        selector: "#cvv",
      },
      expirationDate: {
        selector: "#expirationDate",
      },
    },
  });

  return createdHostedFields;
};

export type PotentialBillingAddressTypes =
  | AddressDetailsFormValues
  | AddressFragment;

export const getNonce = async (
  hostedFields: HostedFields,
  bill: PotentialBillingAddressTypes | undefined,
  cardholderName: string
): Promise<HostedFieldsTokenizePayload> => {
  if (!bill) {
    return Promise.reject("Billing address required");
  }

  try {
    const payload = await hostedFields.tokenize(
      getTokenizeFields(bill, cardholderName)
    );
    return Promise.resolve(payload);
  } catch (tokenizeErr) {
    return Promise.reject(tokenizeErr);
  }
};
