import React, { FC, useContext } from "react";
import { ThemeContext } from "styled-components";
import { HexColor } from "../../types";

interface GiftSvgProps extends React.SVGProps<SVGSVGElement> {
  color?: HexColor;
}

export const GiftIconSvg: FC<GiftSvgProps> = ({
  color,
  className,
  ref,
  ...props
}) => {
  const theme = useContext(ThemeContext);
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3595_9554)">
        <path
          d="M5.41406 1.05469L6 2.01562L6.5625 1.05469C6.9375 0.398438 7.61719 0 8.36719 0H8.4375C9.5625 0 10.5 0.9375 10.5 2.0625C10.5 2.41406 10.4062 2.71875 10.2656 3H10.875C11.4844 3 12 3.51562 12 4.125V5.625C12 6.11719 11.6719 6.53906 11.25 6.70312V6.75V10.5C11.25 11.3438 10.5703 12 9.75 12H2.25C1.40625 12 0.75 11.3438 0.75 10.5V6.75V6.70312C0.304688 6.53906 0 6.11719 0 5.625V4.125C0 3.51562 0.492188 3 1.125 3H1.71094C1.57031 2.71875 1.5 2.41406 1.5 2.0625C1.5 0.9375 2.41406 0 3.5625 0H3.60938C4.35938 0 5.03906 0.398438 5.41406 1.05469ZM10.875 4.125H8.4375H6.75V5.625H10.125H10.875V4.125ZM5.25 4.125H3.5625H1.125V5.625H1.875H5.25V4.125ZM5.25 6.75H1.875V10.5C1.875 10.7109 2.03906 10.875 2.25 10.875H5.25V6.75ZM6.75 10.875H9.75C9.9375 10.875 10.125 10.7109 10.125 10.5V6.75H6.75V10.875ZM8.4375 3C8.95312 3 9.375 2.60156 9.375 2.0625C9.375 1.54688 8.95312 1.125 8.4375 1.125H8.36719C8.01562 1.125 7.71094 1.3125 7.52344 1.61719L6.70312 3H6.75H8.4375ZM5.25 3H5.27344L4.45312 1.61719C4.28906 1.3125 3.96094 1.125 3.60938 1.125H3.5625C3.02344 1.125 2.625 1.54688 2.625 2.0625C2.625 2.60156 3.02344 3 3.5625 3H5.25Z"
          fill={color || theme.color.primary}
        />
      </g>
      <defs>
        <clipPath id="clip0_3595_9554">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
