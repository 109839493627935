import React, { FC } from "react";
import styled from "styled-components";
import { Heading } from "../heading/Heading";

const Header = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color?.primary};
`;

const NoConnectionImgContainer = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeadingContainer = styled.div`
  text-align: center;
  padding: 5px;
`;

const ContentText = styled.div`
  padding: 20px;
  text-align: center;
`;

export const NoNetworkConnectionModal: FC<{
  logoSrc: string;
}> = ({ logoSrc }) => {
  return (
    <div>
      <Header />
      <NoConnectionImgContainer />

      <HeadingContainer>
        <Heading level="h2" size="xl">
          Can&apos;t Connect
        </Heading>
      </HeadingContainer>
      <ContentText>
        You&apos;re having issues connecting to the internet. Please try again
        later when you have a better connection.
      </ContentText>
    </div>
  );
};
