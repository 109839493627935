import * as Sentry from "@sentry/nextjs";
import { GraphQLErrors, NetworkError } from "@apollo/client/errors";
import { ExecutionResult } from "graphql";
import { ChangesetError } from "../types";

export const captureInSentry = (e: unknown, operationName?: string) => {
  Sentry.captureException(e, { tags: { operationName } });
  return;
};

export const logServerNetworkError = (
  e: NetworkError,
  operationName?: string
) => {
  e &&
    console.error(
      JSON.stringify({ message: e.message, stack: e.stack, name: e.name })
    );
  captureInSentry(e, operationName);
};

export const logClientSideError = (e: unknown, operationName?: string) => {
  console.error(e);
  captureInSentry(e, operationName);
};

export const logUnknownErrorCode = (code: string, operationName: string) => {
  const errorMsg = `Unknown error "${code}" from ${operationName} GQL
    operation. All new errors must be mapped to the frontend. Please
    report to backend team for triage.`;

  console.warn(errorMsg);
  Sentry.captureMessage(errorMsg);
};

export const logChangesetError = (
  operationName: string,
  changesetErrorDetails: ChangesetError["details"]
) => {
  const errorMsg = `Changeset error from ${operationName} GQL operation.
${JSON.stringify(changesetErrorDetails, null, 2)}
  `;
  Sentry.captureMessage(errorMsg);
};

export const logWithMessageInSentry = (msg: string) => {
  console.error(msg);
  Sentry.captureMessage(msg);
};

interface LogNonArrayGraphQLErrors {
  graphQLErrors: GraphQLErrors;
  operationName: string;
  response: ExecutionResult | undefined;
}

// graphQLErrors should be an array, log it when we don't get it as
// one for further investigation.
export const logNonArrayGraphQLErrors = ({
  graphQLErrors,
  operationName,
  response,
}: LogNonArrayGraphQLErrors) => {
  const value =
    typeof graphQLErrors === "object"
      ? JSON.stringify(graphQLErrors, null, 2)
      : graphQLErrors;

  Sentry.captureMessage(
    `graphQLErrors should be an array on ${operationName}
      and is being returned as type: ${typeof graphQLErrors},
      value: ${value}, and response: ${JSON.stringify(response, null, 2)}
    `,
    "warning"
  );
};
