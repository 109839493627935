import React from "react";
import { ModalType, ModalProps, ModalContextInterface } from "types/modal";

export const ModalContext = React.createContext<ModalContextInterface>({
  isOpen: false,
  type: "",
  props: null,
  open: (_type: ModalType, _props?: ModalProps) => {},
  close: () => {},
});
