import { useState, useEffect, useCallback, useMemo } from "react";
import { LocalizationQuery } from "v3/services/graphql/generated";
import { formatToLocaleCurrency } from "@pepdirect/v3/helpers/currency";
import { genericErrorMsgEnglish } from "@pepdirect/v3/constants";

export interface LocalizationContextInterface {
  formatPriceByLocale: (
    price?: number | null,
    style?: "currency" | "percent"
  ) => string;
  genericErrorMsg: string;
  localization: LocalizationQuery["localization"] | null;
}

export function useLocalizationProvider(
  localization: LocalizationQuery["localization"] | null | undefined,
  defaultCurrencyCode: string
): {
  localizationContextValue: LocalizationContextInterface;
} {
  const [localizationState, setLocalizationState] = useState<
    LocalizationQuery["localization"] | null
  >(null);

  const genericErrorMsg = useMemo(
    () =>
      localizationState?.checkout?.errors?.genericErrorMsg ||
      genericErrorMsgEnglish,
    [localizationState]
  );

  useEffect(() => {
    if (localization) setLocalizationState(localization);
  }, [localization]);

  const formatPriceByLocale = useCallback(
    (price?: number | null, style?: "currency" | "percent"): string => {
      if (!price && price !== 0) return "";

      return formatToLocaleCurrency({
        defaultCurrencyCode: defaultCurrencyCode || "USD",
        locale:
          localization?.currentLocale || localization?.defaultLocale || "en",
        style,
        valueInCentsDenomination: price,
      });
    },
    [
      defaultCurrencyCode,
      localization?.currentLocale,
      localization?.defaultLocale,
    ]
  );

  const localizationContextValue: LocalizationContextInterface = {
    formatPriceByLocale,
    genericErrorMsg,
    localization: localizationState,
  };

  return { localizationContextValue };
}
