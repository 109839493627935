/**
 * NEXT_PUBLIC_PROJECT_ENV is used to set current environment because
 * Next.js doesn't support it
 * https://github.com/vercel/next.js/blob/master/errors/non-standard-node-env.md
 */
import URI from "urijs";
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

export enum ENV_ENUM {
  dev = "dev",
  "dev-https" = "dev-https",
  production = "production",
  staging = "staging",
  uat = "uat",
}

export const environment: ENV_ENUM = publicRuntimeConfig.environment || "dev";
export const sentryEnvironment =
  publicRuntimeConfig.sentryEnvironment as string;
export const isProductionReady = ["staging", "production", "uat"].includes(
  environment
);
export const isLocalEnv = !isProductionReady && !process.env.CI;
// FIXME: test env remove this please
export const isTestEnv = process.env.NODE_ENV == "test";

export const decovoShopAppId = "b0414f96-89ee-4a2d-94ea-9b062d2a7212";
export const sentryDSN = publicRuntimeConfig.sentryDSN as string;

export const apiSubdomain = publicRuntimeConfig.apiSubdomain as
  | string
  | undefined;

export const endpoints = {
  cdn: publicRuntimeConfig.cdn as string,
  productsImgCdn: publicRuntimeConfig.productsImgCdn as string,
  web: publicRuntimeConfig.web as string,
  api: publicRuntimeConfig.api as string,
  corporateFrontend: publicRuntimeConfig.corporateFrontend as string,
  internalApi: publicRuntimeConfig.internalApi as string,
};

export const cookies = {
  localStorageUserKey: "caps_user",
  cartCookieName: "decovo-shoppingcart",
  secureCookie: isProductionReady,
  defaultOptions: {
    path: "/",
    domain: URI(endpoints.web || window.location.host).domain(),
    secure: isProductionReady,
    maxAge: 7 * 24 * 60 * 60,
  },
};

export const cart = {
  maxCartQty: 15,
};

export const paypalClientId = publicRuntimeConfig.paypalClientId;
