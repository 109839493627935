import React, { FC, useRef, useEffect } from "react";
import {
  Field,
  FieldInputProps as FinalFormInputProps,
} from "react-final-form";
import styled from "styled-components";
import { FieldProps } from "./types";
import { colors } from "../../styles/variables";

const StyledField = styled.div`
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  height: 42px;
  align-items: center;
  margin: 30px 0 25px;
`;

const InputContainer = styled.div`
  position: absolute;
  width: 100%;
`;

const Label = styled.span<{ moved: boolean }>`
  position: absolute;
  top: 12px;
  transition: transform 0.2s, color 0.2s;
  transform-origin: 0 0;
  font-size: 16px;
  line-height: 1;
  color: ${colors.mediumGray};
  ${({ moved }) =>
    moved &&
    `
    transform: scale(0.8) translate(0, -25px);
    transition: transform 0.2s, color 0.2s;
  `};
`;

const StyledInput = styled.input<{ valid?: boolean; invalid?: boolean }>`
  width: 100%;
  height: 42px;
  background-color: transparent;
  font: inherit;
  font-size: 16px;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;

  &:focus {
    border-bottom-color: ${({ valid, invalid, theme }) =>
      !valid && !invalid && theme.color.primary};
    outline: none;
  }
`;

const Error = styled.span`
  font-size: 12px;
  line-height: 1.3;
  color: ${({ theme }) => theme.color.error};
  margin-top: 5px;
  display: block;
`;

const LabelContainer = styled.label`
  display: flex;
  flex-direction: column;
`;

interface FieldInputProps extends FieldProps {
  input?: FinalFormInputProps<string>;
  defaultValue?: string;
  autocompleteValue?: string;
  maxLength?: number;
  browserAutofillOffValue?: string;
  trackInteraction?: (fieldName: string) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OnChangeType = (event: any) => void;

export const FieldInput: FC<FieldInputProps> = ({
  name,
  defaultValue,
  autocompleteValue,
  browserAutofillOffValue,
  maxLength,
  trackInteraction,
  ...props
}) => {
  const inputOnChange = useRef<OnChangeType | null>(null);

  // handle an incoming change from the AutocompleteInput
  useEffect(() => {
    if (inputOnChange.current && autocompleteValue !== undefined) {
      inputOnChange.current(autocompleteValue);
    }
  }, [autocompleteValue, inputOnChange]);

  return (
    <Field name={name} {...props} defaultValue={defaultValue}>
      {({ input, meta }) => {
        if (!inputOnChange.current) {
          inputOnChange.current = input.onChange;
        }

        return (
          <StyledField>
            <InputContainer>
              <LabelContainer>
                <StyledInput
                  id={name}
                  className="input-field"
                  type="text"
                  {...input}
                  {...props}
                  valid={meta.touched && !meta.error}
                  invalid={meta.touched && meta.error}
                  onChange={input.onChange}
                  onFocus={() => {
                    trackInteraction?.(name);
                    input?.onFocus?.();
                  }}
                  autoComplete={browserAutofillOffValue}
                  maxLength={maxLength}
                />
                {props.label && (
                  <Label
                    className="input-field-label"
                    moved={meta.active || (!meta.active && !!input.value)}
                  >
                    {props.label}
                  </Label>
                )}
              </LabelContainer>
              {meta.touched && meta.error && <Error>{meta.error}</Error>}
            </InputContainer>
          </StyledField>
        );
      }}
    </Field>
  );
};
