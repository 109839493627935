import React from "react";

export const CirclePlayIconSvg = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="37"
    viewBox="0 0 79 79"
  >
    <path
      d="M0 0 C1.17526245 0.00749268 1.17526245 0.00749268 2.37426758 0.01513672 C11.63365358 0.29062886 19.94089439 3.31877759 26.7265625 9.7734375 C33.88925367 17.89115416 37.84164356 26.39728789 37.75 37.25 C37.74500488 38.0335083 37.74000977 38.8170166 37.73486328 39.62426758 C37.45937114 48.88365358 34.43122241 57.19089439 27.9765625 63.9765625 C19.85884584 71.13925367 11.35271211 75.09164356 0.5 75 C-0.2835083 74.99500488 -1.0670166 74.99000977 -1.87426758 74.98486328 C-11.13365358 74.70937114 -19.44089439 71.68122241 -26.2265625 65.2265625 C-33.38925367 57.10884584 -37.34164356 48.60271211 -37.25 37.75 C-37.24500488 36.9664917 -37.24000977 36.1829834 -37.23486328 35.37573242 C-36.95937114 26.11634642 -33.93122241 17.80910561 -27.4765625 11.0234375 C-19.35884584 3.86074633 -10.85271211 -0.09164356 0 0 Z M-21.75 14.5 C-28.35347212 21.60920849 -31.96386193 29.73363866 -31.75 39.5 C-30.375798 48.95931282 -26.36689759 56.9163957 -18.8125 62.875 C-11.42097626 67.91912112 -3.92507747 70.3970846 5.01953125 69.09765625 C14.37494665 66.88637625 21.5867574 62.48003556 27.03515625 54.49609375 C31.82507665 46.62583869 33.30725848 38.90570896 31.1875 29.9375 C27.84092124 19.26462721 21.56688991 12.89240384 11.875 7.6875 C-0.37185467 3.67559933 -11.94265132 6.0117684 -21.75 14.5 Z "
      fill="#6B6B6B"
      transform="translate(39.75,1.5)"
    />
    <path
      d="M0 0 C5.32613407 0.4517234 9.04373176 3.36365473 13.3125 6.3125 C14.05306641 6.79912109 14.79363281 7.28574219 15.55664062 7.78710938 C16.25595703 8.26599609 16.95527344 8.74488281 17.67578125 9.23828125 C18.31427002 9.67180908 18.95275879 10.10533691 19.6105957 10.55200195 C21 12 21 12 21 16 C19.50292969 17.55566406 19.50292969 17.55566406 17.421875 19.015625 C16.67292969 19.54414062 15.92398438 20.07265625 15.15234375 20.6171875 C14.35957031 21.15601562 13.56679688 21.69484375 12.75 22.25 C11.97269531 22.80429687 11.19539063 23.35859375 10.39453125 23.9296875 C4.52967211 28 4.52967211 28 0 28 C-1.13466279 22.56082283 -1.14889391 17.27745741 -1.125 11.75 C-1.12886719 10.83992188 -1.13273437 9.92984375 -1.13671875 8.9921875 C-1.13542969 8.12335937 -1.13414063 7.25453125 -1.1328125 6.359375 C-1.13168457 5.57014648 -1.13055664 4.78091797 -1.12939453 3.96777344 C-1 2 -1 2 0 0 Z "
      fill="#646464"
      transform="translate(32,25)"
    />
    <path
      d="M0 0 C0.66 0.33 1.32 0.66 2 1 C-1.96 4.96 -5.92 8.92 -10 13 C-10 8.83248505 -7.76127698 6.85829482 -5 4 C-3.36927384 2.62294236 -1.7133562 1.27277889 0 0 Z "
      fill="#949494"
      transform="translate(19,7)"
    />
  </svg>
);
