import React from "react";
import styled, { keyframes, css } from "styled-components";

const dotAnimation = keyframes`
  0%,
  80%,
  100% {
    transform: scale(0) translateZ(0);
  }
  40% {
    transform: scale(1) translateZ(0);
  }
`;

const dotStyles = css<SpinnerProps>`
  width: ${({ dotSize }) => dotSize || "10px"};
  height: ${({ dotSize }) => dotSize || "10px"};
  background: ${({ color, theme }) => color || theme.color.primary};
  border-radius: 50%;
  display: inline-block;
  animation: ${dotAnimation} 1.4s infinite ease-in-out both;
`;

const StyledDot1 = styled.div<SpinnerProps>`
  ${dotStyles};
  animation-delay: -0.32s;
`;

const StyledDot2 = styled.div<SpinnerProps>`
  ${dotStyles};
  animation-delay: -0.16s;
`;

const StyledDot3 = styled.div<SpinnerProps>`
  ${dotStyles};
`;

const StyledSpinner = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  min-width: 50px;
  transform: translate(-50%, -50%);
`;

const SpinnerContainer = styled.div`
  position: relative;
  width: 100%;
`;

interface SpinnerProps {
  color?: string;
  dotSize?: string; // for height and width css values
  className?: string;
}

export const Spinner = ({
  color,
  dotSize,
  className,
}: SpinnerProps): JSX.Element => {
  return (
    <SpinnerContainer data-testid="spinner" className={className || ""}>
      <StyledSpinner>
        <StyledDot1 color={color} dotSize={dotSize}></StyledDot1>
        <StyledDot2 color={color} dotSize={dotSize}></StyledDot2>
        <StyledDot3 color={color} dotSize={dotSize}></StyledDot3>
      </StyledSpinner>
    </SpinnerContainer>
  );
};
