import React, { FC, ReactNode } from "react";
import reactStringReplace from "react-string-replace";
import { CSSProperties } from "styled-components";
import { StyledTemplate } from "./index.styled";

interface ComponentMap {
  [key: string]: ReactNode;
}

interface TemplateAsComponentProps {
  template: string;
  map: ComponentMap;
  className?: string;
  display?: CSSProperties["display"];
}

/**
 * TemplateAsComponent is used to interpolate React/HTML nodes integrated into a
 * cohesive sentence (text formatting like bold or italic; link components).
 * Ex. usage: terms and conditions
 * For simple string interpolation use parseTemplateAsString
 */
export const TemplateAsComponent: FC<TemplateAsComponentProps> = ({
  template,
  map,
  className,
  display,
}) => {
  const parsedTemplate = reactStringReplace(
    template,
    /(\$\{.+?})/g,
    (match) => {
      const key = match.substring(2, match.length - 1).trim();
      return map[key];
    }
  );

  return (
    <StyledTemplate $display={display} className={className}>
      {parsedTemplate.map((val, i) => (
        <React.Fragment key={i}>{val}</React.Fragment>
      ))}
    </StyledTemplate>
  );
};
