export const formFields = {
  email: {
    autoComplete: "email",
    label: "Email*",
    name: "email",
  },
  firstName: {
    autoComplete: "given-name",
    label: "First Name*",
    name: "firstName",
  },
  lastName: {
    autoComplete: "family-name",
    label: "Last Name*",
    name: "lastName",
  },
  month: {
    autoComplete: "bday-month",
    label: "",
    name: "month",
  },
  day: {
    autoComplete: "bday-day",
    label: "",
    name: "day",
  },
  year: {
    autoComplete: "bday-year",
    label: "",
    name: "year",
  },
  password: {
    autoComplete: "new-password",
    label: "Password*",
    name: "password",
  },
};
