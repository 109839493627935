import React, { FC, useState, useEffect, useContext, useMemo } from "react";
import styled from "styled-components";
import cx from "classnames";
import { DiscountsContext } from "context/discounts";
import { CreditContext } from "context/credit";
import { colors } from "@pepdirect/ui/variables";
import { Divider } from "@pepdirect/ui/divider";
import { PromoCode } from "../PromoCode";
import { RegulatoryFees } from "../RegulatoryFees";
import { getDiscountAmount, getTotalAmount } from "helpers/price";
import { PriceSummary } from "types/price";
import { formatDollarsFromCents } from "helpers/currency";
import { CreateOrderMutation } from "services/graphql/generated";

import st from "./summary.module.scss";
import { LocalizationContext } from "v3/context/localization";

const creditLabel = "Allocation";
const remainingCreditLabel = "Remaining allocation amount";

const StyledFree = styled.span`
  color: ${({ theme }) => theme.priceAccentColor || colors.green};
  font-weight: bold;
`;

interface SummaryProps {
  price: PriceSummary;
  taxLabel?: string;
  feeLabel?: string;
  showPromoInput?: boolean;
  disablePromoRemoval?: boolean;
  showAllocation?: boolean;
  order?: NonNullable<CreateOrderMutation["createOrder"]>;
}

export const Summary: FC<SummaryProps> = ({
  price,
  taxLabel,
  feeLabel,
  showPromoInput = false,
  disablePromoRemoval = false,
  showAllocation = true,
  order,
}) => {
  const { discounts, hasDiscounts } = useContext(DiscountsContext);
  const { activeAllocation, hasInsufficientCredit } = useContext(CreditContext);
  const { localization } = useContext(LocalizationContext);

  const {
    subtotalLabel = "Subtotal",
    shippingLabel = "Shipping & Handling",
    estimatedTaxLabel = "Estimated Sales Tax",
    estimatedFeeLabel = "Estimated Regulatory Fees",
    totalLabel = "Total",
    freeShipLabel = "Free",
  } = localization?.portal?.orderSummary || {};

  const [remainingCreditAmount, setRemainingCreditAmount] = useState("0");

  const discountAmount = useMemo(() => {
    return getDiscountAmount(price, discounts);
  }, [discounts, price]);

  const {
    userShippingPrice = 0,
    userSubtotalPrice = 0,
    userTaxPrice = 0,
    userRegulatoryPrices,
  } = price || {};

  useEffect(() => {
    setRemainingCreditAmount(
      formatDollarsFromCents(
        (activeAllocation?.availableAmount || 0) -
          (userSubtotalPrice || 0) -
          (userShippingPrice || 0) -
          (discountAmount || 0)
      )
    );
  }, [activeAllocation, userSubtotalPrice, userShippingPrice, discountAmount]);

  const shippingPrice =
    userShippingPrice && userShippingPrice > 0 ? (
      formatDollarsFromCents(userShippingPrice)
    ) : (
      <StyledFree>{freeShipLabel}</StyledFree>
    );
  const subtotalPrice = formatDollarsFromCents(userSubtotalPrice);
  const taxPrice = formatDollarsFromCents(userTaxPrice);
  const totalPrice = formatDollarsFromCents(
    getTotalAmount(price, discounts) || 0
  );

  return (
    <div className={st.wrapper}>
      <div className={cx(st.priceItem, st.lineItem)}>
        <h3>{subtotalLabel}</h3>
        <div data-testid="subtotal">{subtotalPrice}</div>
      </div>
      <div className={cx(st.priceItem, st.lineItem)}>
        <h3>{shippingLabel}</h3>
        <div>{shippingPrice}</div>
      </div>
      {!!userTaxPrice && (
        <div className={cx(st.priceItem, st.lineItem)}>
          <h3>{taxLabel || estimatedTaxLabel}</h3>
          <div>{taxPrice}</div>
        </div>
      )}
      {!!userRegulatoryPrices?.total && (
        <div className={st.lineItem}>
          <RegulatoryFees
            fees={userRegulatoryPrices}
            label={feeLabel || estimatedFeeLabel}
          />
        </div>
      )}
      {activeAllocation && !hasInsufficientCredit && showAllocation && (
        <div
          className={cx(st.priceItem, st.lineItem)}
          data-testid="credit-used"
        >
          <div>{creditLabel}</div>
          <div>-{subtotalPrice}</div>
        </div>
      )}
      {(hasDiscounts || showPromoInput) && (
        <div className={st.lineItem}>
          <PromoCode
            price={price}
            disableRemoval={disablePromoRemoval}
            order={order}
          />
        </div>
      )}
      <Divider className={st.divider} />
      <div className={cx(st.priceItem, st.total)}>
        <h3>{totalLabel}</h3>
        <div>{totalPrice}</div>
      </div>
      {activeAllocation && !hasInsufficientCredit && showAllocation && (
        <div
          className={cx(st.priceItem, st.remaining)}
          data-testid="credit-remaining"
        >
          <div>{remainingCreditLabel}</div>
          <div>{remainingCreditAmount}</div>
        </div>
      )}
    </div>
  );
};
