import { useState } from "react";
import { InvoicedAddress } from "services/graphql/generated";

export interface InvoicingContextInterface {
  invoicingEnabled: boolean;
  setInvoicingEnabled: (invoincingEnabled: boolean) => void;
  addresses: InvoicedAddress[];
  setAddresses: (addresses: InvoicedAddress[]) => void;
  canPlaceOrders: boolean;
  setCanPlaceOrders: (canPlaceOrders: boolean) => void;
}

export function useInvoicingProvider(): {
  invoicingContextValue: InvoicingContextInterface;
} {
  const [invoicingEnabled, setInvoicingEnabled] = useState<boolean>(false);
  const [canPlaceOrders, setCanPlaceOrders] = useState<boolean>(false);
  const [addresses, setAddresses] = useState<InvoicedAddress[]>([]);

  const invoicingContextValue: InvoicingContextInterface = {
    invoicingEnabled,
    addresses,
    setInvoicingEnabled,
    setAddresses,
    canPlaceOrders,
    setCanPlaceOrders,
  };

  return { invoicingContextValue };
}
