import React, { FC, Ref, useState, useRef } from "react";
import {
  Field,
  FieldInputProps as FinalFormInputProps,
} from "react-final-form";
import Downshift, {
  GetInputPropsOptions,
  DownshiftState,
  StateChangeOptions,
  ControllerStateAndHelpers,
} from "downshift";
import styled from "styled-components";
import debounce from "lodash/debounce";
import { DebouncedFunc } from "lodash-es";
import { AutocompleteOptionLabel } from "./AutocompleteOptionLabel";
import { Spinner } from "../spinner/Spinner";
import {
  logLoqateAutocompleteInitiated,
  logLoqateAutocompleteAddressSelected,
} from "@pepdirect/helpers/analyticsLogger";
import {
  SelectedItemProps,
  AutocompletedAddressValues,
} from "@pepdirect/shared/types";
import { FieldProps } from "./types";
import { colors } from "../../styles/variables";

const StyledField = styled.div`
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  height: 30px;
  margin: 30px 0 25px;
`;

const InputContainer = styled.div`
  position: absolute;
  width: 100%;
`;

const Label = styled.span<{ moved: boolean }>`
  position: absolute;
  top: 6px;
  transition: transform 0.2s, color 0.2s;
  transform-origin: 0 0;
  font-size: 16px;
  line-height: 1;
  color: ${colors.mediumGray};
  ${({ moved }) =>
    moved &&
    `
    transform: scale(0.8) translate(0, -25px);
    transition: transform 0.2s, color 0.2s;
  `};
`;

const StyledInput = styled.input<{ valid?: boolean; invalid?: boolean }>`
  width: 100%;
  height: 30px;
  background-color: transparent;
  font: inherit;
  font-size: 16px;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ valid, invalid, theme }) =>
    (valid && colors.green) ||
    (invalid && theme.color.error) ||
    colors.mediumGray};

  &:focus {
    border-bottom-color: ${({ valid, invalid, theme }) =>
      !valid && !invalid && theme.color.primary};
    outline: none;
  }
`;

// copied from react-select menu styles
const OptionListContainer = styled.div`
  top: 100%;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  position: absolute;
  width: 100%;
  z-index: 1;
  overflow: hidden;
`;

// copied from react-select menuList styles
const OptionList = styled.div`
  max-height: 220px;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  position: relative;

  & > div {
    padding: 8px 12px;
    cursor: pointer;
  }
`;

const Option = styled.div<OptionProps>`
  padding: 8px 12px;
  cursor: pointer;

  ${({ selectedItem, label, highlightedIndex, index }) => `
    background-color: ${
      selectedItem === label
        ? colors.gray
        : highlightedIndex === index
        ? "rgba(0, 0, 0, 0.15)"
        : "white"
    };
    color: ${selectedItem === label && "white"};
    ${
      selectedItem !== label &&
      `
      &:hover {
        background-color: rgba(0, 0, 0, 0.15);
      }
    `
    }
  `}
`;

export const StyledSpinner = styled.div`
  position: absolute;
  top: 13px;
  left: 28px;
`;

const Error = styled.span`
  font-size: 12px;
  line-height: 1.3;
  color: ${({ theme }) => theme.color.error};
  margin-top: 5px;
  display: block;
`;

interface AutocompleteInputProps extends FieldProps {
  input?: FinalFormInputProps<string>;
  setAutocompletedAddressValues: (
    props: AutocompletedAddressValues | null
  ) => void;
  browserAutofillOffValue?: string;
  findAddresses: (
    inputValue?: string,
    container?: string
  ) => Promise<SelectedItemProps[]>;
  retrieveAddresses: (
    addressId: string
  ) => Promise<AutocompletedAddressValues[]>;
}

// https://github.com/downshift-js/downshift/issues/718#issuecomment-540901414
interface GetInputPropsOptionsRef extends GetInputPropsOptions {
  ref?: Ref<HTMLInputElement>;
  as?: never;
}

interface OptionProps {
  selectedItem: string;
  label: string;
  highlightedIndex: number;
  index: number;
}

type DebouncedFind = DebouncedFunc<
  (
    inputValue?: string | undefined,
    container?: string | undefined
  ) => Promise<void>
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type InputOnChange = (event: React.ChangeEvent<HTMLInputElement> | any) => void;

// React Final Form + Downshift example
// https://codesandbox.io/s/qzm43nn2mj
export const AutocompleteInput: FC<AutocompleteInputProps> = ({
  name,
  setAutocompletedAddressValues,
  browserAutofillOffValue,
  findAddresses,
  retrieveAddresses,
  ...props
}) => {
  const [selectedAddress, setSelectedAddress] =
    useState<SelectedItemProps | null>(null);
  const [foundAddresses, setFoundAddresses] = useState<SelectedItemProps[]>([]);
  const [autocompleteInitiated, setAutocompleteInitiated] = useState(false);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);

  const debounceFindAddresses = useRef<DebouncedFind | null>(null);

  const find = async (inputValue?: string, container?: string) => {
    const addresses = await findAddresses(inputValue, container);
    setFoundAddresses(addresses);
  };

  // https://github.com/downshift-js/downshift/issues/347#issuecomment-469531762
  if (!debounceFindAddresses.current) {
    debounceFindAddresses.current = debounce(find, 500);
  }

  /**
   * We need to use both stateReducer and handleStateChange to listen to events because:
   *
   * 1. stateReducer has the existing value in the input (state.inputValue)
   *    and handleStateChange does not have access to it
   * 2. stateReducer should be "pure": do nothing other than return the
   *    state changes you want to have happen, so we do external
   *    function calls in handleStateChange
   */
  const stateReducer = (
    state: DownshiftState<SelectedItemProps | string>,
    changes: StateChangeOptions<SelectedItemProps>
  ) => {
    const { type, selectedItem } = changes;

    // if a user selects an address type that is not "Address"
    // (i.e. a multi-unit building): keep dropdown open for the
    // new results and leave input value as-is
    if (
      (type === Downshift.stateChangeTypes.clickItem ||
        type === Downshift.stateChangeTypes.keyDownEnter) &&
      selectedItem &&
      selectedItem.type !== "Address"
    ) {
      return {
        ...changes,
        isOpen: true,
        inputValue: state.inputValue,
      };
    }

    // Under the hood, Downshift wants to set the value based on the
    // findAddresses but we want to set it based on the value of
    // retrieveAddresses so we control it here
    if (
      type === Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem &&
      selectedItem?.line1Value
    ) {
      return {
        ...changes,
        inputValue: selectedItem.line1Value,
      };
    }

    // if user clicks/blurs out of field, keep their typed input value as-is
    if (
      type === Downshift.stateChangeTypes.mouseUp ||
      type === Downshift.stateChangeTypes.blurInput
    ) {
      return {
        ...changes,
        isOpen: false,
        inputValue: state.inputValue,
      };
    }

    return changes;
  };

  const handleStateChange = async (
    changes: StateChangeOptions<SelectedItemProps>,
    stateAndHelpers: ControllerStateAndHelpers<SelectedItemProps>
  ) => {
    if (!autocompleteInitiated) {
      logLoqateAutocompleteInitiated();
      setAutocompleteInitiated(true);
    }

    const { type, selectedItem } = changes;
    const { inputValue } = stateAndHelpers;

    // if a user selects an address type that is not "Address"
    // (i.e. a multi-unit building) do another Find API call
    if (
      (type === Downshift.stateChangeTypes.clickItem ||
        type === Downshift.stateChangeTypes.keyDownEnter) &&
      selectedItem &&
      selectedItem.type !== "Address"
    ) {
      find(undefined, selectedItem.id);
    }

    if (
      (type === Downshift.stateChangeTypes.clickItem ||
        type === Downshift.stateChangeTypes.keyDownEnter) &&
      selectedItem &&
      selectedItem.type === "Address"
    ) {
      setAutocompleteLoading(true);
    }

    // if the user is still typing, debounce the Find API
    if (type === Downshift.stateChangeTypes.changeInput) {
      debounceFindAddresses.current &&
        debounceFindAddresses.current(inputValue || "");
      setAutocompleteInitiated(true);
    }
  };

  const handleSelection =
    (inputOnChange: InputOnChange) =>
    async (selectedItem: SelectedItemProps | null) => {
      // only update the input to be the selection
      // if a user selects an address type that is "Address"
      if (selectedItem?.type === "Address" && selectedItem.id) {
        logLoqateAutocompleteAddressSelected();
        // after user selects an address, allow for
        // logLoqateAutocompleteInitiated to happen again
        // if the user types again to find another address
        setAutocompleteInitiated(false);
        try {
          const addresses = await retrieveAddresses(selectedItem.id);
          // TODO: the API could return more than 1 address, how to handle?
          const retrievedAddress = addresses[0];
          setAutocompletedAddressValues(retrievedAddress);
          // set value for react-final-form
          inputOnChange(retrievedAddress.line1);
          // This type must be the same type of selectedItem. We create another
          // key in SelectedItemProps called `line1Value` for itemToString to
          // look for, otherwise if we were to set the `value` as previously
          // done, it would flash the incorrect full address from findAddress
          // before setting the correct line1 from retrieveAddress
          setSelectedAddress({ line1Value: retrievedAddress.line1 });
        } catch {
          // logged in errorLink
        } finally {
          setAutocompleteLoading(false);
        }
      }
    };

  return (
    <Field name={name} {...props}>
      {({ input, meta }) => (
        <StyledField>
          <InputContainer>
            <Downshift
              {...input}
              itemToString={(item: SelectedItemProps | null) =>
                item?.line1Value || ""
              }
              selectedItem={selectedAddress}
              initialInputValue={
                typeof meta.initial === "string" ? meta.initial : undefined
              }
              stateReducer={stateReducer}
              onStateChange={handleStateChange}
              onChange={handleSelection(input.onChange)}
              inputId={name}
              labelId={name}
            >
              {({
                getInputProps,
                getItemProps,
                isOpen,
                inputValue,
                highlightedIndex,
                selectedItem,
              }) => {
                return (
                  <div>
                    <label id={name}>
                      {props.label && (
                        <Label
                          moved={meta.active || (!meta.active && !!input.value)}
                        >
                          {props.label}
                        </Label>
                      )}
                      {autocompleteLoading && (
                        <StyledSpinner>
                          <Spinner />
                        </StyledSpinner>
                      )}
                      <StyledInput
                        data-testid="autocomplete-input"
                        {...(getInputProps({
                          id: name,
                          name: input.name,
                          // onBlur and onFocus needed for meta to work
                          onBlur: (e) => {
                            input.onBlur(e);
                            // set the user's existing input value on blur
                            input.onChange(inputValue);
                          },
                          onFocus: input.onFocus,
                        }) as GetInputPropsOptionsRef)}
                        valid={meta.touched && !meta.error}
                        invalid={meta.touched && meta.error}
                        autoComplete={browserAutofillOffValue}
                        disabled={autocompleteLoading}
                      />
                    </label>

                    {isOpen && !!foundAddresses?.length && (
                      <OptionListContainer>
                        <OptionList key="option-list">
                          {foundAddresses.map((item, index) => (
                            <Option
                              data-testid={`autocomplete-option-${index}`}
                              key={`autocomplete-option-${index}`}
                              selectedItem={selectedItem}
                              label={item.label}
                              highlightedIndex={highlightedIndex}
                              index={index}
                              {...getItemProps({
                                key: item.id,
                                index,
                                item,
                              })}
                            >
                              <AutocompleteOptionLabel
                                inputValue={inputValue || ""}
                                label={item.label || ""}
                              />
                            </Option>
                          ))}
                        </OptionList>
                      </OptionListContainer>
                    )}
                  </div>
                );
              }}
            </Downshift>
            {meta.touched && meta.error && <Error>{meta.error}</Error>}
          </InputContainer>
        </StyledField>
      )}
    </Field>
  );
};
