import { useState, useEffect, useMemo, useCallback } from "react";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { HostedFields } from "braintree-web";
import isEmpty from "lodash/isEmpty";
import {
  AddressFragment,
  CreateOrderMutation,
  CurrentUserQuery,
  PaymentRequestInput,
  useCalculateOrderDiscountsMutation,
  useUpdateCartItemMutation,
} from "services/graphql/generated";
import {
  createOrFetchOrder,
  saveEnteredShipToOrder,
} from "services/checkoutSubmission";
import {
  getContactInfoInput,
  getPriceSummary,
  getPaymentRequestForSubmit,
  getSubmitOrderMutationVariables,
  transformPriceFragmentToInput,
} from "helpers/checkout";
import { isValidEmail } from "helpers/validation";
import { determineIfShipVerified } from "helpers/address";
import { CartItemUpdateDto, CartError } from "@pepdirect/shared/types";
import { PriceSummary } from "types/price";
import { BraintreeErrorFieldsInvalid } from "types/payment";
import { CheckoutFormValues } from "pages/[cartId]/[appId]";
import { braintreeErrors, PAYMENT_TYPES } from "constants/payment";
import { DiscountsContextInterface } from "types/discounts";
import { ModalContextInterface } from "types/modal";
import { AutocompleteContextInterface } from "types/autocomplete";
import { AddressDetailsFormValues } from "components/Forms/AddressDetailsForm";
import { TenantContextInterface } from "v3/context/hooks/useTenantProvider";

let AUTO_SAVE_DEBOUNCE_ID: ReturnType<typeof setTimeout>;

export interface CheckoutContextInterface {
  // values
  apiBaseURL: string;
  cartErrors: CartError[];
  createdOrFetchedOrder: CreateOrderMutation["createOrder"];
  deliveryDate: Date | null;
  isCheckoutPageLoading: boolean;
  orderHasSubscription: boolean;
  paymentRequest: PaymentRequestInput;
  priceSummary: PriceSummary | null;
  selectedCountry: string;
  userInfo: CurrentUserQuery["currentUser"] | null;
  userShippingAddress: AddressFragment | null;
  selectedInvoicedAddressId: string | null;
  // functions
  autoSaveEnteredShip: (ship: AddressDetailsFormValues) => void;
  continueToConfirmation: (
    values: CheckoutFormValues,
    hostedFields: HostedFields | null,
    isApplePay?: boolean
  ) => void;
  setApiBaseURL: (url: string) => void;
  setCartErrors: (errors: CartError[]) => void;
  setCreatedOrFetchedOrder: (order: CreateOrderMutation["createOrder"]) => void;
  setDeliveryDate: (date: Date) => void;
  setIsCheckoutPageLoading: (isCheckoutPageLoading: boolean) => void;
  setOrderHasSubscription: (orderHasSub: boolean) => void;
  setPaymentRequest: (payment: PaymentRequestInput) => void;
  setSelectedCountry: (countryCode: string) => void;
  setUserInfo: (user: CurrentUserQuery["currentUser"] | null) => void;
  setUserShippingAddress: (addr: AddressFragment | null) => void;
  setSelectedInvoicedAddressId: (addr: string | null) => void;
  updateCart: (dto: CartItemUpdateDto) => Promise<void>;
}

export function useCheckoutProvider(
  client: ApolloClient<NormalizedCacheObject>,
  tenantContext: TenantContextInterface,
  discountsContext: DiscountsContextInterface,
  modalContext: ModalContextInterface,
  autocompleteContext: AutocompleteContextInterface
): {
  checkoutContextValue: CheckoutContextInterface;
} {
  const { tenant } = tenantContext;
  const { open, isOpen } = modalContext;
  const { discounts } = discountsContext;
  const { shipSelectedFromAutocomplete } = autocompleteContext;

  const [createdOrFetchedOrder, setCreatedOrFetchedOrder] =
    useState<CreateOrderMutation["createOrder"]>(null);
  const [userInfo, setUserInfo] = useState<
    CurrentUserQuery["currentUser"] | null
  >(null);
  const [userShippingAddress, setUserShippingAddress] =
    useState<AddressFragment | null>(null);
  const [selectedInvoicedAddressId, setSelectedInvoicedAddressId] = useState<
    string | null
  >(null);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequestInput>({});
  const [deliveryDate, setDeliveryDate] = useState<Date | null>(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isCheckoutPageLoading, setIsCheckoutPageLoading] = useState(false);
  const [apiBaseURL, setApiBaseURL] = useState("");
  const [cartErrors, setCartErrors] = useState<CartError[]>([]);
  const [orderHasSubscription, setOrderHasSubscription] = useState(false);

  const [updateCartItem] = useUpdateCartItemMutation({ client });
  const [calculateOrderDiscounts] = useCalculateOrderDiscountsMutation({
    client,
  });

  const priceSummary = useMemo(() => {
    if (createdOrFetchedOrder) {
      return getPriceSummary(
        createdOrFetchedOrder.price.items,
        createdOrFetchedOrder.price.shipping || 0
      );
    }
    return null;
  }, [createdOrFetchedOrder]);

  const handleSetApiBaseUrl = useCallback(
    (url: string) => setApiBaseURL(url),
    []
  );
  const handleSetCreatedOrFetchedOrder = useCallback(
    (order: CreateOrderMutation["createOrder"]) => {
      setCreatedOrFetchedOrder(order);
    },
    []
  );
  const handleSetPaymentRequest = useCallback(
    (payment: PaymentRequestInput) => setPaymentRequest(payment),
    []
  );
  const handleSetUserInfo = useCallback(
    (user: CurrentUserQuery["currentUser"] | null) => {
      setUserInfo(user);
    },
    []
  );
  const handleSetUserShippingAddress = useCallback(
    (addr: AddressFragment | null) => setUserShippingAddress(addr),
    []
  );
  const handleSetSelectedInvoicedAddressId = useCallback(
    (addr: string | null) => {
      return setSelectedInvoicedAddressId(addr);
    },
    []
  );
  const handleSetDeliveryDate = useCallback(
    (date: Date) => setDeliveryDate(date),
    []
  );
  const handleSetCartErrors = useCallback(
    (errors: CartError[]) => setCartErrors(errors),
    []
  );

  const handleOrderHasSubscription = useCallback(
    (hasSub: boolean) => setOrderHasSubscription(hasSub),
    []
  );

  // Set selected country on saved address option changes
  useEffect(() => {
    if (userShippingAddress) {
      setSelectedCountry(userShippingAddress.countryCode);
    }
  }, [userShippingAddress]);

  /**
   * Currently we do not allow the updating of cart in checkout. Once bundle
   * builder is implemented, please test updateCart thoroughly
   */
  const handleUpdateCart = async (dto: CartItemUpdateDto) => {
    const { productId, quantity } = dto;
    const { cart } = createdOrFetchedOrder || {};
    try {
      await updateCartItem({
        variables: { id: cart?.id, itemId: productId, quantity },
      });

      const orderData = await createOrFetchOrder(cart?.id);

      // recalculate discounts when the cart changes
      if (orderData && discounts.length) {
        const { data } = await calculateOrderDiscounts({
          variables: {
            input: {
              orderId: createdOrFetchedOrder?.id,
              price: transformPriceFragmentToInput({
                ...orderData.price,
                discounts,
              }),
            },
          },
        });

        const { calculateOrderDiscounts: orderWithDiscounts } = data || {};

        if (orderWithDiscounts?.order?.price) {
          orderData.price = orderWithDiscounts.order.price;
        }
      }
      setCreatedOrFetchedOrder(orderData);
    } catch (e) {
      // logged in errorLink
    }
  };

  /**
   * aka "Remember Me" feature - a convenience for users that have started
   * filling out the ship form with a new address. If they leave the app/
   * refresh the page/come back later, the ship form will be filled out for them
   * with the previously entered information.
   */
  const handleAutoSaveEnteredShip = async (
    newShip: Partial<AddressDetailsFormValues>
  ) => {
    // we set the logged-in user's email to the form so we don't need to worry
    // about checking it on the user, it exists on newShip
    const validEmail = await isValidEmail(newShip?.email);

    if (
      // if there is a modal open, we should not be auto-saving anything
      isOpen ||
      // do not autosave on invoicing for the now.  in the future we may want to re-enable
      paymentRequest?.paymentMethod === PAYMENT_TYPES.INVOICE ||
      // must have entered ship values
      isEmpty(newShip) ||
      // must have a valid email
      !validEmail
    ) {
      clearTimeout(AUTO_SAVE_DEBOUNCE_ID);
      return;
    }

    clearTimeout(AUTO_SAVE_DEBOUNCE_ID);

    AUTO_SAVE_DEBOUNCE_ID = setTimeout(async () => {
      try {
        await saveEnteredShipToOrder({
          orderId: createdOrFetchedOrder?.id,
          input: {
            contactInfo: getContactInfoInput(
              newShip,
              newShip.email || "",
              false
            ),
          },
        });
      } catch (e) {
        // logged in errorLink
      }
    }, 2000);
  };

  const handleContinueToConfirmation = async (
    values: CheckoutFormValues,
    hostedFields: HostedFields | null,
    isApplePay?: boolean
  ) => {
    // cancel any pending autosave timeouts
    clearTimeout(AUTO_SAVE_DEBOUNCE_ID);

    const savedShip = userShippingAddress;
    const newShip = values.shippingAddress;

    const ship = savedShip || newShip;
    const { submissionUid } = createdOrFetchedOrder || {};

    // must have an available shipping address and submissionUid
    if (!ship || !submissionUid || !createdOrFetchedOrder) {
      // something went wrong
      open("ERROR_MODAL");
      setIsCheckoutPageLoading(false);
      return;
    }

    const selectedPaymentRequest = !isApplePay
      ? paymentRequest
      : {
          paymentMethod: PAYMENT_TYPES.APPLE_PAY,
          paymentToken: undefined,
          token: undefined,
        };
    const { paymentMethod, paymentToken, token } = selectedPaymentRequest;

    let paymentRequestForSubmit;

    try {
      paymentRequestForSubmit = await getPaymentRequestForSubmit(
        hostedFields,
        selectedPaymentRequest,
        ship,
        values.billingAddress || null,
        values.payment?.cardholderName || ""
      );
    } catch (tokenizeErr) {
      open("ERROR_MODAL", {
        error: braintreeErrors(tokenizeErr as BraintreeErrorFieldsInvalid),
      });
      setIsCheckoutPageLoading(false);
      return;
    }

    if (!paymentRequestForSubmit) {
      // something went wrong
      open("ERROR_MODAL");
      setIsCheckoutPageLoading(false);
      return;
    }

    if (paymentMethod === PAYMENT_TYPES.PAYPAL && !paymentToken && !token) {
      open("ERROR_MODAL", { error: "No Payment Method Provided" });
      setIsCheckoutPageLoading(false);
      return;
    }

    const priceBeforeTax = { ...createdOrFetchedOrder.price, discounts };
    const { giftForm } = values;
    if (paymentMethod === PAYMENT_TYPES.INVOICE) {
      const submitOrderVars = getSubmitOrderMutationVariables({
        ship,
        orderId: createdOrFetchedOrder.id,
        email: userInfo?.email || values?.shippingAddress?.email || "",
        paymentRequest: paymentRequestForSubmit,
        poNumber: values.poNumber,
        price: priceBeforeTax,
        deliveryDate,
        isShipValidated: true,
        ...(giftForm && giftForm),
        isGift: !!giftForm,
        invoiceAddressId: selectedInvoicedAddressId || "",
      });

      open("INVOICE_CONFIRMATION_MODAL", {
        submitOrderVars,
      });
      setIsCheckoutPageLoading(false);
      return;
    }

    const isShipValidated = determineIfShipVerified({
      tenant,
      shipSelectedFromAutocomplete,
      newShip,
      savedShip,
    });

    const submitOrderVars = getSubmitOrderMutationVariables({
      ship,
      orderId: createdOrFetchedOrder.id,
      email: userInfo?.email || values?.shippingAddress?.email || "",
      paymentRequest: paymentRequestForSubmit,
      price: priceBeforeTax,
      deliveryDate,
      ...(giftForm && giftForm),
      isGift: !!giftForm,
      isShipValidated,
      poNumber: values.poNumber,
    });

    open("VERIFICATION_AND_CONFIRMATION_MODAL", {
      submitOrderVars,
      priceBeforeTax,
    });
    setIsCheckoutPageLoading(false);
  };

  const checkoutContextValue: CheckoutContextInterface = {
    // values
    apiBaseURL,
    cartErrors,
    createdOrFetchedOrder,
    deliveryDate,
    isCheckoutPageLoading,
    orderHasSubscription,
    paymentRequest,
    priceSummary,
    selectedCountry,
    userInfo,
    userShippingAddress,
    selectedInvoicedAddressId,
    // functions
    autoSaveEnteredShip: (ship: Partial<AddressDetailsFormValues>) =>
      handleAutoSaveEnteredShip(ship),
    continueToConfirmation: (
      values: CheckoutFormValues,
      hostedFields: HostedFields | null,
      isApplePay?: boolean
    ) => handleContinueToConfirmation(values, hostedFields, isApplePay),
    setCartErrors: handleSetCartErrors,
    setApiBaseURL: handleSetApiBaseUrl,
    setCreatedOrFetchedOrder: handleSetCreatedOrFetchedOrder,
    setDeliveryDate: handleSetDeliveryDate,
    setIsCheckoutPageLoading: (isCheckoutPageLoading: boolean) => {
      setIsCheckoutPageLoading(isCheckoutPageLoading);
    },
    setOrderHasSubscription: handleOrderHasSubscription,
    setPaymentRequest: handleSetPaymentRequest,
    setSelectedCountry: (countryCode: string) =>
      setSelectedCountry(countryCode),
    setUserInfo: handleSetUserInfo,
    setUserShippingAddress: handleSetUserShippingAddress,
    setSelectedInvoicedAddressId: handleSetSelectedInvoicedAddressId,
    updateCart: async (dto: CartItemUpdateDto) => handleUpdateCart(dto),
  };

  return { checkoutContextValue };
}
