import braintree from "braintree-web";
import type { ApplePay } from "braintree-web";
import { BraintreeContext } from "context/braintree";
import { useEffect, useRef, useContext, useCallback } from "react";
import { logClientSideError } from "@pepdirect/helpers/logAndCaptureInSentry";
import { TenantContext } from "v3/context/tenant";
import { DiscountsContext } from "context/discounts";
import { getTotalAmount } from "helpers/price";
import { PriceSummary } from "types/price";
import { buildBraintreePaymentRequest } from "helpers/checkout";
import { PAYMENT_TYPES } from "constants/payment";
import { PaymentRequestInput } from "services/graphql/generated";

type UseApplePayReturnType = {
  authorizePayment: (
    priceSummary: PriceSummary
  ) => Promise<PaymentRequestInput | undefined>;
};

export function useApplePay(): UseApplePayReturnType {
  const { tenant } = useContext(TenantContext);
  const { discounts } = useContext(DiscountsContext);
  const { deviceData } = useContext(BraintreeContext);

  const { client } = useContext(BraintreeContext);
  const applePayRef = useRef<ApplePay>();

  useEffect(() => {
    (async () => {
      if (!client) return;

      try {
        const applePayInstance = await braintree.applePay.create({
          client: client,
        });

        applePayRef.current = applePayInstance;
      } catch (e) {
        logClientSideError(e);
      }
    })();
  }, [client]);

  const authorizePayment = useCallback(
    async (
      priceSummary: PriceSummary
    ): Promise<PaymentRequestInput | undefined> => {
      const subtotalPrice =
        typeof priceSummary.userSubtotalPrice === "number"
          ? priceSummary.userSubtotalPrice / 100
          : 0;
      const taxPrice =
        typeof priceSummary.userTaxPrice === "number"
          ? priceSummary.userTaxPrice / 100
          : 0;
      const shippingPrice =
        typeof priceSummary.userShippingPrice === "number"
          ? priceSummary.userShippingPrice / 100
          : 0;
      const discountPrice =
        typeof priceSummary.userDiscountPrice === "number"
          ? -priceSummary.userDiscountPrice / 100
          : 0;
      const totalPrice = (getTotalAmount(priceSummary, discounts) || 0) / 100;

      const lineItems = [
        {
          label: "Subtotal",
          type: "final",
          amount: subtotalPrice.toString(),
        },
        {
          label: "Shipping & Handling",
          type: "final",
          amount: shippingPrice.toString(),
        },
        {
          label: "Estimated Sales Tax",
          type: "final",
          amount: taxPrice.toString(),
        },
      ];

      if (discountPrice < 0) {
        lineItems.push({
          label: "Discount",
          type: "final",
          amount: discountPrice.toString(),
        });
      }

      const paymentRequest = applePayRef.current?.createPaymentRequest({
        total: {
          label: tenant?.brandConfig.title ?? "",
          amount: totalPrice.toString(),
        },
        // @ts-expect-error - Braintree types for ApplePayLineItem are incorrect
        lineItems,
        requiredBillingContactFields: ["postalAddress"],
      }) as ApplePayJS.ApplePayPaymentRequest;

      if (!paymentRequest) {
        // TODO: check if we it would be better to show an error modal here instead of throwing an error
        throw new Error("Payment request is not valid");
      }

      return new Promise((resolve, reject) => {
        const startPaymentSession = async () => {
          const session = new ApplePaySession(3, paymentRequest);
          session.onvalidatemerchant = async (event) => {
            try {
              const merchantSession =
                await applePayRef.current?.performValidation({
                  validationURL: event.validationURL,
                  displayName: tenant?.brandConfig.title ?? "",
                });
              session.completeMerchantValidation(merchantSession);
            } catch (error) {
              logClientSideError(error);
              reject(error);
            }
          };

          session.onpaymentauthorized = async (event) => {
            try {
              const payload = await applePayRef.current?.tokenize({
                token: event.payment.token,
              });
              if (payload) {
                const paymentRequest = buildBraintreePaymentRequest({
                  nonce: payload?.nonce,
                  isBillingEqualShipping: true,
                  paymentMethod: PAYMENT_TYPES.APPLE_PAY,
                  saveCardForLater: false,
                  deviceData,
                });
                resolve(paymentRequest);
              }

              session.completePayment(ApplePaySession.STATUS_SUCCESS);
            } catch (error) {
              session.completePayment(ApplePaySession.STATUS_FAILURE);
              reject(error);
            }
          };

          session.oncancel = () => {
            resolve(undefined);
          };
          session.begin();
        };
        startPaymentSession();
      });
    },
    [deviceData, discounts, tenant?.brandConfig.title]
  );

  return {
    authorizePayment,
  };
}
