import React, { FC, SVGProps } from "react";
import { HexColor } from "../../types/styles";
import { colors } from "../../styles/variables";

interface ExchangeIconSvgProps extends SVGProps<SVGSVGElement> {
  color?: HexColor;
}

export const ExchangeIconSvg: FC<ExchangeIconSvgProps> = ({
  color = colors.gray900,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
  >
    <path
      id="Vector"
      d="M5.21156 11.0103C4.07698 10.6097 3.16333 9.92041 2.47063 8.94249C1.77792 7.96447 1.43156 6.8753 1.43156 5.67499C1.43156 5.31364 1.46458 4.95905 1.53063 4.61124C1.59677 4.26353 1.69594 3.91942 1.82813 3.5789L0.400313 4.39983L0 3.68577L2.64547 2.15468L4.17672 4.82171L3.48438 5.2339L2.63938 3.78077C2.51281 4.08442 2.41625 4.39692 2.34969 4.71827C2.28323 5.03963 2.25 5.36577 2.25 5.69671C2.25 6.76317 2.56292 7.71629 3.18875 8.55608C3.81448 9.39577 4.63135 9.97588 5.63938 10.2964L5.21156 11.0103ZM9.27281 3.4778V2.66546H10.9447C10.5008 2.09254 9.94557 1.63879 9.27891 1.30421C8.61224 0.969625 7.89026 0.802334 7.11297 0.802334C6.48557 0.802334 5.90063 0.916345 5.35813 1.14437C4.81563 1.37228 4.32688 1.67697 3.89188 2.05843L3.46391 1.33843C3.95193 0.916969 4.5062 0.586865 5.12672 0.348115C5.74734 0.109365 6.40745 -0.0100098 7.10703 -0.0100098C8.00922 -0.0100098 8.83714 0.179885 9.59078 0.559677C10.3443 0.939469 10.9883 1.44989 11.5228 2.09093V0.415459H12.3353V3.4778H9.27281ZM8.98797 12.8228L6.3425 11.2856L7.87375 8.64015L8.58766 9.0464L7.74875 10.4875C8.95073 10.3184 9.95573 9.77817 10.7638 8.86671C11.5719 7.95525 11.9759 6.90577 11.9759 5.71827C11.9759 5.53077 11.9647 5.34384 11.9423 5.15749C11.9198 4.97124 11.8882 4.78634 11.8473 4.6028H12.6814C12.7223 4.77593 12.7504 4.95463 12.7656 5.1389C12.7808 5.32317 12.7884 5.5091 12.7884 5.69671C12.7884 7.08931 12.3287 8.31765 11.4092 9.38171C10.4897 10.4458 9.33807 11.0844 7.95422 11.2976L9.39422 12.1245L8.98797 12.8228Z"
      fill={color}
    />
  </svg>
);
