import React, { useContext } from "react";
import styled from "styled-components";
import { TenantContext } from "v3/context/tenant";
import { LocalizationContext } from "v3/context/localization";
import { TemplateAsComponent } from "@pepdirect/v3/ui/TemplateAsComponent";
import { Button } from "@pepdirect/ui/button";
import { Heading } from "@pepdirect/ui/heading";
import { ExternalLink } from "@pepdirect/ui/ExternalLink";
import { ErrorProps } from "types/modal";
import { BrandConfigCustomizationsLink } from "services/graphql/generated";
import st from "./errorModal.module.scss";

const StyledExternalLink = styled(ExternalLink)`
  color: ${({ theme }) => theme.color.primary};
  text-decoration: underline;
`;

interface ErrorModalProps extends ErrorProps {
  onClose: () => void;
}

export function ErrorModal({
  error,
  children,
  hideContact = false,
  buttonType = "secondary",
  buttonText,
  header,
  onClose,
}: ErrorModalProps): JSX.Element {
  const { tenant } = useContext(TenantContext);
  const { genericErrorMsg, localization } = useContext(LocalizationContext);

  const {
    errorHeadingText = "Error",
    okText = "OK",
    visitOurLinkForAssistanceText = "Visit our ${link} for assistance.",
  } = localization?.components?.errorModal || {};

  const contactLink = (tenant?.brandConfig.customizations.links || []).find(
    (link: BrandConfigCustomizationsLink) => link.location === "error-modal"
  );

  return (
    <>
      <div className={st.title}>
        <Heading level="h2" size="xl">
          {header?.length ? header : errorHeadingText}
        </Heading>
      </div>

      <div className={st.text}>{children || error || genericErrorMsg}</div>

      {!(children || error || hideContact) && contactLink && (
        <div className={st.contact}>
          <TemplateAsComponent
            template={visitOurLinkForAssistanceText}
            display="inline"
            map={{
              link: (
                <StyledExternalLink href={contactLink ? contactLink.url : ""}>
                  {contactLink.title}
                </StyledExternalLink>
              ),
            }}
          />
        </div>
      )}

      <div className={st.buttonContainer}>
        <Button type={buttonType} onClick={onClose}>
          {buttonText || okText}
        </Button>
      </div>
    </>
  );
}
