import { AnyObjectSchema, object, string, lazy } from "yup";
import { ValidationErrors } from "final-form";
import { emptyInputMessage } from "constants/field";
import { CheckoutFormValues } from "pages/[cartId]/[appId]";
import { AddressDetailsFormValues } from "components/Forms/AddressDetailsForm";
import { PaymentFormValues } from "types/payment";
import { PAYMENT_TYPES } from "constants/payment";
import { regexEmailHelper } from "@pepdirect/v3/helpers/validation";
import { ReactSelectDropdownOption } from "@pepdirect/ui/dropdown/types";
import { giftSchema, validateWithYup } from "@pepdirect/ui/validation";

const WRONG_EMAIL = "Email address is not valid";
const FIELD_REQUIRED = emptyInputMessage;
const PASSWORD_REQUIRED = "Your password is required";
const PHONE_INVALID = "This phone number is not valid";
export const US_ZIP_INVALID = "Invalid US zip code";
export const CA_ZIP_INVALID = "Invalid Canadian postal code";

// https://www.regexpal.com/17
const REGEX_PHONE =
  /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
// https://regexland.com/zip-codes/
const REGEX_ZIP_US = /^\d{5}(?:[- ]?\d{4})?$/;
// https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s15.html
const REGEX_ZIP_CA =
  /^(?!.*[DdFfIiOoQqUu])[A-VXYa-vxy][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/;

export const isValidEmail = async (
  email: string | undefined
): Promise<boolean> => {
  const emailIsValid = await object({
    email: string().trim().email().required(),
  }).isValid({ email });

  return emailIsValid;
};

export const getChangePasswordSchema = (messages: Record<string, string>) =>
  object({
    currentPassword: string()
      .trim()
      .required(messages["currentPasswordRequired"] || PASSWORD_REQUIRED),
    password: string()
      .trim()
      .required(
        messages["newPasswordRequired"] ||
          "Please enter a new password to use for your account"
      ),
  });

const addressPartial = {
  firstName: string().required(FIELD_REQUIRED).nullable(),
  lastName: string().required(FIELD_REQUIRED).nullable(),
  line1: lazy((val: Record<string, unknown> | string) => {
    // type varies depending on autocomplete feature
    if (typeof val === "object") {
      return object()
        .shape({
          id: string(),
          value: string(),
          type: string(),
          label: string(),
        })
        .nullable();
    } else {
      return string().required(FIELD_REQUIRED).nullable();
    }
  }),
  state: object()
    .shape({
      value: string().required(),
      label: string().required(),
    })
    // https://github.com/jquense/yup/issues/1455#issuecomment-1318661574
    .default(undefined)
    .required(FIELD_REQUIRED)
    .nullable(),
  city: string().required(FIELD_REQUIRED).nullable(),
  zip: string()
    .when("country", {
      is: (option: ReactSelectDropdownOption) => option?.value === "US",
      then: string()
        .matches(REGEX_ZIP_US, {
          excludeEmptyString: true,
          message: US_ZIP_INVALID,
        })
        .required(FIELD_REQUIRED)
        .nullable(),
    })
    .when("country", {
      is: (option: ReactSelectDropdownOption) => option?.value === "CA",
      then: string()
        .matches(REGEX_ZIP_CA, {
          excludeEmptyString: true,
          message: CA_ZIP_INVALID,
        })
        .required(FIELD_REQUIRED)
        .nullable(),
    })
    .when("country", {
      is: (option: ReactSelectDropdownOption) =>
        !["US", "CA"].includes(option?.value),
      then: string().required(FIELD_REQUIRED).nullable(),
    }),
  country: object()
    .shape({
      value: string().required(),
      label: string().required(),
    })
    .default(undefined)
    .required(FIELD_REQUIRED)
    .nullable(),
};

const cardholderName = string().required(FIELD_REQUIRED);

const phoneNumber = string()
  .matches(REGEX_PHONE, {
    excludeEmptyString: true,
    message: PHONE_INVALID,
  })
  .required(FIELD_REQUIRED)
  .nullable();

const userShipPaymentBillSchema = object({
  shippingAddress: object({
    ...addressPartial,
    phoneNumber,
  }),
  payment: object({ cardholderName }),
  billingAddress: object({ ...addressPartial }),
});

const userShipAndPaymentSchema = object({
  shippingAddress: object({
    ...addressPartial,
    phoneNumber,
  }),
  payment: object({ cardholderName }),
});

const userPaymentAndBillSchema = object({
  payment: object({ cardholderName }),
  billingAddress: object({ ...addressPartial }),
});

export const userShipSchema = object({
  shippingAddress: object({
    ...addressPartial,
    nickname: string().max(140).nullable(),
    phoneNumber,
  }),
});

const userPaymentSchema = object({
  payment: object({ cardholderName }),
});

const guestShipSchema = object({
  shippingAddress: object({
    email: string()
      .trim()
      .email(WRONG_EMAIL)
      .matches(regexEmailHelper, {
        excludeEmptyString: true,
        message: "Email address is not valid",
      })
      .required(FIELD_REQUIRED),
    ...addressPartial,
    nickname: string().max(140).nullable(),
    phoneNumber,
  }),
});

const guestShipPaymentBillSchema = object({
  shippingAddress: object({
    email: string()
      .trim()
      .email(WRONG_EMAIL)
      .matches(regexEmailHelper, {
        excludeEmptyString: true,
        message: "Email address is not valid",
      })
      .required(FIELD_REQUIRED),
    ...addressPartial,
    phoneNumber,
  }),
  payment: object({ cardholderName }),
  billingAddress: object({ ...addressPartial }),
});

const guestShipPaymentSchema = object({
  shippingAddress: object({
    email: string()
      .trim()
      .email(WRONG_EMAIL)
      .matches(regexEmailHelper, {
        excludeEmptyString: true,
        message: "Email address is not valid",
      })
      .required(FIELD_REQUIRED),
    ...addressPartial,
    phoneNumber,
  }),
  payment: object({ cardholderName }),
});

const FormErrorsType = Promise;
type FormErrorsType = Promise<ValidationErrors | undefined>;

export interface AddressValidationProps {
  shippingAddress: AddressDetailsFormValues;
}

export interface CreditCardValidationProps {
  cardholderName: PaymentFormValues;
}

export interface PaymentAndBillingFormValues {
  payment: PaymentFormValues;
  billingAddress?: AddressDetailsFormValues;
}

export const addressValidation = async (
  values: AddressValidationProps
): FormErrorsType => {
  const handleValidation = async (schema: AnyObjectSchema) => {
    const errors = await validateWithYup(schema)(values);
    return errors;
  };

  return await handleValidation(userShipSchema);
};

export const paymentValidation = async (
  values: PaymentAndBillingFormValues,
  billingFormOpen: boolean
): FormErrorsType => {
  const handleValidation = async (schema: AnyObjectSchema) => {
    const errors = await validateWithYup(schema)(values);
    return errors;
  };

  // billing open
  if (billingFormOpen) {
    return await handleValidation(userPaymentAndBillSchema);
  }

  return await handleValidation(userPaymentSchema);
};

export const giftValidation = async (
  values: CheckoutFormValues,
  isLoading: boolean,
  scrollToError: (err: ValidationErrors | undefined) => void
): FormErrorsType => {
  const handleValidation = async (schema: AnyObjectSchema) => {
    const errors = await validateWithYup(schema)(values);

    // only scroll to the error after clicking submit
    if (isLoading) {
      scrollToError(errors);
    }
    return errors;
  };

  return handleValidation(giftSchema);
};

export const checkoutValidation = async (
  paymentType: PAYMENT_TYPES,
  values: CheckoutFormValues,
  isLoggedInUser: boolean,
  shipFormOpen: boolean,
  paymentFormOpen: boolean,
  billingFormOpen: boolean,
  isLoading: boolean,
  scrollToError: (err: ValidationErrors | undefined) => void
): FormErrorsType => {
  const handleValidation = async (schema: AnyObjectSchema) => {
    const errors = await validateWithYup(schema)(values);

    // only scroll to the error after clicking submit
    if (isLoading) {
      scrollToError(errors);
    }

    return errors;
  };

  // NOTE: if the payment form is closed, the billing form is also closed
  if (paymentType === PAYMENT_TYPES.CREDIT_CARD) {
    if (isLoggedInUser) {
      // no forms open
      if (!shipFormOpen && !paymentFormOpen) {
        return await handleValidation(object({}));
      }

      // all forms open
      if (shipFormOpen && paymentFormOpen && billingFormOpen) {
        return await handleValidation(userShipPaymentBillSchema);
      }

      // ship and payment open
      if (shipFormOpen && paymentFormOpen && !billingFormOpen) {
        return await handleValidation(userShipAndPaymentSchema);
      }

      // payment and billing open
      if (!shipFormOpen && paymentFormOpen && billingFormOpen) {
        return await handleValidation(userPaymentAndBillSchema);
      }

      // ship open only
      if (shipFormOpen && !paymentFormOpen) {
        return await handleValidation(userShipSchema);
      }

      // payment open only
      if (!shipFormOpen && paymentFormOpen && !billingFormOpen) {
        return await handleValidation(userPaymentSchema);
      }
    }

    // guest flows: the shipping and payment forms are always open
    if (billingFormOpen) {
      // all forms open
      return await handleValidation(guestShipPaymentBillSchema);
    } else {
      // ship and payment open
      return await handleValidation(guestShipPaymentSchema);
    }
  }
  if (paymentType === PAYMENT_TYPES.PAYPAL) {
    if (isLoggedInUser) {
      if (shipFormOpen) {
        return await handleValidation(userShipSchema);
      }
    } else {
      // guest checkout
      return await handleValidation(guestShipSchema);
    }
  }

  if (paymentType === PAYMENT_TYPES.ALLOCATED_CREDIT && shipFormOpen) {
    return await handleValidation(userShipSchema);
  }
};
