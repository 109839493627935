import { useState } from "react";
import { BlockedItem } from "@pepdirect/shared/types";

export interface BlockedItemsInterface {
  blockedItems: BlockedItem[];
  setBlockedItems: (itemIds: BlockedItem[]) => void;
}

export function useBlockedItemsProvider(): {
  blockedItemsContextValue: BlockedItemsInterface;
} {
  const [blockedItems, setBlockedItems] = useState<BlockedItem[]>([]);

  const blockedItemsContextValue = {
    blockedItems,
    setBlockedItems: (items: BlockedItem[]) => setBlockedItems(items),
  };

  return { blockedItemsContextValue };
}
