import { DiscountFragment } from "services/graphql/generated";

interface DiscountPriceProps {
  userDiscountPrice?: number | null;
  userDiscountableSubtotal?: number | null;
}

const calculateDiscount = (
  discountAmount: number,
  discountablePrice: number,
  isPercent?: boolean | null
): number => {
  if (isPercent) {
    return Math.round(discountablePrice * (discountAmount / 100));
  }
  return discountAmount;
};

export const getDiscountAmount = (
  { userDiscountPrice = 0, userDiscountableSubtotal = 0 }: DiscountPriceProps,
  discounts: DiscountFragment[]
): number => {
  const discountablePrice = userDiscountableSubtotal || 0;
  const { amount, isPercent } = discounts[0] || {};
  const discountAmount = calculateDiscount(
    amount || 0,
    discountablePrice,
    isPercent
  );

  return userDiscountPrice || discountAmount || 0;
};

export interface TotalAmountPriceProps extends DiscountPriceProps {
  userTotalPrice?: number | null;
}

export const getTotalAmount = (
  price: TotalAmountPriceProps,
  discounts: DiscountFragment[]
): number => {
  const { userTotalPrice = 0, userDiscountPrice } = price;

  // if item userDiscountPrice exists, it is already included in total;
  if (userDiscountPrice) return userTotalPrice || 0;

  const discountAmount = getDiscountAmount(price, discounts);

  return discountAmount && userTotalPrice
    ? userTotalPrice - discountAmount
    : userTotalPrice || 0;
};
