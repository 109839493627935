import { HexColor } from "../types/styles";

/**
 * OLD COLORS FOR REFERENCE WHEN CONVERTING TO V3 COLORS
 * (with updates from design for easier conversion)
 */
// export const colors: Record<colorNames, HexColor> = {
//   darkGray: "#1a1a1a",
//   mediumGray: "#666",
//   gray: "#ccc",
//   lightGray: "#f5f5f5",
//   borderGray: "#e5e5e5",
//   lightestGray: "#ccc",
//   red: "#cd0002",
//   orange: "#e07647",
//   green: "#027b3b",
//   white: "#fff",
//   black: "#000",
//   yellow: "#f48604",
//   brown: "#8e6451",
//   formBlue: "#0597f2",
// };

/**
 * *** GLOBAL COLOR LIST ***
 *
 * Note about adding colors:
 * - do NOT add to this list unless absolutely necessary!
 * - one-off colors should live in the component, not here
 * - brand-specific colors should live in its theme, not here
 */
export type colorNames =
  | "black"
  | "blue"
  | "blueForm"
  | "brown"
  | "gray100"
  | "gray200"
  | "gray300"
  | "gray400"
  | "gray500"
  | "gray600"
  | "gray700"
  | "gray800"
  | "gray900"
  | "green"
  | "orange"
  | "red"
  | "yellow"
  | "white";

export const colors: Record<colorNames, HexColor> = {
  black: "#000",
  blue: "#023e73",
  blueForm: "#0597f2",
  brown: "#925002",
  gray100: "#f5f5f5",
  gray200: "#e5e5e5",
  gray300: "#ccc",
  gray400: "#b2b2b2",
  gray500: "#808080",
  gray600: "#666",
  gray700: "#4d4d4d",
  gray800: "#333",
  gray900: "#1a1a1a",
  green: "#027b3b",
  orange: "#e07647",
  red: "#cd0002",
  white: "#fff",
  yellow: "#F9C645",
};
