import React from "react";
import { HexColor } from "../../types/styles";
import { colors } from "../../styles/variables";

export const ErrorIconSvg = ({
  color = colors.gray900,
  width = "18",
  height = "18",
}: {
  color?: HexColor;
  height?: string;
  width?: string;
}) => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 13.7C9.23333 13.7 9.425 13.625 9.575 13.475C9.725 13.325 9.8 13.1333 9.8 12.9C9.8 12.6667 9.725 12.475 9.575 12.325C9.425 12.175 9.23333 12.1 9 12.1C8.76667 12.1 8.575 12.175 8.425 12.325C8.275 12.475 8.2 12.6667 8.2 12.9C8.2 13.1333 8.275 13.325 8.425 13.475C8.575 13.625 8.76667 13.7 9 13.7ZM8.25 10.225H9.75V4.2H8.25V10.225ZM5.45 17.5L0.5 12.55V5.45L5.45 0.5H12.55L17.5 5.45V12.55L12.55 17.5H5.45ZM6.1 16H11.9L16 11.9V6.1L11.9 2H6.1L2 6.1V11.9L6.1 16Z"
      fill={color}
    />
  </svg>
);
