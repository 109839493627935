export const ADDRESS_FIELDS = {
  email: {
    label: "Email",
    name: "email",
    autoComplete: "email",
  },
  firstName: {
    label: "First Name",
    name: "firstName",
    autoComplete: "given-name",
  },
  lastName: {
    label: "Last Name",
    name: "lastName",
    autoComplete: "family-name",
  },
  cardholderName: {
    label: "Cardholder Name",
    name: "cardholderName",
    autoComplete: "name",
  },
  company: {
    label: "Company (Optional)",
    name: "company",
    autoComplete: "organization",
  },
  line1: {
    label: "Street Address",
    name: "line1",
    autoComplete: "address-line1",
  },
  line2: {
    label: "Apt., Suite, etc. (Optional)",
    name: "line2",
    autoComplete: "address-line2",
  },
  city: {
    label: "City",
    name: "city",
    autoComplete: "address-level2",
  },
  zip: {
    label: "Zip Code",
    name: "zip",
    autoComplete: "postal-code",
  },
  state: {
    label: "State",
    name: "state",
    autoComplete: "address-level1",
  },
  country: {
    label: "Country",
    name: "country",
    autoComplete: "country",
  },
  phoneNumber: {
    label: "Phone",
    name: "phoneNumber",
    autoComplete: "tel-national",
  },
  specialInstructions: {
    label: "Special Instructions",
    name: "specialInstructions",
    autoComplete: "",
  },
  nickname: {
    label: "Address Nickname",
    name: "nickname",
    autoComplete: "",
  },
  emailOptIn: {
    label:
      "Yes, I'd like to opt-in to receive additional product information, news and special offers.",
    name: "emailOptIn",
  },
  isDefault: {
    label: "Make this my default shipping address",
    name: "isDefault",
  },
};
