import { Response, BackendResponseWithData } from "./response";

interface ErrorResponseItem {
  messages: string[];
}

interface ErrorResponseData {
  errors?: ErrorResponseItem[];
  message: string;
}

export interface ErrorResponse {
  response?: Partial<Response<BackendResponseWithData<ErrorResponseData>>>;
}

export interface ErrorsInterface {
  [key: string]: string;
}

export enum SignupErrorKeys {
  emailVerificationRequired = "emailVerificationRequired",
}
