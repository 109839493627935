// USE FOR BOTH V2 AND V3
export const PageRoutes = {
  // these are v3
  signIn: "/v3/sign-in",
  changePassword: "/v3/dashboard/account/change-password",
  editAddress: (addrId: string) =>
    `/v3/dashboard/account/edit-address/${addrId}`,
  exchange: "/v3/dashboard/exchange",
  exchangeDetails: (exchangeId: string) =>
    `/v3/dashboard/exchange/${exchangeId}`,
  machines: "/v3/dashboard/machines",
  updatePassword: "/v3/update-password",
  orderDetails: (orderId: string) => `/v3/dashboard/orders/${orderId}`,
  subscriptionList: "/v3/dashboard/subscriptions",
  subscriptionDetail: (subId: string) => `/v3/dashboard/subscriptions/${subId}`,
  subscriptionPayments: (subId: string) =>
    `/v3/dashboard/subscriptions/${subId}/payments`,
  subscriptionAddresses: (subId: string) =>
    `/v3/dashboard/subscriptions/${subId}/addresses`,
  signUp: "/v3/sign-up",
  subscriptionEditAddress: (subId: string, addrId: string) =>
    `/v3/dashboard/subscriptions/${subId}/addresses/${addrId}`,

  // rest are v2
  account: "/dashboard/account",
  addresses: "/dashboard/account/addresses",
  addAddress: "/dashboard/account/add-address",
  addPayment: "/dashboard/account/add-payment",
  orders: "/dashboard/orders",
  subscriptionAddPayment: (subId: string) =>
    `/dashboard/subscriptions/${subId}/payments/add-payment`,
};
