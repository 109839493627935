import React, { FC, SVGProps } from "react";
import styled from "styled-components";
import { HexColor } from "../../types/styles";
import { colors } from "../../styles/variables";

export enum PagePointDirections {
  left = "left",
  right = "right",
}

const pointDirectionTransformMap = {
  [PagePointDirections.right]: "transform: none",
  [PagePointDirections.left]: "transform: rotate(180deg)",
};

const StyledSvg = styled.svg<{ pointDirection: PagePointDirections }>`
  ${({ pointDirection }) => pointDirectionTransformMap[pointDirection]};
`;

interface PageIconSvgProps extends SVGProps<SVGSVGElement> {
  pointDirection?: PagePointDirections;
  color?: HexColor;
  height?: string;
  width?: string;
  className?: string;
}

export const PageIconSvg: FC<PageIconSvgProps> = ({
  pointDirection = PagePointDirections.right,
  color = colors.gray900,
  height = "6px",
  width = "10px",
  className = "",
}) => (
  <StyledSvg
    pointDirection={pointDirection}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.3125 16.9216L7.28003 17.8892L13.1694 11.9998L7.28003 6.11035L6.3125 7.07788L11.2344 11.9998L6.3125 16.9216ZM16.3125 6.11035V17.8854H17.6875V6.11035H16.3125Z"
      fill={color}
    />
  </StyledSvg>
);
