export const Size = {
  mobileMin: "320px",
  mobileMax: "767px",
  tabletMin: "768px",
  tabletMax: "1024px",
  laptopMin: "1025px",
};

export const Device = {
  mobile: `(min-width: ${Size.mobileMin}) and (max-width: ${Size.mobileMax})`,
  tablet: `(min-width: ${Size.tabletMin}) and (max-width: ${Size.tabletMax})`,
  laptop: `(min-width: ${Size.laptopMin})`,
  notMobile: `(min-width: ${Size.tabletMin})`,
  notLaptop: `(max-width: ${Size.tabletMax})`,
};
