import React from "react";
import { StripePaymentInterface } from "./hooks/useStripeProvider";

export const StripeContext = React.createContext<StripePaymentInterface>({
  generateSetupIntentSecret: async () => {},
  setStripeTokenDetails: () => {},
  stripeTokenDetails: null,
  setupIntentSecret: null,
  setSetupIntentSecret: () => {},
});
