import React, { FC, useState } from "react";
import cx from "classnames";
import { CaretIconSvg, CaretPointDirections } from "@pepdirect/v3/ui/icons";
import { formatDollarsFromCents } from "helpers/currency";
import { RegulatoryPrices } from "types/price";
import { RegulatoryCodes } from "constants/payment";
import summarySt from "../Summary/summary.module.scss";
import st from "./regulatoryFees.module.scss";

export const regulatoryImpositionTypeLabels: {
  [key in RegulatoryCodes]?: string;
} = {
  [RegulatoryCodes.SALES_TAX]: "Estimated Sales Tax",
  [RegulatoryCodes.SWEETENED_TAX]: "Sweetened Beverage Tax",
  [RegulatoryCodes.CONTAINER_FEE]: "Beverage Container Fee",
};

interface RegulatoryFeesProps {
  fees: RegulatoryPrices;
  label: string;
}
export const RegulatoryFees: FC<RegulatoryFeesProps> = ({ fees, label }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" || e.key === " ") {
      const currentElement = e.currentTarget as HTMLElement;
      currentElement.click();
    }
  };

  return (
    <>
      <div className={summarySt.priceItem}>
        <h3>
          {label}
          <div
            className={st.caretWrapper}
            role="button"
            onClick={() => setIsExpanded(!isExpanded)}
            tabIndex={0}
            onKeyDown={handleKeyDown}
            data-testid="dropdown-icon"
          >
            <CaretIconSvg
              pointDirection={
                isExpanded ? CaretPointDirections.up : CaretPointDirections.down
              }
              width="17px"
              height="8.5px"
            />
          </div>
        </h3>
        {!isExpanded && <div>{formatDollarsFromCents(fees?.total || 0)}</div>}
      </div>
      {isExpanded && fees?.subtotals && (
        <div className={st.expandedSection}>
          {fees.subtotals.map((sub, index) => {
            return (
              <div
                key={index}
                className={cx(summarySt.priceItem, summarySt.lineItem)}
              >
                <h3>
                  {regulatoryImpositionTypeLabels[sub.impositionTypeId || 0] ||
                    sub.impositionValue}
                </h3>
                <div>{formatDollarsFromCents(sub.amount || 0)}</div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
