type QueryParam = string | string[] | undefined;

export function transformQueryParamToString(param: QueryParam): string {
  if (!param) return "";
  if (Array.isArray(param)) return param.join("");
  return param;
}

// constructs redirect url from query params
// successRedirectSite can be shop or secure
// successRedirectTo should start with "/" to
// construct working URL ex: "/cart"
export const getRedirectUrl = (
  successRedirectTo: QueryParam,
  successRedirectSite: QueryParam,
  fallbackUrl: QueryParam
): string => {
  let redirectUrl = "";
  if (successRedirectSite === "shop") {
    redirectUrl += fallbackUrl;
  }
  if (successRedirectTo) {
    redirectUrl += successRedirectTo;
  }

  return redirectUrl;
};

// https://jsfiddle.net/magikMaker/7bjaT/
// Use this to make a string Base64 encoded and URL friendly,
// i.e. '+' and '/' are replaced with '-' and '_' also any trailing '='
// characters are removed
export const base64EncodeUrl = (str: string) => {
  const encodedStr = Buffer.from(str).toString("base64");
  // eslint-disable-next-line no-useless-escape
  return encodedStr.replace(/\+/g, "-").replace(/\//g, "_").replace(/\=+$/, "");
};
// Use this to recreate a Base64 encoded string that was made URL friendly
// '-' and '_' are replaced with '+' and '/' and also it is padded with '+'
export const base64DecodeUrl = (str: string) => {
  str = (str + "===").slice(0, str.length + (str.length % 4));
  str.replace(/-/g, "+").replace(/_/g, "/");
  return Buffer.from(str, "base64").toString();
};
