import React from "react";
import { Field } from "react-final-form";
import { Checkbox } from "@pepdirect/ui/checkbox";
import st from "./fieldCheckbox.module.scss";

interface FieldCheckboxProps {
  name: string;
  label: string | JSX.Element;
}

export function FieldCheckbox({
  name,
  label,
}: FieldCheckboxProps): JSX.Element {
  return (
    <Field
      type="checkbox"
      name={name}
      render={({ input: { onChange, checked } }) => (
        <div className={st.checkbox}>
          <label>
            <Checkbox checked={checked} onChange={onChange} />
            {label}
          </label>
        </div>
      )}
    />
  );
}
