import isEqual from "lodash/isEqual";
import {
  AddressFragment,
  TenantQuery,
  UserAddressInput,
  VerifiedAddressFragment,
  VerifyAddressInput,
} from "services/graphql/generated";
import { AddressDetailsFormValues } from "components/Forms/AddressDetailsForm";
import { AutocompletedAddressValues } from "@pepdirect/shared/types";
import { VerificationAddrTypes } from "components/Modals/VerificationAndConfirmationModal/AddressVerificationModal";

interface IsEnteredAndVerifiedAddressSame {
  enteredAddress: VerifyAddressInput;
  isStateRequired: boolean;
  verifiedAddress: VerifiedAddressFragment;
}

export const isEnteredAndVerifiedAddressSame = ({
  enteredAddress,
  isStateRequired,
  verifiedAddress,
}: IsEnteredAndVerifiedAddressSame): boolean => {
  const checkEquality = (left?: string | null, right?: string | null) => {
    // needed for falsy value comparison, ex: null vs. undefined vs. ""
    if (!left && !right) return true;
    return left?.toLowerCase() === right?.toLowerCase();
  };
  const isLine1Equal = checkEquality(
    enteredAddress.line1,
    verifiedAddress.line1
  );
  const isLine2Equal = checkEquality(
    enteredAddress.line2,
    verifiedAddress.line2
  );
  const isCityEqual = checkEquality(enteredAddress.city, verifiedAddress.city);
  const isStateEqual = isStateRequired
    ? checkEquality(enteredAddress.state, verifiedAddress.state)
    : true;
  const isZipEqual = checkEquality(enteredAddress.zip, verifiedAddress.zip);
  const isCountryEqual = checkEquality(
    enteredAddress.countryCode,
    verifiedAddress.countryCode
  );

  return (
    isLine1Equal &&
    isLine2Equal &&
    isCityEqual &&
    isStateEqual &&
    isZipEqual &&
    isCountryEqual
  );
};

// convert form values to AddressVerificationInput
// type for address verification
export const formatShipToAddrVerifInput = (
  ship: AddressDetailsFormValues
): VerifyAddressInput => {
  return {
    city: ship.city,
    company: ship.company || null,
    countryCode: ship.country?.value,
    firstName: ship.firstName,
    lastName: ship.lastName,
    line1: ship.line1,
    line2: ship.line2 || null,
    state: ship.state?.value,
    zip: ship.zip,
  };
};

interface getAddressForCreateUpdateUserAddressArgs {
  formValues: AddressDetailsFormValues;
  confirmedAddress: VerificationAddrTypes;
  verifiedAddress?: VerifiedAddressFragment;
}

// add form details that aren't part of address verification
// to the confirmed address to create the final user address to save
export const getUserAddressInputFromVerification = ({
  formValues,
  confirmedAddress,
  verifiedAddress,
}: getAddressForCreateUpdateUserAddressArgs): UserAddressInput => {
  const {
    city,
    company,
    countryCode,
    firstName,
    lastName,
    line1,
    line2,
    state,
    zip,
  } = confirmedAddress;
  const { isDefault, nickname, phoneNumber, specialInstructions } = formValues;
  const validated = confirmedAddress === verifiedAddress;

  return {
    city,
    company,
    countryCode,
    firstName,
    lastName,
    line1,
    line2,
    state,
    zip,
    isDefault,
    nickname,
    phoneNumber,
    specialInstructions,
    validated,
  };
};

// Convert form values to UserAddressInput needed for create/update user address.
export const getVerifiedUserAddressInputFromFormValues = (
  formValues: AddressDetailsFormValues
): UserAddressInput => {
  const {
    firstName,
    lastName,
    company,
    line1,
    line2,
    city,
    state,
    country,
    zip,
    phoneNumber,
    nickname,
    specialInstructions,
    isDefault,
  } = formValues;

  return {
    firstName,
    lastName,
    company,
    line1,
    line2,
    city,
    zip,
    phoneNumber,
    nickname,
    specialInstructions,
    isDefault,
    state: state?.value,
    countryCode: country.value,
    validated: true,
  };
};

interface IsShipVerifiedArgs {
  tenant: TenantQuery["tenant"];
  shipSelectedFromAutocomplete: AutocompletedAddressValues | null;
  newShip?: Partial<AddressDetailsFormValues>;
  savedShip?: AddressFragment | null;
}

export const determineIfShipVerified = ({
  tenant,
  shipSelectedFromAutocomplete,
  newShip,
  savedShip,
}: IsShipVerifiedArgs) => {
  const isValidationOptional =
    tenant?.contactConfig.shippingAddressConfig.requireValidation ===
    "OPTIONAL";

  // a saved ship is already verified if validation is optional
  // if validation NOT optional, we want to run verification on the saved ship
  if (savedShip) return isValidationOptional;

  // PCS-1014 only verify ship with Loqate once (don't Find/Retrieve then Verify)
  // if a user chooses an autocompleted address and doesn't change it,
  // it has already been verified by the Loqate Find and Retrieve APIs
  if (shipSelectedFromAutocomplete && newShip) {
    const { line1, line2, city, state, zip, country } = newShip;
    const formattedNewShip: AutocompletedAddressValues = {
      line1: line1 || "",
      line2: line2 || "",
      city: city || "",
      state: state?.value || "",
      zip: zip || "",
      country: country?.value || "",
    };
    if (isEqual(shipSelectedFromAutocomplete, formattedNewShip)) return true;
  }

  return false;
};
