import React from "react";
import { HexColor } from "../../types/styles";
import { colors } from "../../styles/variables";

export const CircleToolIconSvg = ({
  color = colors.black,
}: {
  color?: HexColor;
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="37"
    viewBox="0 0 74 74"
  >
    <path
      d="M0 0 C8.06740009 7.80185871 10.0183223 15.58713031 10.625 26.75 C10.27945333 36.24101522 7.21470801 43.90429234 0.6640625 50.8203125 C-6.91529508 57.76079703 -14.90558456 60.64596914 -25.1796875 60.7890625 C-37.05488159 60.08260619 -45.37747491 55.50919415 -53.375 46.75 C-58.93699439 39.68476388 -59.58990503 29.3661849 -59.2578125 20.6875 C-57.84662571 10.5499949 -52.49687852 2.85029755 -44.7421875 -3.625 C-41.14930605 -6.09140047 -37.59861112 -7.30332854 -33.375 -8.25 C-31.9828125 -8.62125 -31.9828125 -8.62125 -30.5625 -9 C-18.82157339 -9.92085699 -9.03359564 -7.66006648 0 0 Z M-46.375 7.75 C-51.45628088 15.34375457 -54.1325399 22.62022897 -52.375 31.75 C-49.03598599 41.38041935 -44.1273865 47.44527602 -35.1875 52.125 C-28.49895041 54.80041983 -21.96922806 54.98292914 -15.15234375 52.64453125 C-6.7240127 48.7489086 -1.82120083 44.35731838 2 35.9375 C4.91640608 27.83773352 4.39687312 20.89074059 0.87890625 13.0703125 C-3.16103626 5.59453104 -9.17821525 0.66514804 -17.359375 -1.86328125 C-28.10973915 -3.92585112 -39.27323457 -0.85505885 -46.375 7.75 Z "
      fill="#747474"
      transform="translate(61.375,11.25)"
    />
    <path
      d="M0 0 C1.051875 0.1546875 1.051875 0.1546875 2.125 0.3125 C2.9375 2 2.9375 2 3.125 4.3125 C1 6.75 1 6.75 -1.875 9.3125 C-3.0815625 10.6428125 -3.0815625 10.6428125 -4.3125 12 C-6.875 14.3125 -6.875 14.3125 -9.70703125 15.17578125 C-13.3511338 16.48334456 -15.32325806 18.16545547 -18.125 20.8125 C-18.97578125 21.6065625 -19.8265625 22.400625 -20.703125 23.21875 C-22.10578744 24.57095695 -23.4973343 25.9348343 -24.875 27.3125 C-27.5 26.9375 -27.5 26.9375 -29.875 26.3125 C-28.5152138 21.73867368 -26.99704426 20.55274145 -22.875 18.3125 C-21.44044255 17.35973732 -20.02465328 16.37833569 -18.625 15.375 C-17.93921875 14.89160156 -17.2534375 14.40820312 -16.546875 13.91015625 C-14.60303244 12.05260577 -14.42004526 10.88426696 -13.875 8.3125 C-11.6875 6.875 -11.6875 6.875 -8.875 5.3125 C-7.86279842 4.43047658 -6.86309613 3.53394631 -5.875 2.625 C-2.875 0.3125 -2.875 0.3125 0 0 Z "
      fill="#7E7E7E"
      transform="translate(49.875,23.6875)"
    />
    <path
      d="M0 0 C2.75131134 2.65643853 3.72247989 5.20722511 4 9 C2.6 13.4 2.6 13.4 1 15 C-3.29991887 15.53203864 -6.84413024 15.78021209 -10.5 13.3125 C-12.1175594 10.81876259 -12.67357189 8.93785297 -13 6 C-8.44615385 5.63076923 -8.44615385 5.63076923 -6.1875 7.5 C-5.795625 7.995 -5.40375 8.49 -5 9 C-4.34 7.68 -3.68 6.36 -3 5 C-4.32 4.67 -5.64 4.34 -7 4 C-7.33 2.68 -7.66 1.36 -8 0 C-5.03475663 -1.48262169 -3.14877613 -0.83538959 0 0 Z "
      fill="#7F7F7F"
      transform="translate(32,22)"
    />
    <path
      d="M0 0 C1.78378262 1.7789875 2.19177799 2.9590255 2.453125 5.44921875 C1.015625 7.69921875 1.015625 7.69921875 -0.546875 9.44921875 C-5.3934405 8.1708493 -7.99646794 6.45722623 -11.421875 2.82421875 C-12.20304687 2.00695313 -12.98421875 1.1896875 -13.7890625 0.34765625 C-14.36914062 -0.27882813 -14.94921875 -0.9053125 -15.546875 -1.55078125 C-14.546875 -3.55078125 -14.546875 -3.55078125 -12.796875 -4.23828125 C-7.48961832 -4.97540023 -4.22491081 -2.91151462 0 0 Z "
      fill="#787878"
      transform="translate(52.546875,43.55078125)"
    />
    <path
      d="M0 0 C0.33 0 0.66 0 1 0 C1.08897355 3.2030477 1.08978594 5.97281683 0 9 C-1.7421875 10.81640625 -1.7421875 10.81640625 -3.875 12.5625 C-6.85539604 15.01823066 -6.85539604 15.01823066 -9.58984375 17.734375 C-10.05519531 18.15203125 -10.52054688 18.5696875 -11 19 C-13.6875 18.6875 -13.6875 18.6875 -16 18 C-14.6402138 13.42617368 -13.12204426 12.24024145 -9 10 C-7.56544255 9.04723732 -6.14965328 8.06583569 -4.75 7.0625 C-4.06421875 6.57910156 -3.3784375 6.09570312 -2.671875 5.59765625 C-0.75572648 3.7665704 -0.37968837 2.58523109 0 0 Z "
      fill="#9F9F9F"
      transform="translate(36,32)"
    />
    <path
      d="M0 0 C0.33 0 0.66 0 1 0 C1.17706237 6.37424547 1.17706237 6.37424547 0 9 C-2.5625 10.25 -2.5625 10.25 -5 11 C-5 9.68 -5 8.36 -5 7 C-3.68212715 5.97971135 -2.34789347 4.98028616 -1 4 C-0.20376178 1.90584196 -0.20376178 1.90584196 0 0 Z "
      fill="#969696"
      transform="translate(36,32)"
    />
  </svg>
);
